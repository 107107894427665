import './warranty-banner.css';

export default function WarrantyBanner() {
  return (
    <div className="warranty-banner-container">
      <picture>
        <source media="(max-width:600px)" srcSet="./images/extended_warranty_mobile.jpg" loading="lazy" />
        <img src="./images/extended_warranty_web.jpg" alt="tractor warranty" loading="lazy" />
      </picture>
    </div>
  );
}

import './warranty.css';
import WarrantyBanner from './warranty-banner/warranty-banner';
import BuyWarrantyBanner from './warranty-buy-banner/warranty-buy-banner';
import WarrantyExtend from './warranty-extend/warranty-extend';
import FeedPumpDeteriorating from './warranty-feedpump-deteriorating/warranty-feedpump-deteriorating';
import WarrantyForm from './warranty-form/warranty-form';
import MajorBenefits from './warranty-major-benefits/major-benefits';
import WarrantySEO from './warranty-seo';

export default function Warranty() {
  return (
    <div className="warranty-page">
      <WarrantySEO />
      <WarrantyBanner />
      <WarrantyForm />
      <WarrantyExtend />
      <MajorBenefits />
      <BuyWarrantyBanner />
      <FeedPumpDeteriorating />
    </div>
  );
}

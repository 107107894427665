import { Controller, useForm } from 'react-hook-form';
import { useRequestOTP } from '../login-api';
import InputNumber from '../../../assets/custom-fields/input-number';
import { Button, InputAdornment } from '@mui/material';
import { useLanguage } from '../../../language/useLanguage';

const defaultValues = {
  phone_no: '',
};

export default function FormSendOTP({ sendSuccess }) {
  const requestOTP = useRequestOTP();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues });
  const langData = useLanguage();
  function submit(inputs) {
    console.debug('SUBMITTING:', inputs);

    const form = new FormData();
    form.append('phone_no', inputs.phone_no);

    requestOTP.mutate(form, {
      onSuccess: (response, form) => {
        sendSuccess(inputs.phone_no);
        console.debug('REQUEST_OTP_SUCCESS: ', { response, form });
      },
      onError: (err, form) => {
        console.debug('REQUEST_OTP_ERROR: ', { err, form });
      },
    });
  }

  return (
    <form className="login-form" noValidate onSubmit={handleSubmit(submit)}>
      <p className="title">{langData('Signup/Login with OTP')}</p>
      <p className="error-text">{errors.phone_no?.message ?? langData('Enter your Login Details')}</p>
      <div className="field-input">
        <Controller
          name="phone_no"
          control={control}
          rules={{
            required: langData('Phone Number is Required'),
            maxLength: { value: 10, message: 'Enter Valid Number' },
            minLength: { value: 10, message: 'Enter Valid Number' },
          }}
          render={({ field }) => (
            <InputNumber
              {...field}
              onChange={(e) => {
                if (e.target.value.length > 10) return;
                field.onChange(e.target.value);
              }}
              fullWidth
              InputProps={{
                startAdornment: <InputAdornment position="start">+91 |</InputAdornment>,
              }}
            />
          )}
        />
      </div>
      <Button variant="contained" type="submit" disabled={requestOTP.isLoading}>
        {langData('Request OTP')}
      </Button>
    </form>
  );
}

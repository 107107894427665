import { styled } from '@mui/material';
import { SectionTagGrey } from '../../../../common/tags';
import IconArrowRedirect from '../../../../../assets/icons/icon-common/icon-redirection';
import { InFiniteScroll } from '../../../../common/infinite-scroll/infinite-logo-scroll';
import { useLanguage } from '../../../../../language/useLanguage';

export default function AboutUs({ data }) {
  const langData = useLanguage();
  return (
    <AboutUsContainer>
      <AboutWrapper>
        <FlexWrapper>
          <div>
            <SectionTagGrey text={data.tag} />
          </div>
          <h3>{data.heading}</h3>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <p>{data.sub_heading}</p>
          </div>
        </FlexWrapper>
        <AboutDetials>
          <FlexWrapper className="about-content" style={{ gap: 48 }}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: 24 }}>
              <h4>{data.description1}</h4>
              <p>{data.description2}</p>
              <div>
                <a href="about-us">
                  <p>{langData('Know More')}</p>
                  <IconArrowRedirect height="0.7rem" width="0.7rem" />
                </a>
              </div>
            </div>
            <div>
              <InFiniteScroll data={[...(data?.partners_content ?? []), ...(data?.partners_content ?? [])]} title={data?.title} />
            </div>
          </FlexWrapper>
          <FlexWrapper className="about-video">
            {/* <LazyImage name={"https://tractorseva.com/images/about-us-industry.png"} height={"100%"} width={"100%"} /> */}
            <iframe
              width="100%"
              height="100%"
              // src="https://www.youtube.com/embed/7R84WQzC9hs?autoplay=1&mute=1&loop=1&playlist=7R84WQzC9hs"
              src="https://www.youtube.com/embed/OdKlNdjIK9I?si=8pDxvmIP0T4cbtYG"
              style={{ border: 'none' }}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Embedded YouTube Video"
            ></iframe>
          </FlexWrapper>
        </AboutDetials>
      </AboutWrapper>
    </AboutUsContainer>
  );
}

const AboutUsContainer = styled('div')({
  // padding: "4rem",
  padding: '4rem var(--padding-inline)',
  backgroundColor: '#FFFFFF',
  marginInline: 'calc(0px - var(--padding-inline))',
});

const AboutWrapper = styled('div')({
  display: 'flex',
  // alignItems: "flex-start",
  flexDirection: 'column',
  gap: 46,
});

const FlexWrapper = styled('div')({
  display: 'flex',
  // alignItems: "flex-start",
  // flex: "1 1 400px",
  flexWrap: 'wrap',
  gap: 14,
  flexDirection: 'column',
  h3: {
    color: 'var(--clr-primary)',
    fontSize: '3rem',
    fontWeight: 500,
  },
  h4: {
    fontSize: '1.8rem',
    fontWeight: 500,
    color: 'var(--clr-primary)',
  },
  a: {
    color: '#ff4c4c',
    textDecoration: 'none',
    display: 'inline-flex',
    alignItems: 'center',
    gap: 8,
    p: {
      color: '#ff4c4c',
      textDecoration: 'none',
      fontWeight: 500,
      fontSize: 18,
    },
  },
  p: {
    color: '#6D7D93',
    fontSize: 18,
    fontWeight: 400,
  },
  ' @media only screen and (max-width: 600px)': {
    h3: {
      fontSize: '2rem',
    },
    p: {
      fontSize: '1rem',
    },
  },
});

const AboutDetials = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  gap: 68,
  ' div.about-content, div.about-video': {
    flex: '1 1 400px',
  },
  '@media screen and (min-width: 750px) and (max-width: 1300px)': {
    gap: 30,
  },
  '@media screen and (max-width: 600px)': {
    gap: '1rem',
    'div.about-video iframe': {
      height: '250px', // Set iframe height to 100px for small screens
      marginTop: '2.6rem', // Add margin to iframe
    },
  },
});

import { Button } from '@mui/material';
import './ctafranchiseprogram.css';
import { HashLink } from 'react-router-hash-link';
import { useLanguage } from '../../../../../language/useLanguage';

export default function FranchiseProgram({ data }) {
  const langData = useLanguage();
  return (
    <div className="cta-franchise-program-container">
      <div className="franchise-program-des-container">
        <h4 className="franchise-program-title">{data?.heading}</h4>
        <p className="franchise-program-subtitle">{data?.sub_heading}</p>
        <HashLink className="sidebar-main-link" to="franchise#franchise-form">
          <Button style={{ alignSelf: 'flex-start' }} variant="contained" color="info">
            {langData('Know More')}
          </Button>
        </HashLink>
      </div>
      <div className="franchise-program-img-container">
        <div className="franchise-program-img">
          <img className="map-img" src="./images/india-map-cta.svg" />
        </div>
      </div>
    </div>
  );
}

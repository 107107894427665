export default function IconKits({ width = '1em', height = '1em', color = 'var(--clr-primary)', color2 = 'var(--clr-secondary)', ...props }) {
  return (
    <svg width={width} height={height} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M28.131 20.7598C27.1998 20.7598 26.2895 21.0359 25.5153 21.5532C24.741 22.0706 24.1376 22.8059 23.7812 23.6662C23.4249 24.5265 23.3317 25.4731 23.5133 26.3864C23.695 27.2997 24.1434 28.1386 24.8018 28.797C25.4603 29.4555 26.2992 29.9039 27.2125 30.0855C28.1257 30.2672 29.0724 30.1739 29.9327 29.8176C30.793 29.4613 31.5283 28.8578 32.0456 28.0836C32.5629 27.3093 32.8391 26.3991 32.8391 25.4679C32.8391 24.2192 32.343 23.0217 31.4601 22.1387C30.5772 21.2558 29.3796 20.7598 28.131 20.7598ZM30.3485 24.6251L27.9944 26.9792C27.9052 27.0667 27.7852 27.1157 27.6602 27.1157C27.5352 27.1157 27.4151 27.0667 27.3259 26.9792L25.9134 25.5667C25.8259 25.4779 25.7771 25.358 25.7775 25.2332C25.778 25.1085 25.8278 24.989 25.916 24.9007C26.0042 24.8125 26.1237 24.7628 26.2485 24.7623C26.3732 24.7618 26.4931 24.8107 26.582 24.8982L27.6602 25.9811L29.6799 23.9566C29.7688 23.869 29.8887 23.8202 30.0135 23.8206C30.1382 23.8211 30.2577 23.8709 30.346 23.9591C30.4342 24.0473 30.4839 24.1668 30.4844 24.2916C30.4849 24.4163 30.436 24.5362 30.3485 24.6251Z"
        fill="#EC2726"
      />
      <path
        d="M22.2455 25.4682C22.2473 23.9079 22.8678 22.4121 23.9711 21.3088C25.0743 20.2055 26.5701 19.5849 28.1304 19.583V5.29395H20.3625V12.4287L15.6893 8.62622L11.0162 12.4287V5.29395H3.24805V28.5176C3.24888 28.9573 3.42391 29.3787 3.7348 29.6895C4.04568 30.0004 4.4671 30.1755 4.90675 30.1763H24.6035C23.8715 29.6288 23.2773 28.9182 22.868 28.1009C22.4587 27.2837 22.2456 26.3822 22.2455 25.4682Z"
        fill="#002852"
      />
      <path d="M12.2096 5.29395V10.2776L15.6894 7.44097L19.1691 10.302V5.29395H12.2096Z" fill="#002852" />
    </svg>
  );
}

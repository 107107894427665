import { configureStore, combineReducers } from '@reduxjs/toolkit';
import authReducer from './features/auth/authSlice';
import customerOrderReducer from './features/customer-order/customerOrderSlice';
import locationSliceReducer from './features/location/locationSlice';
import cartSliceReducer from './features/cart/cartSlice';
import tractorSelectionReducer from './features/tractor-selection-sticky/tractorSelectionSticky';

// Combine all your reducers
const appReducer = combineReducers({
  auth: authReducer,
  customer_order: customerOrderReducer,
  location: locationSliceReducer,
  cart: cartSliceReducer,
  tractor_selection: tractorSelectionReducer,
});

// Create a rootReducer that handles resetting the state on logout
const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    state = undefined; // Reset all slice states to initial
  }
  return appReducer(state, action);
};

//  Configure the store with the rootReducer
export const store = configureStore({
  reducer: rootReducer,
});

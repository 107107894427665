export default function IconFacebook({ width = '1em', height = '1em', color = '#fff', ...props }) {
  return (
    <svg width={width} height={height} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="35" height="35" rx="17.5" stroke={color} />
      <g clipPath="url(#clip0_1804_24)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.7496 27.6004V18.9604H22.3727L22.8012 15.1204H19.7496V13.2501C19.7496 12.2613 19.7749 11.2804 21.1566 11.2804H22.5561V8.53492C22.5561 8.49364 21.354 8.40039 20.1378 8.40039C17.598 8.40039 16.0076 9.9913 16.0076 12.9126V15.1204H13.2012V18.9604H16.0076V27.6004H19.7496Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1804_24">
          <rect width="19.2" height="19.2" fill={color} transform="translate(8.40039 8.40039)" />
        </clipPath>
      </defs>
    </svg>
  );
}

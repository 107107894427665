export default function IconSearchWhite({ width = '1em', height = '1em', color = 'var(--clr-primary)', color2 = 'var(--clr-secondary)', ...props }) {
  return (
    <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_634_4825)">
        <path
          d="M11.5761 13.5886C11.2507 13.2632 11.2507 12.7355 11.5761 12.4101C11.9015 12.0847 12.4292 12.0847 12.7546 12.4101L16.088 15.7434C16.4134 16.0689 16.4134 16.5965 16.088 16.9219C15.7625 17.2474 15.2349 17.2474 14.9094 16.9219L11.5761 13.5886Z"
          fill="white"
        />
        <path
          d="M8.83333 13C11.1345 13 13 11.1345 13 8.83333C13 6.53215 11.1345 4.66667 8.83333 4.66667C6.53215 4.66667 4.66667 6.53215 4.66667 8.83333C4.66667 11.1345 6.53215 13 8.83333 13ZM8.83333 14.6667C5.61167 14.6667 3 12.055 3 8.83333C3 5.61167 5.61167 3 8.83333 3C12.055 3 14.6667 5.61167 14.6667 8.83333C14.6667 12.055 12.055 14.6667 8.83333 14.6667Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_634_4825">
          <rect width="20" height="20" rx="8.51087" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

import { useEffect } from 'react';
import './scroll.css';

export function InFiniteScroll({ data, title }) {
  let scrollers;
  useEffect(() => {
    scrollers = document.querySelectorAll('.scroller');
    if (!window.matchMedia('(prefers-reduced-motion: reduce)').matches) {
      addanimation();
    }
  }, []);

  function addanimation() {
    scrollers.forEach((scroller) => {
      scroller.setAttribute('data-animated', true);
      const scrollerInner = scroller.querySelector('.scroller_inner');
      const scrollContent = Array.from(scrollerInner.children);
      scrollContent.forEach((item) => {
        const duplicateItem = item.cloneNode(true);
        duplicateItem.setAttribute('aria-hidden', true);
        scrollerInner.appendChild(duplicateItem);
      });
    });
  }

  return (
    <div>
      <h5
        style={{
          fontSize: 26,
          fontWeight: 500,
          color: 'var(--clr-primary)',
        }}
      >
        {title}
      </h5>
      <div className="scroller">
        <div className="scroller_inner">
          {data?.map((elem, num) => (
            <img key={num} className="quality-card-icn" src={elem?.image_name} height={120} width={120} alt={`Partner logo ${num}`} />
          ))}
          {data?.map((elem, num) => (
            <img key={'copy' + num} className="quality-card-icn" src={elem?.image_name} height={120} width={120} alt={`Partner logo ${num}`} />
          ))}
        </div>
      </div>
    </div>
  );
}

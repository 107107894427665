import { Autocomplete, Button, TextField } from '@mui/material';
import { TagCompleted } from '../../common/tags';
import { Controller, useForm } from 'react-hook-form';
import { useLanguage } from '../../../language/useLanguage';
import { useGetDistricts, useGetStates, useGetSubDistricts } from '../../profile/profile-api';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../../context/authContext';
import { useAddCustomerAddress } from '../login-api';

const FormProfileAddress = ({ tempToken }) => {
  const langData = useLanguage();
  const { setToken } = useAuthContext();
  const inputs = {
    address_name: '',
    address_1: '',
    address_2: '',
    state_id: null,
    district_id: null,
    sub_district_id: null,
    pincode: '',
  };
  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm({ defaultValues: inputs });
  const [state_id, district_id] = watch(['state_id', 'district_id']);
  const navigate = useNavigate();
  const addAddress = useAddCustomerAddress(tempToken);
  const dropdownStateList = useGetStates();
  const dropdownDistrictList = useGetDistricts(state_id);
  const dropdownSubdistrictList = useGetSubDistricts(district_id);
  function submit(inputs) {
    const form = new FormData();
    for (const key in inputs) {
      form.append(key, inputs[key]);
    }
    addAddress.mutate(form, {
      onSuccess: (res) => {
        setToken(res.data.token);
        if (location?.state?.redirectTo) {
          navigate(location?.state?.redirectTo, { state: location.state });
        } else {
          navigate('/');
        }
      },
    });
  }
  return (
    <form
      className="login-form profile-form custom-scrollbar"
      style={{ height: '400px', overflowY: 'scroll', paddingRight: 15 }}
      noValidate
      onSubmit={(e) => {
        e.stopPropagation();
        handleSubmit(submit)(e);
      }}
    >
      {/* <h2>{addressID ? "Update" : "Add"} Address</h2> */}
      <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
        <h2>Add {langData('Address')}</h2>
      </div>
      <div className="field-container">
        <label className="required">{langData('Address Name')}</label>
        <TextField
          {...register('address_name', {
            required: langData('Field is Required'),
            minLength: { value: 2, message: 'Minimum 2 character required' },
            maxLength: { value: 30, message: 'Maximum limit exceed' },
          })}
          error={!!errors.address_name}
        />
        <p className="error-text">{errors.address_name?.message}</p>
      </div>
      <div className="field-container">
        <label className="required">{langData('Address Line 1')}</label>
        <TextField
          {...register('address_1', {
            required: langData('Field is Required'),
            minLength: { value: 2, message: 'Minimum 2 character required' },
            maxLength: { value: 200, message: 'Maximum limit exceed' },
          })}
          error={!!errors.address_1}
        />
        <p className="error-text">{errors.address_1?.message}</p>
      </div>
      <div className="field-container">
        <label>{langData('Address Line 2')}</label>
        <TextField
          {...register('address_2', {
            minLength: { value: 2, message: 'Minimum 2 character required' },
            maxLength: { value: 200, message: 'Maximum limit exceed' },
          })}
          error={!!errors.address_2}
        />
        <p className="error-text">{errors.address_2?.message}</p>
      </div>
      <div className="form-column-2">
        <div className="field-container">
          <label className="required">{langData('State')}</label>
          <Controller
            name={'state_id'}
            control={control}
            rules={{ required: langData('Please Select State') }}
            render={({ field }) => {
              return (
                <Autocomplete
                  {...field}
                  options={dropdownStateList.data ?? []}
                  renderInput={(params) => <TextField {...params} placeholder={langData('State')} error={!!errors.state_id} />}
                  isOptionEqualToValue={(option, value) => option.id === value}
                  getOptionLabel={(option) => option.label ?? dropdownStateList.data?.find((opt) => opt.id === option)?.label ?? ''}
                  onChange={(__event, data) => {
                    field.onChange(data?.id ?? null);
                    setValue(`district_id`, null);
                    setValue(`sub_district_id`, null);
                  }}
                />
              );
            }}
          />
          <p className="form-error-text">{errors.state_id?.message}</p>
        </div>
        <div className="field-container">
          <label className="required">{langData('District')}</label>
          <Controller
            name={'district_id'}
            control={control}
            rules={{ required: langData('Please Select District') }}
            render={({ field }) => {
              return (
                <Autocomplete
                  {...field}
                  disabled={!state_id}
                  options={dropdownDistrictList.data ?? []}
                  renderInput={(params) => <TextField {...params} placeholder={langData('District')} error={!!errors.district_id} />}
                  isOptionEqualToValue={(option, value) => option.id === value}
                  getOptionLabel={(option) => option.label ?? dropdownDistrictList.data?.find((opt) => opt.id === option)?.label ?? ''}
                  onChange={(__event, data) => {
                    field.onChange(data?.id ?? null);
                    setValue('sub_district_id', null);
                  }}
                />
              );
            }}
          />
          <p className="form-error-text">{errors.district_id?.message}</p>
        </div>
      </div>
      <div className="form-column-2">
        <div className="field-container">
          <label className="required">{langData('Tehsil')}</label>
          <Controller
            name={'sub_district_id'}
            control={control}
            rules={{ required: langData('Please Select Tehsil') }}
            render={({ field }) => {
              return (
                <Autocomplete
                  {...field}
                  disabled={!district_id}
                  options={dropdownSubdistrictList.data ?? []}
                  renderInput={(params) => <TextField {...params} placeholder={langData('Tehsil')} error={!!errors.sub_district_id} />}
                  isOptionEqualToValue={(option, value) => option.id === value}
                  getOptionLabel={(option) => option.label ?? dropdownSubdistrictList.data?.find((opt) => opt.id === option)?.label ?? ''}
                  onChange={(__event, data) => {
                    field.onChange(data?.id ?? null);
                  }}
                />
              );
            }}
          />
          <p className="form-error-text">{errors.sub_district_id?.message}</p>
        </div>
        <div className="field-container">
          <label className="required">{langData('Pincode')}</label>
          <TextField
            placeholder={langData('Pincode')}
            error={!!errors.pincode}
            {...register('pincode', {
              required: langData('Field is Required'),
              minLength: { value: 6, message: 'Enter valid pincode' },
              maxLength: { value: 6, message: 'Enter valid pincode' },
            })}
          />
          <p className="form-error-text">{errors.pincode?.message}</p>
        </div>
      </div>
      <div style={{ display: 'flex', gap: 16, flexWrap: 'wrap' }}>
        <Button variant="contained" type="submit" disabled={addAddress.isLoading}>
          {langData('Save')}
        </Button>
      </div>
    </form>
  );
};

export default FormProfileAddress;

export default function IconParts({ width = '1em', height = '1em', color = 'var(--clr-primary)', color2 = 'var(--clr-secondary)', ...props }) {
  return (
    <svg width={width} height={height} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_1294_1454)">
        <path
          d="M32.1682 5.0768L29.2076 8.04005H27.9339V6.76634L30.8971 3.80567L29.749 3.25819C27.3463 2.11309 24.4688 2.60716 22.5888 4.48715C20.862 6.21394 20.3047 8.78104 21.1115 11.049L11.0537 21.2265C8.78579 20.4197 6.21869 20.977 4.4919 22.7038C2.6255 24.5702 2.13775 27.4402 3.2784 29.8459L3.82541 30.9973L6.77109 28.0542H8.03965V29.3228L5.09538 32.2696L6.24797 32.848C8.43696 33.8861 11.7404 33.2514 13.3901 31.6017C15.0939 29.8981 15.6971 27.3725 14.8671 25.0401L17.3497 22.5574L18.6159 23.8236L23.7035 18.7362L22.4372 17.47L24.9249 14.8624C27.1929 15.6692 29.7602 15.1119 31.4867 13.3851C33.3667 11.5051 33.8608 8.62759 32.7157 6.22495L32.1682 5.0768Z"
          fill="#002852"
        />
        <path d="M19.8925 25.1005L24.9798 20.0129L29.0898 24.1231L24.0024 29.2104L19.8925 25.1005Z" fill="#002852" />
        <path
          d="M30.3615 25.3936L25.274 30.4809L27.1973 32.4043C28.4377 33.6288 31.0444 33.6288 32.2846 32.4043C33.6689 31.0022 33.6689 28.719 32.2846 27.3169L30.3615 25.3936Z"
          fill="#002852"
        />
        <path
          d="M8.97314 11.224L9.40326 10.7938L14.1743 15.5647L15.4455 14.2936L10.6744 9.52271L11.1045 9.0926L8.71217 3.46925L4.59891 2.64697L2.64746 4.59842L3.46974 8.71168L8.97314 11.224Z"
          fill="#EC2726"
        />
      </g>
      <defs>
        <clipPath id="clip0_1294_1454">
          <rect width="30.7059" height="30.7059" fill="white" transform="translate(2.64746 2.64697)" />
        </clipPath>
      </defs>
    </svg>
  );
}

import { Link } from 'react-router-dom';
import './serviceagreement.css';
import ServiceAgreementSEO from './service-agreement-seo';
import { useLanguage } from '../../../../language/useLanguage';
// import { useLanguage } from "../../../../language/useLanguage";

export default function Serviceagreement() {
  const langData = useLanguage();
  return (
    <>
      <ServiceAgreementSEO />
      <div className="service_main_container">
        <div className="service_container">
          <h1 className="heading_text">{langData('Service Agreement')}</h1>
          <p className="para_text">
            <ol>
              <li>
                <p>
                  {langData(
                    "BEDIENUNG SOLUTION PRIVATE LIMITED, hereinafter referred to as 'Tractor Seva,' with its registered office located at Flat No. 302, 7B, Building No. 7, Mahindra Bloomdale Co-operative Housing Society, Mihan, Nagpur (Urban), Khapri Maharashtra- 441108, India, provides the Tractor Seva tool through its web portal, tractorseva.com, and its mobile application ('Tractor Seva App') for delivering its services to you, a vehicle owner referred to as the 'User,' based on the terms outlined in this Service Agreement.",
                  )}
                </p>
              </li>
              <li>
                <p>
                  {langData(
                    "The Tractor Seva tool has been designed to facilitate Users in discovering and connecting with workshops that specialize in servicing heavy vehicles, particularly tractors ('Workshop Services'). Furthermore, it offers the option to schedule a service at a location chosen by the User ('At-door Service') and to establish and manage service records for the User' vehicle, collectively referred to as the 'Services.' Workshops are made available by Tractor Seva through its network of franchisees spread across the country. At-door Services shall be provided by ;Service Professionals; assigned by Tractor Seva.",
                  )}
                </p>
              </li>
              <li>
                <p>
                  {langData(
                    "Please note that the Services mentioned above may undergo periodic changes at Tractor Seva' discretion, and this Service Agreement applies to all Users who visit Tractor Seva to utilize its Services, as well as to all information provided by Users on the Tractor Seva platform at any given time.",
                  )}
                </p>
              </li>
              <li>
                <p>
                  {langData(
                    'Tractor Seva retains the right to amend or terminate any part of this Service Agreement at its discretion and at any time. Users will be notified of such modifications through notifications. Users are encouraged to use the most recent version of Tractor Seva and to review the Service Agreement regularly. Your continued use of Tractor Seva following any such modification signifies your agreement to abide by the revised Service Agreement.',
                  )}
                </p>
              </li>
              <li>
                <p>
                  {langData(
                    'Tractor Seva is authorized to delegate the performance of any of its services to third parties, including affiliated group companies of Tractor Seva, who may act as subcontractors or Franchise Holders.',
                  )}
                </p>
              </li>
            </ol>
          </p>
        </div>
        <div className="service_container">
          <h2 className="heading_text">{langData('Service Offer and Availability')}</h2>
          <p className="para_text">
            <ol>
              <li>
                <p>
                  {langData(
                    'Tractor Seva provides a platform with a variety of services that enable you, as a User, to access information and interact with workshops and other service providers. For a comprehensive overview of the services and the associated requirements, please consult our service description at tractoreseva.com. Users also have the option to specify their service requirements beyond those outlined in the service description. However, the availability of such services depends on the individual workshops. Tractor Seva cannot guarantee the availability of such services.',
                  )}
                </p>
              </li>
              <li>
                <p>
                  {langData(
                    'We retain the right to supplement the services available on Tractor Seva or to restrict the usage of certain services. Users do not have an inherent right to retain specific services or features.',
                  )}
                </p>
              </li>
              <li>
                <p>
                  {langData(
                    'To access certain services, you must complete the registration process, which is free of charge. However, we reserve the right to offer specific services for a fee.',
                  )}
                </p>
              </li>
              <li>
                <p>{langData('For free services, there is no guarantee of uninterrupted usage.')}</p>
              </li>
              <li>
                <p>
                  {langData(
                    'It is possible that access to or use of Tractor Seva may be temporarily interrupted or impaired due to maintenance, upgrades, or other disruptions that may result in data loss. We make every effort to ensure uninterrupted usability of Tractor Seva services, but temporary disruptions or interruptions may occur due to technical issues, such as power outages, hardware and software glitches, or technical problems with data lines.',
                  )}
                </p>
              </li>
            </ol>
          </p>
        </div>
        <div className="service_container">
          <h2 className="heading_text">{langData('Booking Service Appointment')}</h2>
          <p className="para_text">
            <ol>
              <li>
                <p>
                  {langData(
                    "By utilizing Tractor Seva' services, Users acknowledge and consent to the sharing of their User Data with Workshops, which is necessary to facilitate communication and appointments.",
                  )}
                </p>
              </li>
              <li>
                <p>
                  {langData(
                    'Users can choose between two service options: ;Workshop Servicing, ; where the service is performed at the Workshop selected by the User, or ;At-door Servicing,; where the service is conducted at a location designated by the User. Users should be aware that the quality of service for At-door Servicing may differ from that of Workshop Servicing. Tractor Seva recommends Workshop Servicing for a higher quality service experience.',
                  )}
                </p>
              </li>
              <li>
                <p>
                  {langData(
                    "It' important to understand that although Tractor Seva provides appointment booking services, Tractor Seva assumes no liability if a Workshop cancels a confirmed appointment or if the selected Workshop is unavailable at the scheduled time.",
                  )}
                </p>
              </li>
              <li>
                <p>
                  {langData(
                    'Any search results generated by Users on Tractor Seva for Workshops should not be construed as an endorsement by Tractor Seva of any particular Workshop. If Users decide to engage with a Workshop for vehicle services, they do so at their own discretion.',
                  )}
                </p>
              </li>
              <li>
                <p>
                  {langData(
                    'Without limiting the generality of the above, please note that Tractor Seva is not involved in providing vehicle services and, as such, is not responsible for any interactions between Users and Workshops. Users expressly acknowledge and agree that Tractor Seva shall not be held liable for:',
                  )}
                </p>
                <ul>
                  <li>{langData('Any interactions or associated issues that Users may have with Workshops.')}</li>
                  <li>{langData('he ability or intent of Workshops, or their lack thereof, in fulfilling their obligations to Users.')}</li>
                  <li>
                    {langData(
                      'Any issues related to the wrong parts, accessories, or quality of service provided by Workshops, or any negligence on the part of Workshops in delivering services.',
                    )}
                  </li>
                  <li>
                    {langData(
                      'Inappropriate behavior, damage to the vehicle before, during, or after service, the misplacement or loss of personal belongings, or any similar difficulties or inconveniences experienced by Users due to Workshop failures to provide agreed-upon services.',
                    )}
                  </li>
                  <li>
                    {langData(
                      'Inappropriate behavior, damage to the vehicle before, during, or after service, the misplacement or loss of personal belongings, or any similar difficulties or inconveniences experienced by Workshops due to a lack of detailed information about the vehicle service or any other reasons.',
                    )}
                  </li>
                  <li>{langData('Cancellation, no-shows, or rescheduling of booked appointments by Workshops.')}</li>
                </ul>
              </li>
              <li>
                <p>
                  {langData(
                    'It is expressly clarified that any information obtained or received by Users from Tractor Seva, its employees, contractors, partners, sponsors, advertisers, licensors, or other sources on Tractor Seva is for informational purposes only. Tractor Seva makes no guarantees, representations, or warranties, whether expressed or implied, regarding professional qualifications, work quality, the use of spare parts in vehicles, Workshop expertise, or any other information provided on Tractor Seva. In no event shall Tractor Seva be liable to Users or anyone else for any decisions made or actions taken by Users based on such information.',
                  )}
                </p>
              </li>
            </ol>
          </p>
        </div>
        <div className="service_container">
          <h2 className="heading_text">{langData('Customer Conduct')}</h2>
          <p className="para_text">
            <ol>
              <li>
                <p>
                  {langData(
                    'Tractor Seva strictly prohibits discrimination against Service Professionals based on factors including but not limited to race, religion, caste, national origin, disability, sexual orientation, sex, marital status, gender identity, age, or any other characteristic protected under applicable law. This prohibition encompasses all forms of discrimination, including the refusal to accept Services from Service Professionals based on any of these characteristics.',
                  )}
                </p>
              </li>
              <li>
                <p>
                  {langData(
                    'We kindly request that you treat all Service Professionals with courtesy and respect, and that you ensure they have a safe and suitable environment in which to perform their Services. Service Professionals have the right to decline providing Services if you have not provided a safe and appropriate work environment, or if you engage with them in a discourteous, disrespectful, abusive, or otherwise inappropriate manner. Tractor Seva reserves the right to restrict your access to Services at our sole discretion if you behave in a manner deemed discourteous, disrespectful, abusive, inappropriate, or unlawful towards any Service Professional.',
                  )}
                </p>
              </li>
              <li>
                <p>
                  {langData(
                    "You acknowledge your responsibility for refraining from discriminating against Service Professionals and for providing them with a safe, clean, and appropriate location to perform their Services. Additionally, you agree to disclose any information that may affect a Service Professional' ability to provide the Services or impact their health, safety, or well-being, to both Tractor Seva and the Service Professional.",
                  )}
                </p>
              </li>
              <li>
                <p>
                  {langData(
                    "In the event that a Service Professional' conducts themselves in a discourteous, disrespectful, abusive, inappropriate, or unlawful manner, you are required to promptly report such an incident to [insert email id] within 48 (forty-eight) hours of the occurrence.",
                  )}
                </p>
              </li>
            </ol>
          </p>
        </div>
        <div className="service_container">
          <h2 className="heading_text">{langData('Support')}</h2>
          <p className="para_text">
            <ol>
              <li>
                <p>
                  {langData(
                    'Tractor Seva provides a call center support service for addressing malfunctions, queries, or concerns related to the use of the Tractor Seva platform. Users can reach the call center via telephone during regular business hours, from 9 am to 5 pm, India time. For each call, the call center generates a ticket and assigns it an appropriate. When feasible, the call center will offer telephone support to resolve issues independently.',
                  )}
                </p>
              </li>
            </ol>
          </p>
        </div>
        <div className="service_container">
          <h2 className="heading_text">{langData('Service fee')}</h2>
          <p className="para_text">
            <ol>
              <li>
                <p>
                  {langData(
                    "The fees for all services offered by Tractor Seva are detailed under the Services tab on our platform. Users are responsible for all subscription fees or other charges associated with their use of Tractor Seva' services. Payments must be made using the payment methods accepted by Tractor Seva. Please note that Tractor Seva retains the exclusive right to adjust the prices of its services and products.",
                  )}
                </p>
              </li>
              <li>
                <p>
                  {langData(
                    "Additionally, it' important to clarify that Tractor Seva is not accountable for any extra charges that a Workshop may impose for services booked online by Users. Any supplementary expenses incurred in this manner are the sole responsibility of the User and should be settled directly with the Workshop.",
                  )}
                </p>
              </li>
              <li>
                <p>
                  {langData(
                    "For any additional work performed directly by a Workshop, beyond what is included in the User' package purchased through the Tractor Seva portal, payment must be made directly to the Workshop. Tractor Seva disclaims any responsibility for the quality of service and products used by the Workshop for such additional work.",
                  )}
                </p>
              </li>
            </ol>
          </p>
        </div>
        <div className="service_container">
          <h2 className="heading_text">{langData("Payment term'")}</h2>
          <p className="para_text">
            <ol>
              <li>
                {' '}
                <p>{langData('Taxes: All Charges and Fees are inclusive of applicable taxes')}</p>
              </li>
              <li>
                <p>
                  {langData(
                    'Tractor Seva reserves the right to reasonably amend the Charges and Fees at any time at its sole discretion. A change in Fees shall not impact any bookings that have been confirmed before the publication of the revised Fees on the Platform.',
                  )}
                </p>
              </li>
              <li>
                <p>
                  {langData(
                    'Charges and Fees that you pay are final and non-refundable, unless otherwise determined by Tractor Seva or required by the applicable laws. Under certain laws, you may be entitled to a refund or other remedies for a failure in the provision of the Services.',
                  )}
                </p>
              </li>
              <li>
                <p>
                  {langData(
                    'You acknowledge and agree that Charges and Fees applicable in certain geographical areas may increase substantially during times of high demand. Tractor Seva will use reasonable efforts to inform you of the Charges and Fees that may apply. However, by using the Services, you will be responsible for the Charges and Fees incurred under your Account regardless of your awareness of such Charges or Fees.',
                  )}
                </p>
              </li>
              <li>
                <p>
                  {langData(
                    "Payment Processors: We may use a third-party payment processor ('Payment Processor') to bill you through your selected mode of payment. The processing of payments will be subject to the terms and policies of such Payment Processor in addition to these Terms. We shall not be liable for any error of the Payment Processor. In the event of any unsuccessful payment, the money debited shall be credited in accordance with the terms of the Payment Processor.",
                  )}
                </p>
              </li>
            </ol>
          </p>
        </div>
        <div className="service_container">
          <h2 className="heading_text">{langData('Representation, Warranty, Liability')}</h2>
          <p className="para_text">
            <ol>
              <li>
                <p>
                  {langData(
                    "Tractor Seva offers its services on an 'as-is' basis and does not warrant that Tractor Seva will meet Users; requirements or that it will be continuously available and error-free. Tractor Seva assumes no responsibility for warranty or maintenance of the Tractor Seva Service. Furthermore, Tractor Seva does not guarantee uninterrupted access to and usage of Tractor Seva, as interruptions can occur due to maintenance, development, updates, upgrades, malfunctions, or other factors beyond Tractor Seva' control.",
                  )}
                </p>
              </li>
              <li>
                <p>
                  {langData(
                    "The use of Tractor Seva is solely at the User' risk. Tractor Seva does not make any representations or warranties regarding the adequacy of Workshop facilities, the authenticity and quality of spare parts used by Workshops, the quality of service provided by Workshops, the expertise of Workshop employees, or the reasonableness of Workshop prices. Users are expected to conduct their own due diligence and assessments before entrusting their vehicles to a Workshop.",
                  )}
                </p>
              </li>
              <li>
                <p>
                  {langData(
                    'Tractor Seva does not guarantee that the services listed on Tractor Seva will always be available at Workshops or that there will be no delays in service.',
                  )}
                </p>
              </li>
              <li>
                <p>{langData('Tractor Seva bears no responsibility for any consequences resulting from interruptions in mobile data connectivity.')}</p>
              </li>
              <li>
                <p>
                  {langData(
                    'Any disputes that may arise between a User and a Workshop must be resolved solely between the User and the Workshop, and Tractor Seva shall not be involved in such disputes.',
                  )}
                </p>
              </li>
              <li>
                <p>
                  {langData(
                    "Tractor Seva neither endorses nor vouches for any representations made by Workshops. Tractor Seva' role is limited to providing listings of Workshops in the User' vicinity or at the User' specified location. Users acknowledge that they have chosen to avail themselves of Workshop services at their own discretion.",
                  )}
                </p>
              </li>
              <li>
                <p>
                  {langData(
                    'Tractor Seva assumes no liability for third-party ratings of Workshops. Tractor Seva has no influence or control over third-party ratings and reviews about Workshops. Relying on these ratings and reviews is solely at the discretion and risk of the User.',
                  )}
                </p>
              </li>
              <li>
                <p>
                  {langData(
                    'Users have no claims or actions against Tractor Seva for any grievances, disputes, losses, or damages they may incur due to their use of the Tractor Seva Website or App or their interactions with Workshops.',
                  )}
                </p>
              </li>
              <li>
                <p>{langData('Users represent and warrant that all data and details provided herein are true, correct, and will remain so at all times.')}</p>
              </li>
              <li>
                <p>
                  {langData(
                    "Users represent and warrant that their vehicle is used for its intended purpose, is duly insured as required by law, and is otherwise fit for use. The vehicle should be operated by a qualified individual with a valid driver' license.",
                  )}
                </p>
              </li>
              <li>
                <p>
                  {langData(
                    "Tractor Seva merely provides a platform to connect Users with Workshops and facilitate vehicle maintenance and improvement solutions. Therefore, Tractor Seva is not liable to Users for any acts or omissions, negligence or misconduct of Workshops, or for the non-availability or maintenance of service facilities by Workshops, or for any failures on the part of Workshops to properly provide requested services or for any other actions or omissions by third parties beyond Tractor Seva' control.",
                  )}
                </p>
              </li>
              <li>
                <p>
                  {langData(
                    'To the fullest extent permitted by law, Tractor Seva shall not be liable for any direct, indirect, incidental, special, consequential, or other damages, including, without limitation, damages for loss of profits, data corruption, failure to transmit or receive data, delays, accidents, injuries, business interruption, or any other commercial damages or losses, arising from or related to your use or inability to use Tractor Seva and its Services, regardless of the theory of liability (contract, tort, or otherwise), even if Tractor Seva has been advised of the possibility of such damages.',
                  )}
                </p>
              </li>
              <li>
                <p>
                  {langData(
                    "These limitations of liability also apply in cases of faults committed by individuals engaged by Tractor Seva in performing its obligations and to the personal liability of Tractor Seva' employees, representatives, and corporate bodies.",
                  )}
                </p>
              </li>
            </ol>
          </p>
        </div>
        <div className="service_container">
          <h2 className="heading_text">{langData('Other terms')}</h2>
          <p className="para_text">
            <ol>
              <li>
                <p>
                  {langData('In the event that a User books any services from Tractor Seva, this ')}
                  <Link to="/service-agreement" target="_blank">
                    {langData('Service Agreement')}
                  </Link>
                  {langData(' must be read in conjunction with the ')}
                  <Link to="/terms-and-condition" target="_blank">
                    &quot;{langData(' Terms and condition ')}&quot;
                  </Link>
                  , &quot;
                  <Link to="/privacy-policy" target="_blank">
                    {langData(' Privacy policy ')}
                  </Link>
                  &quot; ,{langData(' and ')}&quot;
                  <Link to="/cancellation-policy" target="_blank">
                    {langData(' Cancellation and rescheduling policy ')}
                  </Link>
                  &quot;{' '}
                  {langData(
                    ' of Tractor Seva, which shall be considered fully binding. Users are strongly advised to carefully review all these policies before engaging in any service contract.',
                  )}
                </p>
              </li>
            </ol>
          </p>
        </div>
      </div>
    </>
  );
}

export default function IconSuccess({ width = '1em', height = '1em' }) {
  return (
    <svg width={width} height={height} viewBox="0 0 136 136" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="68" cy="68" r="65" fill="#B5DDA6" />
      <g filter="url(#filter0_d_109_14286)">
        <circle cx="68" cy="68" r="51" fill="#C4E7B8" />
      </g>
      <g filter="url(#filter1_d_109_14286)">
        <circle cx="68" cy="68" r="35" fill="#DEF7D5" />
      </g>
      <path
        d="M86 57.982C85.8619 58.4734 85.5371 58.8364 85.1784 59.1884C78.3304 65.8995 71.4854 72.6149 64.6433 79.3344C64.3202 79.6515 63.9833 79.9178 63.518 79.9812C62.8814 80.0676 62.3684 79.852 61.9184 79.4089C58.9939 76.5279 56.0644 73.6522 53.1297 70.7817C52.59 70.2522 52.0439 69.7291 51.5139 69.1917C51.0292 68.7002 50.8749 68.1097 51.1044 67.4621C51.3338 66.8145 51.8145 66.4316 52.5076 66.3373C53.0731 66.258 53.5619 66.4491 53.9618 66.8391C54.4731 67.3337 54.9781 67.8338 55.4854 68.3316C57.9736 70.773 60.461 73.2149 62.9476 75.6573C63.0289 75.7512 63.1019 75.8517 63.1657 75.9577L63.3338 75.999C63.4105 75.8745 63.4655 75.7294 63.5673 75.6296C70.014 69.2979 76.4621 62.9672 82.9115 56.6376C83.3155 56.2413 83.7711 55.9655 84.356 56.0035C85.1097 56.0526 85.6268 56.441 85.9022 57.1338C85.9354 57.2131 85.9661 57.2995 85.9984 57.3819L86 57.982Z"
        fill="#212121"
      />
      <defs>
        <filter id="filter0_d_109_14286" x="0" y="0" width="136" height="136" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset />
          <feGaussianBlur stdDeviation="8.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.13 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_109_14286" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_109_14286" result="shape" />
        </filter>
        <filter id="filter1_d_109_14286" x="15" y="15" width="106" height="106" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset />
          <feGaussianBlur stdDeviation="9" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.693012 0 0 0 0 0.693012 0 0 0 0 0.693012 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_109_14286" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_109_14286" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  hideHeader: false,
  prevScrollPos: window.scrollY,
};

export const TractorSelectionSlice = createSlice({
  name: 'tractor_selection',
  initialState,
  reducers: {
    setHideHeader: (state, action) => {
      state.hideHeader = action.payload;
    },
    setPrevScrollPos: (state, action) => {
      state.prevScrollPos = action.payload;
    },
  },
});
export const { setPrevScrollPos, setHideHeader } = TractorSelectionSlice.actions;
export default TractorSelectionSlice.reducer;

import './ourstatistics.css';
import React from 'react';

export default function OurStatistics({ data }) {
  return (
    <div className="our-statistics-container">
      <h5>{data?.heading}</h5>
      <h3>{data?.sub_heading}</h3>
      <div className="our-statistics-card-container">
        {data?.content.map((elem, num) => (
          <StatisticsCard key={num + 1} stsicn={elem.icon} limit={elem.count} ststitle={elem.description} />
        ))}
      </div>
    </div>
  );
}

function StatisticsCard({ stsicn, ststitle, limit }) {
  return (
    <div className="our-statistics-card">
      <div className="our-statistics-card-icn-container">
        <img className="our-statistics-card-icn" src={stsicn} alt="stat-icn" />
      </div>
      <div className="our-statistics-title-number-container">
        <div className="our-statistics-number">
          <Counter limit={limit} />
        </div>
        <div className="our-statistics-card-title">{ststitle}</div>
      </div>
    </div>
  );
}

function Counter({ limit }) {
  const [counter, setCounter] = React.useState(0);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setCounter((prevCounter) => {
        if (prevCounter < limit) {
          return prevCounter + 1;
        } else {
          clearInterval(interval);
          return prevCounter;
        }
      });
    }, 1);

    return () => {
      clearInterval(interval);
    };
  }, [limit]);

  const formattedCounter = limit >= 1000 ? `${counter}+` : counter;

  return <div>{formattedCounter}</div>;
  // return <div>{counter}</div>;
}

export default function IconPhone({ width = '1em', height = '1em', color = '#fff', ...props }) {
  return (
    <svg width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1804_94)">
        <circle cx="16" cy="16" r="15.5" stroke={color} />
        <path
          d="M14.6332 17.3679C13.1045 15.8392 12.7593 14.3104 12.6814 13.6979C12.6597 13.5286 12.7179 13.3587 12.8391 13.2384L14.0762 12.0018C14.2582 11.8199 14.2905 11.5365 14.1541 11.3184L12.1843 8.25974C12.0334 8.01818 11.7236 7.931 11.4688 8.05843L8.30664 9.5477C8.10064 9.64913 7.97948 9.86799 8.00287 10.0964C8.16856 11.6704 8.85479 15.5398 12.6574 19.3427C16.46 23.1455 20.3288 23.8314 21.9037 23.9971C22.1322 24.0205 22.351 23.8994 22.4524 23.6934L23.9417 20.5312C24.0687 20.277 23.9821 19.968 23.7415 19.8168L20.6829 17.8476C20.4648 17.711 20.1814 17.7431 19.9994 17.9249L18.7628 19.1621C18.6425 19.2832 18.4726 19.3415 18.3033 19.3197C17.6908 19.2419 16.162 18.8967 14.6332 17.3679Z"
          fill={color}
        />
        <path
          d="M20.5161 16.9996C20.1954 16.9996 19.9354 16.7396 19.9354 16.4189C19.9327 14.0149 17.9846 12.0668 15.5806 12.0641C15.26 12.0641 15 11.8041 15 11.4834C15 11.1628 15.26 10.9028 15.5806 10.9028C18.6257 10.9061 21.0933 13.3738 21.0967 16.4189C21.0967 16.7396 20.8367 16.9996 20.5161 16.9996Z"
          fill={color}
        />
        <path
          d="M23.4194 17C23.0987 17 22.8387 16.74 22.8387 16.4194C22.8342 12.4127 19.5874 9.16577 15.5807 9.16129C15.2601 9.16129 15.0001 8.90133 15.0001 8.58065C15.0001 8.25996 15.2601 8 15.5807 8C20.2284 8.00512 23.9949 11.7716 24 16.4194C24 16.5734 23.9388 16.721 23.8299 16.8299C23.721 16.9388 23.5734 17 23.4194 17Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1804_94">
          <rect width="32" height="32" fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
}

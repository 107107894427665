import { Controller, useFormContext } from 'react-hook-form';
import { useBookingContext } from '../booking-add';
import { useGetBookingWorkshop } from '../booking-api';
import { Button, Skeleton } from '@mui/material';
import { timeFormat } from '../../../config/common-function/date-time-format';
import IconTimeout from '../../../assets/icons/icon-common/icon-timeout';
import { useLanguage } from '../../../language/useLanguage';

export default function FormWorkshop() {
  const { watch, control, formState } = useFormContext();
  const { errors } = formState;
  const { setBookingDataSummary } = useBookingContext();
  const [customer_address_id, service_id, workshop_id] = watch(['customer_address_id', 'service_id', 'workshop_id']);
  const workshopList = useGetBookingWorkshop(customer_address_id, service_id);
  const langData = useLanguage();

  const AddWorkshop = (workshop_id) => {
    const workshopDetails = workshop_id ? workshopList.data?.find((workshop) => workshop.id == workshop_id) : null;
    setBookingDataSummary((prev_state) => {
      const newState = [...prev_state];
      newState[1] = WorkshopDetails(workshopDetails);
      return newState;
    });
  };

  return (
    <div className="form-wrapper" style={{ maxWidth: 'unset' }}>
      <div className="field-container">
        <label className="required" style={{ fontSize: 20 }}>
          {langData('Available Workshop at Your Address')}
        </label>
        <Controller
          name="workshop_id"
          rules={{ required: langData('Select Workshop') }}
          control={control}
          render={(props) => {
            return (
              <div className="address-card-container">
                {workshopList.isLoading ? (
                  [1, 2, 3].map((no) => <LoadingServiceCard key={no} />)
                ) : workshopList.data.length === 0 ? (
                  <NoWorkshop />
                ) : (
                  workshopList.data.map((st) => (
                    <WorkshopCard
                      key={st.id}
                      imagePath={`/icons/customer-workshop-demo.svg`}
                      title={st.workshop_name ?? 'N/A'}
                      startTime={st.WorkshopOperatingDay?.monday_start}
                      endTime={st.WorkshopOperatingDay?.monday_end}
                      address={st.label ?? 'N/A'}
                      addWorkshop={AddWorkshop}
                      workshop={workshop_id}
                      id={st.id}
                      onChange={props.field.onChange}
                      disabled={!st.isKitSuffiecient}
                    />
                  ))
                )}
              </div>
            );
          }}
        />
        <p className="form-error-text">{errors.workshop_id?.message}</p>
      </div>
    </div>
  );
}

function NoWorkshop() {
  return (
    <div style={{ textAlign: 'center', width: '100%' }}>
      <img src="/icons/no-workshop.svg" alt="No-Workshop" />
      <p style={{ fontSize: 20, margin: '16px 0px' }}>No workshop Available</p>
      <p>Please try using another Address or Preference</p>
    </div>
  );
}

function WorkshopDetails(workshopDetails) {
  // console.debug(workshopDetails);
  const langData = useLanguage();
  return (
    <div key="workshop" className="workshop">
      <p className="title">{langData('Selected Workshop')}</p>
      <div className="workshop-details">
        <p className="details">{workshopDetails.workshop_name}</p>
        <p className="details">{workshopDetails.label}</p>
      </div>
    </div>
  );
}

function WorkshopCard({
  imagePath = '/icons/customer-workshop-demo.svg',
  title = 'Basic Service',
  startTime = 'N/A',
  endTime = 'N/A',
  address,
  workshop,
  addWorkshop,
  id,
  onChange,
  disabled,
}) {
  function select() {
    addWorkshop(id);
    onChange(id);
  }
  // console.debug(workshop, id, startTime, endTime);
  const langData = useLanguage();
  return (
    <div className="address-card">
      <div className="image-container">
        <img src={imagePath} alt={imagePath} />
      </div>
      <div className="address-card-details" style={{ position: 'relative' }}>
        <div className="card-title">{title}</div>
        <div className="card-address">
          <p className="card-subtitle">{langData('Address')}</p>
          <p className="card-value">{address}</p>
        </div>
        <div className="card-address">
          <p className="card-subtitle">{langData('Timing')}</p>
          <p className="card-value">{`${timeFormat(startTime)} - ${timeFormat(endTime)}`}</p>
        </div>
        {disabled && (
          <div
            style={{
              position: 'absolute',
              top: 4,
              right: 8,
              fontSize: 14,
              fontWeight: 400,
              backgroundColor: 'var(--clr-primary)',
              color: 'white',
              padding: '4px 8px',
              borderRadius: 3,
            }}
          >
            No Kits Available
          </div>
        )}
        <div className="card-btn">
          {id && workshop !== id && (
            <Button variant="contained" onClick={select} disabled={disabled}>
              {langData('Select')}
            </Button>
          )}
          {id && workshop == id && (
            <Button disabled variant="outlined">
              {langData('Selected')}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

function LoadingServiceCard() {
  return (
    <div className="address-card">
      <div className="image-container">
        <Skeleton variant="circular" width={60} height={60} />
      </div>
      <div className="card-title">
        <Skeleton variant="text" />
      </div>
      <p className="card-subtitle">
        <Skeleton variant="text" />
      </p>
      <span className="tag">
        <div className="icon">
          <IconTimeout />
        </div>
        <div className="text">
          <Skeleton variant="text" width={60} />
        </div>
      </span>
      <div className="card-btn">
        <Button variant="contained" disabled>
          Select
        </Button>
      </div>
    </div>
  );
}

export default function IconTractorTab({ height = '1em', width = '1em', activeTab, ...params }) {
  return (
    <svg width={width} height={height} viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg" {...params}>
      <circle cx="17" cy="17" r="17" fill={activeTab == '1' ? '#EC2726' : '#002852'} />
      <path
        d="M16.3107 19.7792C16.3075 20.3815 16.1196 20.9694 15.7707 21.4686C15.4218 21.9679 14.9276 22.356 14.3505 22.5842C13.7733 22.8123 13.1392 22.8701 12.528 22.7504C11.9169 22.6306 11.3562 22.3387 10.9167 21.9113C10.4772 21.484 10.1787 20.9405 10.0589 20.3494C9.93898 19.7583 10.0031 19.1462 10.2432 18.5903C10.4832 18.0344 10.8884 17.5597 11.4076 17.226C11.9268 16.8924 12.5367 16.7149 13.1603 16.7158C13.5758 16.716 13.9871 16.7956 14.3706 16.9498C14.7541 17.1041 15.1023 17.3301 15.395 17.6148C15.6878 17.8995 15.9194 18.2372 16.0766 18.6087C16.2337 18.9802 16.3133 19.3779 16.3107 19.7792ZM14.1523 19.7792C14.1535 19.589 14.0962 19.4027 13.9879 19.2438C13.8795 19.085 13.7249 18.9607 13.5435 18.8866C13.362 18.8126 13.162 18.7921 12.9685 18.8277C12.7751 18.8634 12.5969 18.9536 12.4564 19.0869C12.316 19.2203 12.2196 19.3908 12.1794 19.5771C12.1392 19.7633 12.157 19.9569 12.2305 20.1333C12.3041 20.3098 12.4301 20.4612 12.5927 20.5686C12.7553 20.676 12.9472 20.7344 13.1441 20.7365C13.2755 20.7377 13.4059 20.7138 13.5277 20.6663C13.6495 20.6187 13.7605 20.5484 13.8541 20.4593C13.9478 20.3703 14.0223 20.2643 14.0735 20.1474C14.1247 20.0304 14.1514 19.9049 14.1523 19.778V19.7792Z"
        fill="white"
      />
      <path
        d="M24.2904 20.2462C24.2906 20.7538 24.1349 21.25 23.843 21.6721C23.5512 22.0942 23.1363 22.4233 22.6508 22.6176C22.1653 22.8119 21.6311 22.8628 21.1156 22.7638C20.6002 22.6648 20.1268 22.4204 19.7552 22.0615C19.3836 21.7026 19.1305 21.2453 19.0281 20.7474C18.9256 20.2496 18.9783 19.7336 19.1794 19.2647C19.3806 18.7958 19.7213 18.395 20.1583 18.1131C20.5953 17.8312 21.109 17.6809 21.6346 17.681C21.9835 17.6805 22.3291 17.7465 22.6516 17.8752C22.974 18.0039 23.267 18.1929 23.5137 18.4312C23.7605 18.6695 23.9561 18.9525 24.0894 19.2639C24.2226 19.5754 24.2909 19.9092 24.2904 20.2462Z"
        fill="white"
      />
      <path
        d="M18.0143 11.9848C18.2584 13.0222 18.4972 14.044 18.7439 15.0637C18.7578 15.1216 18.8335 15.2032 18.8869 15.2084C19.4213 15.2607 19.9556 15.2993 20.511 15.3435V14.5444C20.511 14.3937 20.511 14.243 20.511 14.0922C20.5142 13.7964 20.7198 13.5825 20.9997 13.5805C21.2796 13.5785 21.4929 13.794 21.495 14.0866C21.4975 14.5231 21.495 14.96 21.495 15.413C21.7683 15.436 22.0363 15.4657 22.3048 15.4794C22.7676 15.5031 23.0997 15.7812 23.131 16.2298C23.1585 16.6249 23.1421 17.0229 23.1445 17.42C23.1445 17.4538 23.14 17.4875 23.1359 17.5446C22.0335 16.9867 20.9319 16.9453 19.8599 17.5534C18.7878 18.1616 18.2592 19.1138 18.2029 20.3321C17.8971 20.3321 17.595 20.3442 17.2945 20.3277C17.0993 20.3173 16.9353 20.1372 16.9061 19.9338C16.8625 19.6271 16.8547 19.3128 16.7861 19.0117C16.6176 18.2863 16.2269 17.6284 15.6662 17.1256C15.1055 16.6229 14.4014 16.2993 13.6477 16.1981C12.6111 16.0606 11.6788 16.3022 10.8349 16.9043C10.7237 16.9814 10.5918 17.0249 10.4555 17.0293C10.2422 17.0293 10.0946 16.8995 10.0268 16.6993C9.9925 16.6055 9.99108 16.5033 10.0228 16.4087C10.0545 16.3141 10.1175 16.2325 10.2019 16.1768C10.5271 15.9689 10.8637 15.7784 11.2008 15.5891C11.2752 15.5489 11.3171 15.5155 11.3171 15.4235C11.3134 14.3129 11.3146 13.2027 11.3142 12.0921C11.3142 12.0579 11.3093 12.0242 11.3064 11.9868C11.2181 11.9809 11.1302 11.9707 11.0429 11.9562C10.9266 11.9307 10.8238 11.8646 10.7538 11.7702C10.6838 11.6759 10.6514 11.5598 10.6627 11.4438C10.6729 11.3301 10.7249 11.2239 10.809 11.1448C10.8931 11.0658 11.0037 11.0192 11.1202 11.0137C11.4022 11.0032 11.6842 11.0004 11.9661 11C13.7199 11 15.4737 11 17.2275 11C17.4889 11 17.7504 11.0064 18.0118 11.0105C18.3057 11.0149 18.5178 11.1941 18.5379 11.4526C18.5581 11.7111 18.385 11.9112 18.1026 11.9679C18.08 11.9743 18.0554 11.9776 18.0143 11.9848ZM12.3077 15.2261C14.1278 15.1345 15.9319 15.2181 17.7545 15.1767C17.5025 14.1047 17.2518 13.0475 17.0051 11.9932H12.3077V15.2261Z"
        fill="white"
      />
    </svg>
  );
}

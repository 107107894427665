import { Box, styled } from '@mui/material';
import { useLanguage } from '../../../../../language/useLanguage';
import { LazyImage } from '../../../../../assets/image/lazyImage';
import { useEffect, useState } from 'react';
import { SectionTagGrey } from '../../../../common/tags';

export default function OurBenefits({ data }) {
  const langData = useLanguage();
  return (
    <OurBenefitsContainer>
      <SectionTitle>
        <div>
          <SectionTagGrey text={data.tag2} />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
          <h3>{data.heading2}</h3>
          <p>{data.sub_heading2}</p>
        </div>
      </SectionTitle>
      <OurBenefitsWrapper>
        <LeftContainer>
          <h4>{data.benefits_description}</h4>
          <CardWrapper>
            {data.benefits_content?.map((a, index) => (
              <AboutUsBenefitscards key={index} bnfticn={a.icon} bnfttitle={langData(a.title)} bnftsubtitle={langData(a.description)} />
            ))}
          </CardWrapper>
        </LeftContainer>
        {/* <RightContainer> */}
        <AnalaticsSectionContainer>
          <AnalaticsSectionWrapper>
            <AnalaticsCardContainer>
              <CardDetailsWrapper>
                <LazyImage className="our-statistics-card-icn" name={data.insights_content[0]?.icon} alt="stat-icn" height={50} width={50} />
                <div>
                  <CardCounts>
                    <Counter limit={data.insights_content[0]?.count} />
                  </CardCounts>
                  <CardDescription>{data.insights_content[0]?.description}</CardDescription>
                </div>
              </CardDetailsWrapper>
            </AnalaticsCardContainer>
            <AnalaticsCardContainer>
              <CardDetailsWrapper>
                <LazyImage className="our-statistics-card-icn" name={data.insights_content[2]?.icon} alt="stat-icn" height={50} width={50} />
                <div>
                  <CardCounts>
                    <Counter limit={data.insights_content[2]?.count} />
                  </CardCounts>
                  <CardDescription>{data.insights_content[2]?.description}</CardDescription>
                </div>
              </CardDetailsWrapper>
            </AnalaticsCardContainer>
          </AnalaticsSectionWrapper>
          <AnalaticsSectionWrapper>
            <AnalaticsCardContainer>
              <CardDetailsWrapper>
                <LazyImage className="our-statistics-card-icn" name={data.insights_content[1]?.icon} alt="stat-icn" height={50} width={50} />
                <div>
                  <CardCounts>
                    <Counter limit={data.insights_content[1]?.count} />
                  </CardCounts>
                  <CardDescription>{data.insights_content[1]?.description}</CardDescription>
                </div>
              </CardDetailsWrapper>
            </AnalaticsCardContainer>
            <AnalaticsCardContainer>
              <CardDetailsWrapper>
                <LazyImage className="our-statistics-card-icn" name={data.insights_content[3]?.icon} alt="stat-icn" height={50} width={50} />
                <div>
                  <CardCounts>
                    <Counter limit={data.insights_content[3]?.count} />
                  </CardCounts>
                  <CardDescription>{data.insights_content[3]?.description}</CardDescription>
                </div>
              </CardDetailsWrapper>
            </AnalaticsCardContainer>
          </AnalaticsSectionWrapper>
        </AnalaticsSectionContainer>
        {/* </RightContainer> */}
      </OurBenefitsWrapper>
      <BannerSectionContainer>
        <div className="banner_content">
          <h3>{data.banner_content1}</h3>
          <p>{data.banner_content2}</p>
        </div>
        <Box
          component={'div'}
          style={{
            display: 'flex',
            gap: 8,
            flexWrap: 'wrap',
            '@media (max-width: 600px)': {
              marginTop: '-150px',
            },
          }}
          className="banner_content_phone_button"
        >
          {/* <img src="./images/men-image-cta.png" />
          <img src="./images/tractor-image-cta.png" /> */}
          <img
            style={{ cursor: 'pointer' }}
            src="./images/play-store-buton-v2.svg"
            onClick={() => window.open('https://play.google.com/store/apps/details?id=com.tractorseva.customer&pli=1', '_blank')}
          />
          <img
            style={{ cursor: 'pointer' }}
            src="./images/app-store-button-v2.svg"
            onClick={() => window.open('https://apps.apple.com/in/app/ts-customer/id6472632200', '_blank')}
          />
        </Box>
      </BannerSectionContainer>
    </OurBenefitsContainer>
  );
}

const OurBenefitsContainer = styled('div')({
  // padding: "4rem",
  padding: '4rem var(--padding-inline)',
  backgroundColor: '#FBFBFB',
  marginInline: 'calc(0px - var(--padding-inline))',
  display: 'flex',
  flexDirection: 'column',
  gap: 48,
});

const OurBenefitsWrapper = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  gap: 68,
});

const LeftContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flex: '1 1 300px',
  gap: 48,
  h4: {
    fontSize: 26,
    fontWeight: 500,
  },
  '@media screen and (min-width: 750px) and (max-width: 1300px)': {
    flex: '1 1 450px',
    ' div.banner_content': {
      flex: '1 1 400px',
    },
    h4: {
      fontSize: '1.5rem',
      // fontWeight: 500,
    },
  },
  '@media screen and  (max-width: 600px)': {
    h4: {
      fontSize: '1.5rem',
    },
  },
});

const RightContainer = styled('div')({
  display: 'flex',
  flex: '1 1 300px',
  flexDirection: 'column',
  gap: 24,
});

const CardWrapper = styled('div')({
  // display: "flex",
  // gap: 46,
  ' @media only screen and (max-width: 600px)': {
    gridTemplateColumns: '1fr',
  },
  display: 'grid',
  // gridTemplateColumns: "repeat(auto-fit, minmax(134px, 1fr))",
  gridTemplateColumns: 'repeat(3,  1fr)',
  rowGap: '12px',
  gap: 46,
});

const CategoryCard = styled('div')({
  display: 'grid',
  gridTemplateRows: 'subgrid',
  gridRow: '1/4',
  gap: '1rem',
  h3: {
    fontSize: 24,
    fontWeight: 500,
    lineHeight: '2rem',
  },
  p: {
    fontSize: 16,
  },
  '@media only screen and (max-width: 600px)': {
    gridRow: 'auto',
    gridTemplateRows: 'none',
    h3: {
      fontSize: '1.5rem',
      fontWeight: 500,
      lineHeight: '2rem',
    },
  },
});

function AboutUsBenefitscards({ bnfticn, bnfttitle, bnftsubtitle }) {
  return (
    <CategoryCard>
      <LazyImage name={bnfticn} height={'2rem'} width={'2rem'} />
      <h3>{bnfttitle}</h3>
      <p>{bnftsubtitle}</p>
    </CategoryCard>
  );
}

const CardCounts = styled('span')({
  color: 'var(--clr-primary)',
  fontWeight: 700,
  fontSize: 32,
});

const CardDescription = styled('p')({
  color: '#6D7D93',
});

const CardDetailsWrapper = styled('div')({
  display: 'flex',
  gap: 14,
  alignItems: 'center',
});

const AnalaticsCardContainer = styled('div')({
  backgroundColor: '#fff',
  padding: 24,
  borderLeft: '2px solid #EC2726',
  boxShadow: '0px 1px 6px rgba(0, 0, 0, 0.1)',
  borderTopRightRadius: 8,
  borderBottomRightRadius: 8,
  height: 120,
  minWidth: 350,
  display: 'flex',
  alignItems: 'center',
  '@media screen and (min-width: 750px) and (max-width: 1300px)': {
    height: 100,
  },
  '@media (max-width: 600px)': {
    width: '100%',
    height: 84,
  },
});

const AnalaticsSectionContainer = styled('div')({
  display: 'flex',
  gap: 32,
  flex: '1 1 100px',
  ' >div:nth-of-type(1)': {
    // marginTop: 22,
    flexGrow: 1,
  },
  ' >div:nth-of-type(2)': {
    marginTop: 22,
    flexGrow: 1,
  },
  '@media screen and (min-width: 750px) and (max-width: 1300px)': {
    flexDirection: 'column',
    ' >div:nth-of-type(2)': {
      marginTop: 0,
    },
  },
  '@media (max-width: 600px)': {
    flexWrap: 'wrap',
    ' >div:nth-of-type(2)': {
      marginTop: 0,
    },
  },
});

const AnalaticsSectionWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 32,
  '@media (max-width: 600px)': {
    flexGrow: 1,
    // flexWrap: "wrap",
  },
});

const BannerSectionContainer = styled('div')({
  backgroundImage: 'url(./images/red-background-cta.png)',
  padding: '46px',
  borderRadius: 8,
  minHeight: '300px',
  display: 'flex',
  alignItems: 'center',
  backgroundSize: 'cover',
  justifyContent: 'space-between',
  p: { color: '#ffffffcc', fontSize: 18, fontWeight: 400 },
  h3: { color: '#fff', fontSize: 32, fontWeight: 600 },
  ' div.banner_content': {
    flexBasis: 400,
    gap: '1rem',
    display: 'flex',
    flexDirection: 'column',
  },
  ' @media (max-width: 600px)': {
    height: '557px',
    '.banner_content_phone_button': {
      marginTop: '-150px',
    },
  },
  marginBottom: '-16rem',
  ' @media only screen and (max-width: 600px)': {
    h3: { fontSize: 28 },
    backgroundImage: 'url(./images/red-backgroud-cta-responsive.png)',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    padding: '28px',
    ' div': {
      display: 'flex',
      flexDirection: 'column',
      gap: '1.5rem',
      marginBottom: '3rem',
    },
  },
  '@media screen and (min-width: 750px) and (max-width: 1300px)': {
    ' div.banner_content': {
      flexBasis: 400,
    },
  },
});

const SectionTitle = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 24,
  h3: {
    color: 'var(--clr-primary)',
    fontSize: '3rem',
    fontWeight: 500,
  },
  p: {
    color: '#6D7D93',
    fontSize: 18,
    fontWeight: 400,
  },
  ' @media only screen and (max-width: 600px)': {
    h3: {
      fontSize: '2rem',
    },
    p: {
      fontSize: '1rem',
    },
  },
});

function Counter({ limit }) {
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCounter((prevCounter) => {
        if (prevCounter < limit) {
          return prevCounter + 1;
        } else {
          clearInterval(interval);
          return prevCounter;
        }
      });
    }, 1);

    return () => {
      clearInterval(interval);
    };
  }, [limit]);

  const formattedCounter = limit >= 1000 ? `${counter}+` : counter;

  return <div>{formattedCounter}</div>;
}

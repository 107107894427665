import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  tractorModelId: null,
  tractorModelName: '',
  tractorBrandID: null,
  tractorBrandName: '',
  selectedService: '',
  selectServicePriceTotal: '',
  selectServicePriceOffer: '',
  isAppointmentModelOpen: false,
  isEditAppointmentModelOpen: false,
  serviceId: null,
  chassisNO: '',
  change: false,
  serviceEdit: false,
  customerTractorId: null,
};

export const customerOrderSlice = createSlice({
  name: 'customer_order',
  initialState,
  reducers: {
    setTractorModelId: (state, action) => {
      state.tractorModelId = action.payload;
    },
    setTractorModelName: (state, action) => {
      state.tractorModelName = action.payload;
    },
    setTractorBrandName: (state, action) => {
      state.tractorBrandName = action.payload;
    },
    setTractorBrandId: (state, action) => {
      state.tractorBrandID = action.payload;
    },
    setSelectedService: (state, action) => {
      state.selectedService = action.payload;
    },
    setServiceId: (state, action) => {
      state.serviceId = action.payload;
    },
    setSelectServicePriceTotal: (state, action) => {
      state.selectServicePriceTotal = action.payload;
    },
    setSelectServicePriceOffer: (state, action) => {
      state.selectServicePriceOffer = action.payload;
    },
    setIsAppointmentModelOpen: (state, action) => {
      state.isAppointmentModelOpen = action.payload;
    },
    setIsEditAppointmentModelOpen: (state, action) => {
      state.isEditAppointmentModelOpen = action.payload;
    },
    setChassisNo: (state, action) => {
      state.chassisNO = action.payload;
    },
    setChange: (state, action) => {
      state.change = action.payload;
    },
    setServiceEdit: (state, action) => {
      state.serviceEdit = action.payload;
    },
    setCustomerTractorId: (state, action) => {
      state.customerTractorId = action.payload;
    },
  },
});
export const {
  setCustomerTractorId,
  setServiceEdit,
  setChange,
  setChassisNo,
  setIsEditAppointmentModelOpen,
  setServiceId,
  setTractorModelId,
  setTractorModelName,
  setTractorBrandName,
  setTractorBrandId,
  setSelectedService,
  setSelectServicePriceTotal,
  setSelectServicePriceOffer,
  setIsAppointmentModelOpen,
} = customerOrderSlice.actions;
export default customerOrderSlice.reducer;

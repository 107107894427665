export default function IconTractorTime({ height = '1em', width = '1em', color = '#002852', color2 = '#6D7D93', opacity = '0.05', ...params }) {
  return (
    <svg width={width} height={height} viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg" {...params}>
      <circle cx="17" cy="17" r="17" fill={color} fillOpacity={opacity} />
      <g clipPath="url(#clip0_2010_2591)">
        <path
          d="M23.364 10.6361C21.6641 8.93618 19.404 8 17 8C14.596 8 12.3359 8.93618 10.636 10.6361C8.93614 12.3359 8 14.596 8 17C8 19.404 8.93614 21.6641 10.636 23.3639C12.3359 25.0638 14.596 26 17 26C19.404 26 21.6641 25.0638 23.364 23.3639C25.0639 21.6641 26 19.404 26 17C26 14.596 25.0639 12.3359 23.364 10.6361ZM17 24.2335C13.0114 24.2335 9.76653 20.9885 9.76653 17C9.76653 16.9935 9.76674 16.987 9.76678 16.9805C9.76678 16.9789 9.76653 16.9773 9.76653 16.9756C9.76653 16.9729 9.76688 16.9702 9.76695 16.9674C9.7845 13.0267 12.9692 9.82053 16.9017 9.7678C16.9099 9.76741 16.9181 9.76657 16.9264 9.76657C16.9314 9.76657 16.9363 9.76716 16.9412 9.7673C16.9608 9.76716 16.9804 9.76657 17 9.76657C20.9886 9.76657 24.2335 13.0115 24.2335 17C24.2335 20.9886 20.9886 24.2335 17 24.2335Z"
          fill={color2}
        />
        <path
          d="M22.4944 17.4345C22.2002 17.4345 21.9618 17.1961 21.9618 16.9019C21.9618 16.6078 22.2002 16.3694 22.4944 16.3694H23.1363C22.8348 13.4136 20.4376 11.0688 17.4588 10.8486V11.4074C17.4588 11.7016 17.2204 11.94 16.9263 11.94C16.6322 11.94 16.3937 11.7016 16.3937 11.4074V10.8614C13.459 11.1489 11.1214 13.5017 10.8569 16.443H11.4318C11.7259 16.443 11.9643 16.6814 11.9643 16.9755C11.9643 17.2696 11.7259 17.5081 11.4318 17.5081H10.8525C11.0974 20.4967 13.4819 22.8886 16.4673 23.1451V22.47C16.4673 22.1759 16.7058 21.9374 16.9999 21.9374C17.294 21.9374 17.5324 22.1759 17.5324 22.47V23.1451C20.5423 22.8865 22.9413 20.4574 23.1528 17.4345H22.4944ZM19.1557 15.5973L17.3765 17.3765C17.327 17.426 17.2683 17.4652 17.2037 17.492C17.1391 17.5187 17.0699 17.5325 16.9999 17.5325C16.93 17.5325 16.8607 17.5187 16.7961 17.492C16.7315 17.4652 16.6728 17.426 16.6234 17.3765L13.5263 14.2795C13.3184 14.0715 13.3184 13.7344 13.5263 13.5264C13.7343 13.3184 14.0715 13.3184 14.2795 13.5264L17 16.2468L18.4026 14.8442C18.6106 14.6362 18.9478 14.6362 19.1557 14.8442C19.3637 15.0521 19.3637 15.3893 19.1557 15.5973Z"
          fill={color2}
        />
      </g>
      <defs>
        <clipPath id="clip0_2010_2591">
          <rect width="18" height="18" fill="white" transform="translate(8 8)" />
        </clipPath>
      </defs>
    </svg>
  );
}

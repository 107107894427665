export default function IconLogoutProfile({ width = '1em', height = '1em', color = 'var(--clr-primary)', color2 = 'var(--clr-secondary)', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 19 17" fill="none" {...props}>
      <path
        d="M1 8.57546V6.66531C1.01018 6.56962 1.02125 6.47439 1.02967 6.37915C1.10892 5.48559 1.14921 4.5861 1.27496 3.69938C1.48704 2.19158 2.3283 1.14355 3.66236 0.511536C4.2021 0.255452 4.79231 0.196671 5.37277 0.124676C6.95345 -0.0712607 8.53281 -0.0439207 10.0989 0.261376C11.7469 0.580342 12.7998 1.61744 13.2563 3.24963C13.4289 3.86433 13.4489 4.5255 13.5113 5.17027C13.5458 5.52478 13.2616 5.81549 12.9158 5.83782C12.581 5.85924 12.2937 5.59085 12.2556 5.23178C12.2042 4.74604 12.156 4.25985 12.0873 3.77684C11.9479 2.79124 11.088 1.82158 10.1454 1.5942C9.80555 1.51621 9.46191 1.45704 9.11593 1.41694C7.91726 1.26589 6.70516 1.26589 5.50649 1.41694C5.3958 1.43107 5.28511 1.45021 5.13191 1.47345C5.48612 1.62473 5.79252 1.74685 6.09227 1.88901C6.75111 2.20115 7.40818 2.53059 7.95189 3.02818C8.88171 3.8739 9.42498 4.97023 9.68533 6.19916C9.81816 6.83345 9.85668 7.49234 9.90671 8.14303C10.0254 9.72888 10.0378 11.3212 9.94391 12.9089C9.92885 13.1677 9.91424 13.426 9.89963 13.6876C10.8596 13.5796 11.8549 12.6396 12.051 11.6261C12.152 11.104 12.1949 10.569 12.2521 10.0386C12.2963 9.63626 12.5837 9.36012 12.945 9.39794C13.3063 9.43576 13.5502 9.76248 13.5082 10.1635C13.4878 10.3612 13.4737 10.5594 13.4502 10.7558C13.3838 11.3136 13.3559 11.8822 13.1363 12.4053C12.5164 13.879 11.4564 14.7835 9.889 15.0118C9.7637 15.03 9.72607 15.0851 9.69906 15.194C9.60237 15.6105 9.41119 15.9974 9.14117 16.3232C8.70947 16.8344 8.18258 17.0805 7.50736 16.9766C6.58908 16.8297 5.69643 16.5462 4.85739 16.135C4.22512 15.832 3.5924 15.5148 3.06861 15.0373C2.07238 14.13 1.51007 12.9544 1.27407 11.6239C1.15187 10.9335 1.12929 10.2241 1.06642 9.52279C1.03808 9.20884 1.0217 8.88987 1 8.57546Z"
        fill={color2}
      />
      <path
        d="M18.0018 7.79184C17.8761 7.9536 17.7631 8.12858 17.6223 8.2753C16.9467 8.97946 16.2664 9.67815 15.5816 10.3714C15.2035 10.755 14.6199 10.5956 14.4999 10.082C14.4433 9.8396 14.5181 9.6341 14.6864 9.46095L15.7069 8.41291C15.7446 8.37464 15.7791 8.33317 15.8398 8.2671H15.6325C14.2662 8.2671 12.8998 8.2671 11.5334 8.2671C11.0747 8.2671 10.7718 7.86065 10.898 7.42913C10.9799 7.15026 11.2199 6.97346 11.5277 6.97346C12.3021 6.97346 13.0773 6.97346 13.8509 6.97346H15.8393C15.795 6.91742 15.7663 6.8796 15.7344 6.84679C15.3948 6.49684 15.0534 6.14825 14.7138 5.79876C14.426 5.50166 14.4096 5.11525 14.6695 4.84459C14.9294 4.57392 15.3155 4.58714 15.5993 4.87831C16.3281 5.62408 17.0547 6.37244 17.7791 7.12338C17.8676 7.21724 17.9283 7.34255 18.0005 7.45373L18.0018 7.79184Z"
        fill={color}
      />
    </svg>
  );
}

import { formatAmount } from '../calculation';
import './get-discount.css';
export function GetDiscount({ mrp, offer }) {
  if (mrp === '' || offer === '') return;

  const MRP = formatAmount(mrp);
  const OFFER = formatAmount(offer);
  const discount = ((MRP - OFFER) * 100) / MRP;
  if (discount < 0) return 0;
  return <div className="discount">{`${formatAmount(discount)}% off`}</div>;
}

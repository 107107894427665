export default function IconCart({ width = '1.1em', height = '0.7em', color = 'currentColor', ...props }) {
  return (
    <svg width={width} height={height} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M4.59246 22.79L5.14032 19.6828C5.78784 16.0104 6.1116 14.1743 7.39371 13.0871C8.67583 12 10.5176 12 14.201 12H21.799C25.4824 12 27.3241 12 28.6063 13.0871C29.8884 14.1743 30.2121 16.0104 30.8596 19.6828L31.4076 22.79C32.3036 27.8717 32.7516 30.4125 31.372 32.0813C29.9925 33.75 27.4438 33.75 22.3468 33.75H13.6531C8.55609 33.75 6.00756 33.75 4.62799 32.0813C3.24843 30.4125 3.69645 27.8717 4.59246 22.79Z"
        // fill={color}
        stroke={color}
        strokeWidth="1.5"
      />
      <path
        d="M11.25 12L11.5017 8.97927C11.7834 5.59959 14.6086 3 18 3C21.3914 3 24.2166 5.59959 24.4983 8.97927L24.75 12"
        // fill={color}
        stroke={color}
        strokeWidth="1.5"
      />
      <path
        d="M22.5 16.5C22.305 18.6197 20.3486 20.25 18 20.25C15.6514 20.25 13.695 18.6197 13.5 16.5"
        // fill={color}
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default function IconLogoText({ width = '3.02em', height = '1em', color1 = 'var(--clr-primary)', color2 = 'var(--clr-secondary)', ...props }) {
  return (
    <svg width={width} height={height} viewBox="0 0 302 100" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_1_14)">
        <path d="M42.2444 12.2454H5.28055L0 4.31103H42.2444V12.2454ZM18.4819 14.8902H29.043V41.3382H18.4819V14.8902Z" fill={color2} />
        <path
          d="M58.0861 41.3382H47.525V14.8902H58.0861V41.3382ZM73.9277 12.2454H47.525L42.2444 4.31103H73.9277C82.7286 4.31103 87.1291 7.83743 87.1291 14.8902V17.535C87.1291 23.1949 84.2952 26.5803 78.6274 27.6911L87.1291 41.3382H73.9277L60.7263 20.1798H73.9277C75.6879 20.1798 76.568 19.2982 76.568 17.535V14.8902C76.568 13.127 75.6879 12.2454 73.9277 12.2454Z"
          fill={color2}
        />
        <path d="M104.898 10.5528L110.364 0L131.75 41.3382H120.397L104.898 10.5528ZM108.806 24.6231L100.33 41.3382H88.9773L103.288 13.6736L108.806 24.6231Z" fill={color2} />
        <path
          d="M142.839 30.759C142.839 32.5222 143.719 33.4038 145.479 33.4038V41.3382C136.678 41.3382 132.278 37.8118 132.278 30.759V14.8902C132.278 7.83743 136.678 4.31103 145.479 4.31103H156.04V12.2454H145.479C143.719 12.2454 142.839 13.127 142.839 14.8902V30.759ZM161.321 14.8902C161.321 13.127 160.441 12.2454 158.681 12.2454V4.31103C167.481 4.31103 171.882 7.83743 171.882 14.8902V20.1798H161.321V14.8902ZM158.681 41.3382H148.119V33.4038H158.681C160.441 33.4038 161.321 32.5222 161.321 30.759V25.4694H171.882V30.759C171.882 37.8118 167.481 41.3382 158.681 41.3382Z"
          fill={color2}
        />
        <path d="M214.126 12.2454H177.162L171.882 4.31103H214.126V12.2454ZM190.364 14.8902H200.925V41.3382H190.364V14.8902Z" fill={color2} />
        <path
          d="M246.127 14.8902C246.127 13.127 245.246 12.2454 243.486 12.2454V4.31103C252.287 4.31103 256.688 7.83743 256.688 14.8902V30.759C256.688 37.8118 252.287 41.3382 243.486 41.3382H232.925V33.4038H243.486C245.246 33.4038 246.127 32.5222 246.127 30.759V14.8902ZM227.645 30.759C227.645 32.5222 228.525 33.4038 230.285 33.4038V41.3382C221.484 41.3382 217.083 37.8118 217.083 30.759V14.8902C217.083 7.83743 221.484 4.31103 230.285 4.31103H240.846V12.2454H230.285C228.525 12.2454 227.645 13.127 227.645 14.8902V30.759Z"
          fill={color2}
        />
        <path
          d="M272.529 41.3382H261.968V14.8902H272.529V41.3382ZM288.371 12.2454H261.968L256.688 4.31103H288.371C297.172 4.31103 301.572 7.83743 301.572 14.8902V17.535C301.572 23.1949 298.738 26.5803 293.071 27.6911L301.572 41.3382H288.371L275.17 20.1798H288.371C290.131 20.1798 291.011 19.2982 291.011 17.535V14.8902C291.011 13.127 290.131 12.2454 288.371 12.2454Z"
          fill={color2}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.3435 100H290.601L300.787 53.5437L149.991 53.5437L1.18311 53.5437L15.3435 100ZM130.188 68.8989C130.188 68.8989 146.505 68.9247 150.995 68.9247L152.97 73.4458H132.137L130.188 68.8989ZM151.303 76.0034V79.9819H137.346V82.4879H152.97C152.97 82.4879 152.919 82.6171 151.277 87.009H132.137C132.137 76.4623 132.137 76.0002 132.157 75.9994C132.158 75.9994 132.159 76.0006 132.16 76.0017C132.161 76.0026 132.162 76.0034 132.163 76.0034H151.303ZM114.245 75.9259H129.87V87.009H109.037V82.7462H124.636V79.9819H109.037V73.4458L107.087 68.9247H128.048L129.87 73.1099H114.245V75.9259ZM170.356 68.8989L164.25 82.4362L158.169 68.8989H152.705L160.838 86.9831H167.662C167.666 86.9831 167.667 86.9883 167.67 86.9893C167.694 86.9981 167.839 86.6759 171.099 79.4148L171.104 79.4021L171.105 79.4008C172.228 76.9 173.719 73.5775 175.701 69.1645L177.537 73.4199V86.9831H182.771V73.4199H193.238V77.941H184.489V82.4621H193.238V86.9831H198.369C198.375 86.9831 198.379 86.9879 198.384 86.9923C198.388 86.9961 198.391 86.9995 198.394 86.9993C198.427 86.9966 198.427 86.5862 198.424 81.863V81.8615V81.8509C198.422 79.9047 198.421 77.2281 198.421 73.5491L196.394 68.8989H175.821H175.587H170.356Z"
          fill={color1}
        />
      </g>
      <defs>
        <clipPath id="clip0_1_14">
          <rect width="301.572" height="100" fill={color1} />
        </clipPath>
      </defs>
    </svg>
  );
}

import { useLanguage } from '../../../../../language/useLanguage';
import './get-started.css';

export default function GetStarted() {
  const langData = useLanguage();

  return (
    <div className="get-started-process-container">
      <div className="join-our-franchise-head-container">
        <h5>{langData(`How can you get started?`)}</h5>
        <p>{langData(`Simply adhere to these steps for a hassle-free experience.`)}</p>
      </div>
      <div className="get-started-process-card-container">
        <GetProcessCard
          key={1}
          bnfticn="./images/get-started-eyi-icn.svg"
          bnfttitle={langData(`Express Your Interest`)}
          bnftsubtitle={langData(
            `You can start by filling out the franchisee application form available on our website. Provide us with the necessary details to initiate the application process.`,
          )}
        />
        <div className="process-arrow-container">
          <img className="process-arrow" src="./images/process-arrow.svg" />
        </div>
        <GetProcessCard
          key={2}
          bnfticn="./images/get-started-ie-icn.svg"
          bnfttitle={langData('Initial Evaluation')}
          bnftsubtitle={langData(
            'Our team will review your application and get in touch with you for an initial discussion. This is an opportunity for us to get to know you better and for you to understand our franchisee model.',
          )}
        />
        <div className="process-arrow-container">
          <img className="process-arrow" src="./images/process-arrow.svg" />
        </div>
        <GetProcessCard
          key={3}
          bnfticn="./images/get-started-dtm-icn.svg"
          bnfttitle={langData('Documentation')}
          bnftsubtitle={langData("Once the initial evaluation is successful, we'll provide you with the necessary documentation and franchisee agreement for review.")}
        />
        <div className="process-arrow-container">
          <img className="process-arrow" src="./images/process-arrow.svg" />
        </div>
        <GetProcessCard
          key={4}
          bnfticn="./images/get-started-trg-icn.svg"
          bnfttitle={langData('Training')}
          bnftsubtitle={langData(
            "Once you're on board, we'll provide you with extensive training on our proven systems and methods, ensuring you have all the tools and knowledge needed to succeed.",
          )}
        />
        <div className="process-arrow-container">
          <img className="process-arrow" src="./images/process-arrow.svg" />
        </div>
        <GetProcessCard
          key={5}
          bnfticn="./images/get-started-lh-icn.svg"
          bnfttitle={langData('Launch')}
          bnftsubtitle={langData("You'll be ready to launch your Tractor Seva franchise and start making a real impact in your community.")}
        />
      </div>
    </div>
  );
}
function GetProcessCard({ bnfticn, bnfttitle, bnftsubtitle }) {
  return (
    <div className="get-started-process-card">
      <div className="get-started-icn">
        <img className="icn" src={bnfticn} />
      </div>
      <div className="get-started-title">{bnfttitle}</div>
      <p className="get-started-subtitle">{bnftsubtitle}</p>
    </div>
  );
}

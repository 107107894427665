// export default function IconLocation({
//   width = "1em",
//   height = "1em",
//   color = "var(--clr-primary)",
//   color2 = "var(--clr-secondary)",
//   ...props
// }) {
//   return (
//     <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 60 61" fill="none" {...props}>
//       <path d="M30.334 1.24023C20.2043 1.24023 11.7557 9.40003 11.7557 19.8185C11.7557 23.7821 12.9468 27.3165 15.2326 30.6283L28.9122 51.9742C29.5759 53.0119 31.0935 53.0098 31.7557 51.9742L45.4948 30.5557C47.7313 27.3939 48.9122 23.6813 48.9122 19.8185C48.9122 9.57444 40.578 1.24023 30.334 1.24023ZM30.334 28.2632C25.6778 28.2632 21.8893 24.4747 21.8893 19.8185C21.8893 15.1623 25.6778 11.3738 30.334 11.3738C34.9902 11.3738 38.7787 15.1623 38.7787 19.8185C38.7787 24.4747 34.9902 28.2632 30.334 28.2632Z" fill={color2} />
//       <path d="M43.5374 40.0527L35.0329 53.3488C32.8316 56.7809 27.8241 56.7697 25.6333 53.3521L17.115 40.0562C9.62024 41.7891 5 44.9635 5 48.7569C5 55.3395 18.053 58.8905 30.334 58.8905C42.615 58.8905 55.668 55.3395 55.668 48.7569C55.668 44.9608 51.0413 41.7847 43.5374 40.0527Z" fill={color} />
//     </svg>
//   )
// }
export default function IconLocationAvatar() {
  return (
    <svg width="40" height="50" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1226_1354)">
        <circle cx="24" cy="24" r="22.4679" fill="#D4DBE2" fillOpacity="0.53" stroke="#E1E8EE" strokeWidth="3.06423" />
        <path
          d="M23.9994 13.25C20.2214 13.25 17.0703 16.2933 17.0703 20.1791C17.0703 21.6574 17.5145 22.9756 18.3671 24.2108L23.4691 32.1721C23.7166 32.5591 24.2826 32.5584 24.5297 32.1721L29.6538 24.1837C30.488 23.0045 30.9285 21.6198 30.9285 20.1791C30.9285 16.3584 27.8201 13.25 23.9994 13.25ZM23.9994 23.3287C22.2628 23.3287 20.8498 21.9157 20.8498 20.1791C20.8498 18.4425 22.2628 17.0295 23.9994 17.0295C25.736 17.0295 27.149 18.4425 27.149 20.1791C27.149 21.9157 25.736 23.3287 23.9994 23.3287Z"
          fill="#EC2726"
        />
        <path
          d="M28.924 27.7246L25.7521 32.6836C24.931 33.9637 23.0634 33.9595 22.2463 32.6848L19.0693 27.7259C16.274 28.3722 14.5508 29.5562 14.5508 30.971C14.5508 33.4261 19.4191 34.7505 23.9995 34.7505C28.5799 34.7505 33.4483 33.4261 33.4483 30.971C33.4483 29.5551 31.7227 28.3706 28.924 27.7246Z"
          fill="#002852"
        />
      </g>
      <defs>
        <clipPath id="clip0_1226_1354">
          <rect width="48" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

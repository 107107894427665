import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import useAxios from '../../../config/axios/useAxios';
import { promptActions, promptContext } from '../../../context/prompt';
import { useAuthContext } from '../../../context/authContext';

export function useGetItems(customer_tractor_id, params) {
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  const navigate = useNavigate();
  return useQuery({
    enabled: Boolean(customer_tractor_id),
    queryKey: ['items_tab_cards', customer_tractor_id, params],
    queryFn: async () => {
      try {
        const { data } = await protectedAxios.get(`/getdata/v3/tractor_item/${customer_tractor_id}/`, {
          params,
        });
        return data;
      } catch (error) {
        prompt({
          type: promptActions.SHOW_PROMPT,
          payload: { message: error.response.data?.message || 'Error while getting data' },
        });
        if (error.response.status === 401) navigate('/session-timeout');
        return;
      }
    },
  });
}

export function useGetKits(customer_tractor_id, params) {
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  const navigate = useNavigate();
  const { getData } = useAuthContext();
  const { lang } = getData();
  return useQuery({
    enabled: Boolean(customer_tractor_id),
    queryKey: ['tab-service-kit', customer_tractor_id, params, lang],
    queryFn: async () => {
      try {
        const { data } = await protectedAxios.get(`/getdata/v3/tractor_service_kits/${customer_tractor_id}/`, {
          params,
        });
        return data;
      } catch (error) {
        prompt({
          type: promptActions.SHOW_PROMPT,
          payload: { message: error.response.data?.message || 'Error while getting data' },
        });
        if (error.response.status === 401) navigate('/session-timeout');
        return;
      }
    },
  });
}

import './faqs.css';
import * as React from 'react';
import { styled } from '@mui/material/styles';
// import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
// import IconArrowDown from "../../../assets/icons/icon-common/icon-arrow-down";
import IconArrowDown from '../../../../../assets/icons/icon-common/icon-arrow-down';

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => <MuiAccordionSummary expandIcon={<IconArrowDown style={{ transform: 'rotate(270deg)' }} />} {...props} />)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function Faqs({ data }) {
  //   const [expanded, setExpanded] =
  //     (React.useState < string) | (false > "panel1");
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div className="faqs-container">
      <div className="faqs-heading-container">
        <h5>{data?.heading}</h5>
        <h3>{data?.sub_heading}</h3>
      </div>
      <div className="accordions-container">
        {data?.content.map((elem, num) => (
          <Accordion key={num + 1} expanded={expanded === 'panel' + (num + 1)} onChange={handleChange('panel' + (num + 1))}>
            <AccordionSummary className="test-accr" aria-controls={'panel' + (num + 1) + 'd-content'} id={'panel' + (num + 1) + 'd-header'}>
              <Typography>{elem.title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{elem.description}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </div>
  );
}

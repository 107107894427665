import { Avatar, Skeleton, Tab, Tabs } from '@mui/material';
import { useAuthContext } from '../../context/authContext';
import { useGetBookings } from './booking-api';
import { useState } from 'react';
import IconDashCustomer from '../../assets/icons/icon-dashboard/icon-dash-customer';
import IconDashWorkshop from '../../assets/icons/icon-dashboard/icon-dash-workshop';
import { dateFormat } from '../../config/common-function/date-time-format';
import { useNavigate } from 'react-router';
import { LazyImage } from '../../assets/image/lazyImage';
import { useLanguage } from '../../language/useLanguage';
import { useSearchParams } from 'react-router-dom';
import IconDashBooking from '../../assets/icons/icon-dashboard/icon-dash-booking';

export default function MyBooking() {
  const { getData } = useAuthContext();
  const { userData } = getData();
  const langData = useLanguage();
  const bookings = useGetBookings(userData.user_id);
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState(searchParams.get('list') ?? 'pending');
  const handleTabChange = (event) => {
    if (event.target?.id) {
      setActiveTab(event.target.id);
      setSearchParams([['list', event.target.id]], { replace: true });
    }
  };

  if (bookings.isError) return <p>Error</p>;
  if (bookings.isLoading) return <BookingLoading activeTab={activeTab} />;
  const [pendingBookings, upcomingBookings, ongoingBookings, completedBookings, cancelledBookings] = getBookingData(bookings.data.rows);

  return (
    <div className="booking-container">
      <h1>{langData('My Bookings')}</h1>
      <div className="booking-tabs">
        <Tabs value={activeTab} onClick={handleTabChange} variant="scrollable">
          <Tab label={langData('Pending')} id="pending" value="pending" />
          <Tab label={langData('Upcoming')} id="upcoming" value="upcoming" />
          <Tab label={langData('Ongoing')} id="ongoing" value="ongoing" />
          <Tab label={langData('Completed')} id="completed" value="completed" />
          <Tab label={langData('Cancelled')} id="cancelled" value="cancelled" />
        </Tabs>
        <CustomTabPanel value={activeTab} index="pending" className="booking-panel">
          <BookingList bookingData={pendingBookings} />
        </CustomTabPanel>
        <CustomTabPanel value={activeTab} index="upcoming" className="booking-panel">
          <BookingList bookingData={upcomingBookings} />
        </CustomTabPanel>
        <CustomTabPanel value={activeTab} index="ongoing" className="booking-panel">
          <BookingList bookingData={ongoingBookings} />
        </CustomTabPanel>
        <CustomTabPanel value={activeTab} index="completed" className="booking-panel">
          <BookingList bookingData={completedBookings} />
        </CustomTabPanel>
        <CustomTabPanel value={activeTab} index="cancelled" className="booking-panel">
          <BookingList bookingData={cancelledBookings} />
        </CustomTabPanel>
      </div>
    </div>
  );
}

function getBookingData(data = []) {
  const pendingBookings = [];
  const upcomingBookings = [];
  const ongoingBookings = [];
  const completedBookings = [];
  const cancelledBookings = [];
  data.forEach((booking) => {
    switch (booking.CustomerOrderItem.status) {
      case 'completed':
        completedBookings.push(booking);
        break;
      case 'cancelled':
        cancelledBookings.push(booking);
        break;
      case 'ongoing':
        ongoingBookings.push(booking);
        break;
      case 'upcoming':
      case 're-schedule':
        upcomingBookings.push(booking);
        break;
      case 'pending':
      case 'payment-failed':
      case 'payment-pending':
      default:
        pendingBookings.push(booking);
        break;
    }
  });
  return [pendingBookings, upcomingBookings, ongoingBookings, completedBookings, cancelledBookings];
}
function BookingList({ bookingData = [] }) {
  if (bookingData.length === 0) return <NoBooking />;
  return (
    <div className="booking-card-container">
      {bookingData.map((booking) => (
        <BookingCard key={booking.id} booking={booking} />
      ))}
    </div>
  );
}

function BookingCard({ booking }) {
  const navigate = useNavigate();
  const langData = useLanguage();
  return (
    <div className="booking-card" onClick={() => navigate(`/booking/view/${booking.id}`)}>
      <div className="heading-container">
        <h3>
          {booking.Service.Item.ServiceType.service_type_name}-{booking.CustomerOrderItem.CustomerTractor.TractorModel.TractorBrand.brand_name}{' '}
          {booking.CustomerOrderItem.CustomerTractor.TractorModel.model_name}
        </h3>
        <p>₹ {booking.CustomerOrderItem.total_amount}</p>
        {/* <div>Download Invoice #124450 <span><IconDownload /></span></div> */}
      </div>
      <div className="details-container">
        <div style={{ flexBasis: 320, flexGrow: 1 }}>
          <div className="icon-container">
            <Avatar className="icon-container">
              <LazyImage name={booking.CustomerOrderItem.CustomerTractor.TractorModel.image} width={38.96} height={38.96} fallbackImage="tractor-model-image.svg" />
            </Avatar>
          </div>
          <div className="text-details">
            <p className="title">{langData(booking.Service.Item.ServiceType.service_type_name)}</p>
            <p className="details">
              {booking.CustomerOrderItem.CustomerTractor.TractorModel.TractorBrand.brand_name} {booking.CustomerOrderItem.CustomerTractor.TractorModel.model_name}
            </p>
          </div>
        </div>
        <div style={{ flexBasis: 250, flexGrow: 1 }}>
          <div className="icon-container">
            <IconDashBooking />
          </div>
          <div className="text-details">
            <p className="title">{langData('Order Date')}</p>
            <p className="details">{`${dateFormat(booking.created_at)}`}</p>
          </div>
        </div>
        <div style={{ flexBasis: 400, flexGrow: 1 }}>
          <div className="icon-container">
            <IconDashWorkshop />
          </div>
          <div className="text-details">
            <p className="title">{langData('Workshop')}</p>
            <p className="details">{booking.CustomerOrderItem.CustomerOrder.Workshop.workshop_name}</p>
          </div>
        </div>
        <div style={{ flexBasis: 250, flexGrow: 1 }}>
          <div className="icon-container">
            <IconDashWorkshop />
          </div>
          <div className="text-details">
            <p className="title">{langData('Service Preference')}</p>
            <p className="details" style={{ textTransform: 'capitalize' }}>
              {langData(booking.service_location)} {langData('Service')}
            </p>
          </div>
        </div>
        {booking.done_by && (
          <div style={{ flexBasis: 250, flexGrow: 1 }}>
            <div className="icon-container">
              <IconDashCustomer />
            </div>
            <div className="text-details">
              <p className="title">{langData('Assigned Member')}</p>
              <p className="details">{`${booking.WorkshopTeam.first_name} ${booking.WorkshopTeam.last_name}`}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other} style={{ minHeight: '38vh' }}>
      {value === index && <>{children}</>}
    </div>
  );
}

function NoBooking() {
  const langData = useLanguage();
  return (
    <div style={{ textAlign: 'center', width: '100%', padding: '4rem 0rem 2rem' }}>
      <img style={{ width: '100%', maxWidth: '400px' }} src="/icons/no-booking.svg" alt="No-Workshop" />
      <p style={{ fontSize: 20, margin: '16px 0px' }}>{langData('No Bookings Available')}</p>
    </div>
  );
}

function BookingLoading({ activeTab }) {
  const langData = useLanguage();
  return (
    <div className="booking-container">
      <h1>{langData('My Bookings')}</h1>
      <div className="booking-tabs">
        <Tabs value={activeTab}>
          <Tab label={langData('Pending')} id="pending" value="pending" />
          <Tab label={langData('Upcoming')} id="upcoming" value="upcoming" />
          <Tab label={langData('Ongoing')} id="ongoing" value="ongoing" />
          <Tab label={langData('Completed')} id="completed" value="completed" />
          <Tab label={langData('Cancelled')} id="cancelled" value="cancelled" />
        </Tabs>
        <CustomTabPanel value={activeTab} index="pending" className="booking-panel">
          <BookingListLoading count={3} />
        </CustomTabPanel>
        <CustomTabPanel value={activeTab} index="upcoming" className="booking-panel">
          <BookingListLoading count={3} />
        </CustomTabPanel>
        <CustomTabPanel value={activeTab} index="ongoing" className="booking-panel">
          <BookingListLoading count={3} />
        </CustomTabPanel>
        <CustomTabPanel value={activeTab} index="completed" className="booking-panel">
          <BookingListLoading count={3} />
        </CustomTabPanel>
        <CustomTabPanel value={activeTab} index="cancelled" className="booking-panel">
          <BookingListLoading count={3} />
        </CustomTabPanel>
      </div>
    </div>
  );
}

function BookingListLoading({ count = 3 }) {
  const countArr = [];
  let i = 1;
  while (i <= count) {
    countArr.push(i++);
  }
  return (
    <div className="booking-card-container">
      {countArr.map((x) => (
        <div key={x} className="booking-card">
          <div className="heading-container">
            <h3>
              <Skeleton variant="text" width={80} />
            </h3>
            <p>
              <Skeleton variant="text" width={100} />
            </p>
            {/* <div>Download Invoice #124450 <span><IconDownload /></span></div> */}
          </div>
          <div className="details-container">
            <div style={{ flexBasis: 250, flexGrow: 1 }}>
              <div className="icon-container">
                <Skeleton variant="circular" width={40} height={40} />
              </div>
              <div className="text-details">
                <p className="title">{<Skeleton variant="text" width={80} />}</p>
                <p className="details">{<Skeleton variant="text" width={100} />}</p>
              </div>
            </div>
            <div style={{ flexBasis: 400, flexGrow: 1 }}>
              <div className="icon-container">
                <Skeleton variant="circular" width={40} height={40} />
              </div>
              <div className="text-details">
                <p className="title">{<Skeleton variant="text" width={80} />}</p>
                <p className="details">{<Skeleton variant="text" width={100} />}</p>
              </div>
            </div>
            <div style={{ flexBasis: 250, flexGrow: 1 }}>
              <div className="icon-container">
                <Skeleton variant="circular" width={40} height={40} />
              </div>
              <div className="text-details">
                <p className="title">{<Skeleton variant="text" width={80} />}</p>
                <p className="details" style={{ textTransform: 'capitalize' }}>
                  {<Skeleton variant="text" width={100} />}
                </p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

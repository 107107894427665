export default function IconDashCustomer({ width = '1em', height = '1em', ...props }) {
  return (
    <svg width={width} height={height} viewBox="0 0 129 129" fill="none" xmlns="http://www.w3.org/2000/svg" className="hoverable" {...props}>
      <g clipPath="url(#clip0_860_1360)">
        <circle cx="64.3489" cy="64.3489" r="62.8168" fill="var(--bg)" fillOpacity="0.53" stroke="var(--border)" strokeWidth="3.06423" />
        <path
          d="M64.5783 60.5139C57.8089 60.5139 52.3213 55.0263 52.3213 48.257C52.3213 41.4876 57.8089 36 64.5783 36C71.3476 36 76.8352 41.4876 76.8352 48.257C76.8352 55.0263 71.3476 60.5139 64.5783 60.5139Z"
          fill="var(--color)"
        />
        <path
          d="M37.002 88.7041C38.1897 74.0795 50.0595 66.644 64.5271 66.644C79.1983 66.644 91.252 73.6709 92.1499 88.7066C92.1857 89.3056 92.1499 91.158 89.848 91.158C78.4933 91.158 61.6203 91.158 39.2292 91.158C38.4608 91.158 36.9373 89.5008 37.002 88.7041Z"
          fill="var(--color2)"
        />
      </g>
      <defs>
        <clipPath id="clip0_860_1360">
          <rect width="129" height="129" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

import { useNavigate, useParams } from 'react-router-dom';
import { useCancelOrder, useGetCustomerOrderViews } from '../booking/booking-api';
import { GetBookingTags, GetOrderTags } from '../common/tags';
import { Button, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import dayjs from 'dayjs';
import { timeFormat } from '../../config/common-function/date-time-format';
import { formatAmount } from '../../config/common-function/calculation';
import { useLanguage } from '../../language/useLanguage';
import IconDownload from '../../assets/icons/icon-common/icon-download';
import { useGetPdfExport } from '../../config/common-function/export';
import IconArrowLeft from '../../assets/icons/icon-common/icon-arraow-left';
import { LazyImage } from '../../assets/image/lazyImage';
import { Fragment } from 'react';
import { IconCalendar } from '../../assets/icons/icon-common/icon-cart-calendar-new';
import { IconWatch } from '../../assets/icons/icon-common/icon-watch-new';

export default function OrderView() {
  const { id } = useParams();
  const orderViewData = useGetCustomerOrderViews(id);
  const cancelOrder = useCancelOrder(id);
  const isActionLoading = cancelOrder.isLoading;
  const navigate = useNavigate();
  const langData = useLanguage();
  const exportpdf = useGetPdfExport(`booking/invoice/download/${id}`, `invoice-TSCOI${id}`);

  let payLink = false;

  if (orderViewData.data?.payment_link) {
    const linkData = orderViewData.data?.payment_link.split('https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction');
    payLink = `https://www.tractorseva.com/paylink/${linkData[1]}`;
  }

  const handleCancelBooking = () => {
    cancelOrder.mutate();
  };

  if (orderViewData.isError) return <p>Error</p>;
  if (orderViewData.isLoading) return <p>Loading....</p>;

  return (
    <OrderViewContainer>
      <HeadingWrapper>
        <span onClick={() => navigate(-1)}>
          <IconArrowLeft style={{ display: 'block', cursor: 'pointer' }} />
        </span>
        <h1 style={{ marginTop: '0px' }}>
          {langData('Order')} TSCO{orderViewData.data.id}{' '}
        </h1>
        <GetBookingTags status={orderViewData.data.status} />
        {['payment-pending', 'payment-failed'].includes(orderViewData.data?.status) && payLink && (
          <Button variant="contained" onClick={() => window.open(payLink, '_self')}>
            {langData('Pay Now')}
          </Button>
        )}
        {orderViewData.data?.Payment && orderViewData.data?.Payment?.status === 'Success' && (
          <div className="download-details" style={{ marginLeft: 'auto' }}>
            <div onClick={() => exportpdf()} style={{ cursor: 'pointer' }}>
              {langData('Download Invoice')}{' '}
              <span>
                <IconDownload />
              </span>
            </div>
          </div>
        )}
        {['pending'].includes(orderViewData.data.status) && (
          <Button variant="outlined" color="secondary" onClick={handleCancelBooking} disabled={isActionLoading}>
            Cancel Order
          </Button>
        )}
      </HeadingWrapper>
      <OrderViewWrapper>
        <CustomerDetailsContainer>
          <h2>{langData('Order Details')}</h2>
          <CustomerDetails>
            <div>
              <h4>{langData('Customer Address')}</h4>
              <p>
                {orderViewData.data?.CustomerAddress?.address_name} : {orderViewData.data?.CustomerAddress?.address_1}, {orderViewData.data?.CustomerAddress?.address_2},{' '}
                {orderViewData.data?.CustomerAddress?.SubDistrict.subdistrict_name}, {orderViewData.data?.CustomerAddress?.SubDistrict.District.district_name},{' '}
                {orderViewData.data?.CustomerAddress?.SubDistrict.District.State.state_name} - {orderViewData.data?.CustomerAddress?.pincode}
              </p>
              <div style={{ fontSize: '14px', fontWeight: 600, display: 'flex', flexDirection: 'row', gap: 4, paddingTop: '8px' }}>
                <p>Order Date :</p>
                <p> {dayjs(orderViewData.data?.created_at).format('MMM D, YYYY')}</p>
              </div>
            </div>

            <div>
              <h4>{langData('Workshop Address')}</h4>
              <p>{orderViewData.data.Workshop.workshop_name}</p>
              <p>
                {orderViewData.data?.Workshop?.address_1},{orderViewData.data?.Workshop?.address_2},{orderViewData.data?.Workshop?.SubDistrict.subdistrict_name}
                {orderViewData.data?.Workshop?.SubDistrict.District.district_name},{orderViewData.data?.Workshop?.SubDistrict.District.State.state_name}-{' '}
                {orderViewData.data?.Workshop?.pincode}
              </p>
            </div>
          </CustomerDetails>
        </CustomerDetailsContainer>
      </OrderViewWrapper>
      <OrderViewWrapper>
        <SummaryDetailsContainer>
          <OrderSummary>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <h2>{langData('Order Summary')}</h2>
            </div>

            <TableContainer sx={{ maxWidth: window.innerWidth >= 600 ? '100%' : 440 }}>
              {/* <hr style={{ border: '0.2px solid #EEF1F6' }} /> */}
              <Table>
                <TableHead
                  style={{
                    borderTop: '1px solid rgba(224, 224, 224, 1)',
                    padding: '0px',
                  }}
                >
                  <TableCell sx={{ fontWeight: 600, padding: '8px 8px 8px 0px' }}>{langData('Items')}</TableCell>
                  <TableCell sx={{ fontWeight: 600, padding: '8px 8px 8px 0px' }}>{langData('Quantity')}</TableCell>
                  <TableCell sx={{ fontWeight: 600, padding: '8px 8px 8px 0px' }}>{langData('Price')}</TableCell>
                  <TableCell sx={{ fontWeight: 600, padding: '8px 8px 8px 0px' }}>{langData('Status')}</TableCell>
                </TableHead>
                <TableBody>
                  {orderViewData.data?.CustomerOrderItems.map((a, index) =>
                    a.booking_id ? <Service row={a} key={index} /> : <ItemCell key={index} row={a} customer_id={a?.customer_id} />,
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </OrderSummary>
          <PricingContainer>
            <h2>{langData('Pricing')}</h2>
            <BillingSummary>
              <hr style={{ border: '1px solid #EEF1F6', width: '100%', margin: 0 }} />
              <div>
                <p>{langData('Total')}</p>
                <p>₹ {formatAmount(orderViewData.data.subTotal)}</p>
              </div>
              <div>
                <p>{langData('Discount')}</p>
                <p style={{ color: '#108D0D' }}>₹ {formatAmount(orderViewData.data.discount)}</p>
              </div>
              <div>
                <p>
                  {langData('Tax')} <strong style={{ fontWeight: 500, color: '#EC2726' }}>(18%)</strong>
                </p>
                <p>₹ {formatAmount(orderViewData.data.tax)}</p>
              </div>
              <hr style={{ border: '1px solid #EEF1F6', width: '100%', margin: 0 }} />
              <div>
                <h4>{langData('Total Amount')}</h4>
                <h4>₹ {formatAmount(orderViewData.data.total)}</h4>
              </div>
              <div style={{ backgroundColor: orderViewData.data.Payment.status === 'Success' ? '#0E9002' : '#ec2726', padding: 4, borderRadius: 4, flexWrap: 'wrap', gap: 6 }}>
                <p style={{ color: '#ffffff', fontSize: 14, fontWeight: 500 }}>Payment Status : {orderViewData.data.Payment.status}</p>
                {orderViewData.data.Payment.status === 'Success' && orderViewData.data.Payment.transaction_id && (
                  <p style={{ color: '#ffffff', fontSize: 14, fontWeight: 500 }}>Transaction Id : {orderViewData.data.Payment.transaction_id}</p>
                )}
              </div>
            </BillingSummary>
          </PricingContainer>
        </SummaryDetailsContainer>
      </OrderViewWrapper>
    </OrderViewContainer>
  );
}

const BillingSummary = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  marginTop: 'auto',
  gap: '1rem',
  div: {
    display: 'flex',
    justifyContent: 'space-between',
    p: {
      color: 'var(--clr-primary)',
      fontWeight: 500,
    },
  },
});

const ItemCell = ({ row }) => {
  const kit_items = row.Item?.KitItems?.map((o) => `${o.SubItems.ItemCategory.category_name} ${o.SubItems.item_name} `).join();
  const item_image = row.Item?.ItemImages?.map((o) => `${o.item_img}`).join();
  return (
    <Fragment>
      <TableRow>
        <CustomeTableCellItem>
          <LazyImage name={item_image} height="2.5rem" width="2.5rem" />
          <div>
            <p style={{ fontSize: 14, fontWeight: 600 }}>{row.item_name}</p>
            {kit_items && <p style={{ fontSize: 14, fontWeight: 600 }}>{kit_items}</p>}
          </div>
        </CustomeTableCellItem>
        <CustomeTableCellItemPrice>{row.quantity}</CustomeTableCellItemPrice>
        <CustomeTableCellItemPrice>
          <div>
            <p style={{ fontSize: '1rem', fontWeight: 600 }}>₹ {row.Item.offer_total}</p>
            <p style={{ fontSize: '0.9rem', fontWeight: 500 }}>
              <del style={{ color: 'var(--clr-gray)' }}>₹ {row.Item.mrp_total}</del>
            </p>
          </div>
        </CustomeTableCellItemPrice>
        <CustomeTableCellItemPrice>
          <GetOrderTags status={row.status} />
        </CustomeTableCellItemPrice>
      </TableRow>
    </Fragment>
  );
};

const CustomeTableCellItemPrice = styled(TableCell)({
  verticalAlign: 'top',
  color: 'var(--clr-primary)',
  fontWeight: 500,
  fontSize: 16,
  paddingLeft: 0,
  paddingBottom: 0,
});

const CustomeTableCellItem = styled(TableCell)({
  display: 'flex',
  gap: 12,
  paddingLeft: 0,
  alignItems: 'center',
  div: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  },
});

const Service = ({ row }) => {
  const issues = row.Booking.issues ? JSON.parse(row.Booking.issues) : {};
  console.debug(issues, 'issues');
  const navigate = useNavigate();

  return (
    <Fragment>
      <TableRow className="service-cell" onClick={() => navigate(`/booking/view/${row.Booking.id}`)} style={{ cursor: 'pointer' }}>
        <CustomeTableCellService rowSpan={7}>
          <LazyImage name={row.service_type_img} height="2rem" width="2rem" />
          <div>
            <div>
              <p>{row.item_name}</p>
            </div>
            <p>{row.tractor_name}</p>
            <p style={{ color: '#6D7D93' }}>Chassis No: {row.CustomerTractor?.reg_no}</p>
          </div>
        </CustomeTableCellService>
        <CustomeTableCellPrice>{row.quantity}</CustomeTableCellPrice>
        {/* <CustomeTableCellPrice>₹ {row.Booking.Service.offer_total}</CustomeTableCellPrice> */}
        <CustomeTableCellPrice>
          <div>
            <p style={{ fontSize: '1rem', fontWeight: 600 }}>₹ {row.Booking.Service.offer_total}</p>
            <p style={{ fontSize: '0.9rem', fontWeight: 500 }}>
              <del style={{ color: 'var(--clr-gray)' }}>₹ {row.Booking.Service.mrp_total}</del>
            </p>
          </div>
        </CustomeTableCellPrice>
        <CustomeTableCellPrice>
          <GetBookingTags status={row.status} />
        </CustomeTableCellPrice>
      </TableRow>
      <TableRow className="service-meta">
        <TableCell colSpan={5} style={{ padding: 0, paddingBottom: 8 }}>
          <div
            style={{
              fontSize: 14,
              color: '#6D7D93',
              marginTop: '-0.5rem',
            }}
          >
            <div
              style={{
                display: 'flex',
                gap: 16,
                marginBottom: 4,
                alignItems: 'center',
                // border: pastService ? '2px solid #ec2726c7' : 'none',
                borderRadius: '3px',
                paddingLeft: '7px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  gap: 6,
                  alignItems: 'center',
                }}
              >
                <IconCalendar
                  style={{
                    height: '0.8rem',
                    width: '0.8rem',
                  }}
                />
                <p
                  style={{
                    fontSize: 12,
                    color: 'var(--clr-primary)',
                    fontWeight: 600,
                  }}
                >
                  {dayjs(row.Booking.booking_date).format('MMM D, YYYY')}
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                  gap: 6,
                  alignItems: 'center',
                }}
              >
                <IconWatch
                  style={{
                    height: '0.8rem',
                    width: '0.8rem',
                  }}
                />
                <p
                  style={{
                    fontSize: 12,
                    color: 'var(--clr-primary)',
                    fontWeight: 600,
                  }}
                >
                  {`${timeFormat(row.Booking.start_time)} - ${timeFormat(row.Booking.end_time)}`}{' '}
                </p>
              </div>
            </div>
            {(Object.keys(issues).length > 0 || row.Booking?.notes) && (
              <IssuesAndNotes>
                {Object.keys(issues).length > 0 && (
                  <p>
                    Issue:
                    {Object.keys(issues)
                      .filter((key) => key !== 'other')
                      .map((key) => key.charAt(0).toUpperCase() + key.slice(1))
                      .join(', ')}
                    {issues['other'] && Object.keys(issues).length > 1 ? ', ' : ''}
                    {issues['other']
                      ? `Other - ${issues['other']
                          .split(',')
                          .map((issue) => issue.trim().charAt(0).toUpperCase() + issue.trim().slice(1))
                          .join(', ')}`
                      : ''}
                  </p>
                )}
                {row.Booking?.notes && <p>Notes: {row.Booking?.notes}</p>}
              </IssuesAndNotes>
            )}
          </div>
        </TableCell>
      </TableRow>
    </Fragment>
  );
};

const OrderViewContainer = styled('div')({
  marginInline: 'calc(0px - var(--padding-inline))',
  paddingInline: 'var(--padding-inline)',
  marginBottom: 'calc(0px - 32px)',
  backgroundColor: 'aliceblue',
  display: 'flex',
  flexDirection: 'column',
  gap: 24,
});

const HeadingWrapper = styled('div')({
  display: 'flex',
  // flexDirection: 'row',
  // justifyContent: 'space-between',
  flexWrap: 'wrap',
  gap: 16,
  alignItems: 'center',
  paddingTop: 40,
});

const OrderViewWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  gap: 20,
  fontSize: 16,
  flexWrap: 'wrap',
  '@media only screen and (max-width: 600px) ': {
    order: 2,
  },
});

const CustomerDetailsContainer = styled('div')({
  backgroundColor: 'white',
  width: '55%',
  padding: 24,
  borderRadius: 10,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  gap: 24,
  flex: 1,
  h4: {
    marginBottom: 4,
  },
});

const CustomerDetails = styled('div')({
  display: 'flex',
  flexDirection: 'row',

  justifyContent: 'space-between',
  flexWrap: 'wrap',
  gap: 8,
});

const SummaryDetailsContainer = styled('div')({
  display: 'flex',
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'space-between',
  paddingBottom: 40,
  gap: 24,
  fontSize: 16,
  flexWrap: 'wrap',
  '@media only screen and (max-width: 600px) ': {
    order: 2,
  },
});

const OrderSummary = styled('div')({
  backgroundColor: 'white',
  padding: 24,
  borderRadius: 10,
  flex: 1,
  flexGrow: 2,
  flexBasis: '500px',
  display: 'flex',
  flexDirection: 'column',
  gap: 12,
  h4: {
    marginBottom: 4,
  },
});

const PricingContainer = styled('div')({
  backgroundColor: 'white',
  height: 'fit-content',
  padding: 24,
  borderRadius: 10,
  flex: 1,
  display: 'flex',
  flexBasis: '300px',
  flexDirection: 'column',
  gap: 12,
  h4: {
    marginBottom: 4,
  },
});

const CustomeTableCellService = styled(TableCell)({
  display: 'flex',
  gap: 12,
  position: 'relative',
  paddingLeft: 0,
  borderBottom: 0,
  div: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    p: {
      fontSize: 14,
    },
    div: {
      p: {
        fontSize: 16,
        fontWeight: 600,
      },
    },
  },
});

const IssuesAndNotes = styled('div')({
  display: 'flex',
  marginBottom: 6,
  background: '#F2F7FF',
  padding: 6,
  flexDirection: 'column',
  borderRadius: 8,
  p: {
    fontSize: '12px !important',
    color: '#6D7D93',
  },
  ' @media only screen and (max-width: 600px)': {
    p: {
      fontSize: 12,
      color: '#6D7D93',
    },
  },
});

const CustomeTableCellPrice = styled(TableCell)({
  verticalAlign: 'top',
  color: 'var(--clr-primary)',
  fontWeight: 600,
  fontSize: 16,
  borderBottom: 0,
  paddingLeft: 0,
});

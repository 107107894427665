export default function IconEmptyCart({ width = '10em', height = '12em', color = '#fff', ...props }) {
  return (
    <svg width={width} height={height} viewBox="0 0 180 180" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="90" cy="90" r="90" fill="#002852" fillOpacity="0.05" />
      <circle cx="90" cy="90" r="65" fill="#002852" fillOpacity="0.05" />
      <path
        d="M59.1822 54.6525C55.2515 54.7636 54.6267 60.6925 58.432 61.7262L63.3947 63.4782L69.2329 93.9182C69.9315 97.5609 71.0889 100.858 72.704 103.694C75.0791 107.871 79.2 110.954 84.2924 110.954H109.561C114.121 111.036 114.121 103.74 109.561 103.829H84.2933C80.696 103.365 79.2044 101.553 77.8853 98.896H111.237C114.297 98.896 116.005 96.1796 116.76 93.0373L124.056 68.2196C124.445 64.1396 122.284 63.3342 118.5 63.3342L70.5849 63.3707L69.2978 59.4347C68.9502 58.2631 68.0613 57.3582 66.9458 57.0436L59.1822 54.6525Z"
        fill="#002852"
      />
      <path
        d="M86.016 114.588C84.5808 114.588 83.2043 115.159 82.1894 116.173C81.1746 117.188 80.6044 118.565 80.6044 120C80.6044 121.435 81.1746 122.812 82.1894 123.827C83.2043 124.841 84.5808 125.412 86.016 125.412C87.4497 125.411 88.8242 124.84 89.8374 123.826C90.8506 122.812 91.4194 121.436 91.4187 120.003C91.4187 117.012 89.0018 114.588 86.016 114.588ZM107.562 114.588C104.58 114.588 102.159 117.012 102.159 120.003C102.159 121.436 102.728 122.811 103.741 123.825C104.754 124.839 106.128 125.41 107.562 125.412C108.996 125.411 110.371 124.841 111.385 123.826C112.399 122.812 112.969 121.437 112.969 120.003C112.969 119.292 112.83 118.588 112.558 117.932C112.287 117.275 111.889 116.678 111.387 116.176C110.885 115.673 110.288 115.274 109.632 115.002C108.976 114.729 108.272 114.589 107.562 114.588Z"
        fill="#EC2726"
      />
      <path
        d="M94.5849 81.728C90.6907 81.728 86.8578 83.4285 83.5849 86.5618C82.2604 87.7947 83.1031 90.0107 84.9084 90.0569C85.1769 90.0636 85.4441 90.0162 85.6939 89.9176C85.9437 89.8189 86.1711 89.671 86.3626 89.4827C89.0382 86.92 91.8435 85.7645 94.5849 85.7645C97.3298 85.7645 100.14 86.9191 102.816 89.4827C103.005 89.6793 103.231 89.8358 103.482 89.9426C103.732 90.0495 104.002 90.1046 104.274 90.1046C104.547 90.1046 104.816 90.0495 105.067 89.9426C105.317 89.8358 105.544 89.6793 105.732 89.4827C105.92 89.2846 106.066 89.0506 106.161 88.7948C106.257 88.539 106.299 88.2666 106.287 87.9939C106.275 87.7212 106.207 87.4538 106.089 87.2077C105.971 86.9616 105.804 86.742 105.599 86.5618C102.321 83.4285 98.4827 81.728 94.5849 81.728ZM102.982 73.7778C102.599 73.778 102.22 73.8537 101.866 74.0006C101.512 74.1475 101.19 74.3626 100.919 74.6338C100.648 74.9049 100.434 75.2268 100.287 75.5809C100.141 75.935 100.066 76.3146 100.066 76.6978C100.066 78.3111 101.372 79.6178 102.982 79.6178C104.593 79.6178 105.904 78.3111 105.904 76.6978C105.904 75.9232 105.595 75.1805 105.048 74.6329C104.5 74.0854 103.757 73.7778 102.982 73.7778ZM86.1964 73.7778C85.813 73.7775 85.4333 73.8527 85.079 73.9992C84.7247 74.1456 84.4026 74.3604 84.1313 74.6313C83.86 74.9022 83.6447 75.2239 83.4977 75.578C83.3507 75.9321 83.2749 76.3117 83.2746 76.6951C83.2744 77.0785 83.3496 77.4582 83.496 77.8126C83.6425 78.1669 83.8573 78.4889 84.1282 78.7602C84.3991 79.0315 84.7208 79.2468 85.0749 79.3938C85.429 79.5408 85.8086 79.6166 86.192 79.6169C86.9663 79.6175 87.7091 79.3105 88.2571 78.7634C88.805 78.2162 89.1132 77.4739 89.1138 76.6996C89.1143 75.9253 88.8073 75.1824 88.2602 74.6345C87.7131 74.0865 86.9707 73.7784 86.1964 73.7778Z"
        fill="white"
      />
    </svg>
  );
}

import { styled } from '@mui/material';
import React from 'react';
import IconEmptyCart from '../../assets/icons/icon-common/icon-empty-cart';

const NoCartFound = () => {
  return (
    <div>
      <EmptyCart>
        <IconEmptyCart />
        <p>Your Cart Is Currently Empty!</p>
        <p>Looks like you have not added anything in the cart.</p>
      </EmptyCart>
    </div>
  );
};

export default NoCartFound;
const EmptyCart = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 12,
  alignItems: 'center',
  justifyContent: 'center',
  height: 'calc(100vh - 150px)',
  overflow: 'hidden',
});

export default function IconMultipleServicingOptions({ width = '1em', height = '1em', color = 'var(--clr-primary)', color2 = 'var(--clr-secondary)', ...props }) {
  return (
    <svg className="hoverable" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 74 74" fill="none" {...props}>
      <path
        d="M53.4767 29.0638C54.7046 29.4379 55.9612 29.621 57.2092 29.621C60.5346 29.6208 63.7942 28.3195 66.2518 25.8618C70.0823 22.0312 71.0878 16.1733 68.7537 11.2851L68.6643 11.098L62.9266 16.8356C61.2714 18.4909 58.5878 18.4909 56.9325 16.8356C55.2773 15.1804 55.2773 12.4968 56.9325 10.8416L62.5883 5.18579L62.4975 5.14453C57.5644 2.90203 51.7234 4.01985 47.9634 7.92633C46.2907 9.66419 45.1504 11.8371 44.6657 14.2103C44.2763 16.1161 44.3218 18.0776 44.7918 19.9496L40.1133 24.6547L48.9888 33.5763L53.4767 29.0638Z"
        fill={color}
      />
      <path
        d="M21.5232 43.9365C17.0237 42.5658 12.1284 43.7585 8.74814 47.1387C4.91758 50.9692 3.91237 56.8271 6.24653 61.7152L6.40903 62.0555L12.0736 56.391C13.7288 54.7358 16.4124 54.7358 18.0676 56.391C19.7229 58.0462 19.7229 60.7299 18.0676 62.3851L12.5683 67.8845C14.2451 68.6354 16.024 69.0004 17.7899 69.0003C21.205 69.0001 24.5692 67.6374 27.0366 65.0738C28.7094 63.336 29.8497 61.1631 30.3345 58.7899C30.7237 56.8841 30.6783 54.9225 30.2083 53.0506L34.8714 48.3608L25.9959 39.4392L21.5232 43.9365Z"
        fill={color}
      />
      <path
        d="M56.8359 42.6574C55.5607 42.6574 54.3089 42.8386 53.0995 43.1975L30.8202 20.8025C31.1578 19.6268 31.3284 18.4125 31.3284 17.1785C31.3284 9.9197 25.4231 4.01434 18.1643 4.01434C10.9055 4.01434 5 9.91982 5 17.1785C5 24.4372 10.9055 30.3426 18.1642 30.3426C19.4393 30.3426 20.691 30.1614 21.9006 29.8025L44.1798 52.1975C43.8421 53.3735 43.6716 54.5875 43.6716 55.8216C43.6716 63.0804 49.5769 68.9858 56.8357 68.9858C64.0945 68.9858 70 63.0803 70 55.8215C70 48.5627 64.0947 42.6574 56.8359 42.6574ZM18.1642 22.6207C15.1634 22.6207 12.7219 20.1794 12.7219 17.1785C12.7219 14.1777 15.1634 11.7363 18.1642 11.7363C21.1649 11.7363 23.6064 14.1777 23.6064 17.1785C23.6065 20.1794 21.1651 22.6207 18.1642 22.6207ZM56.8359 61.2637C53.8351 61.2637 51.3936 58.8224 51.3936 55.8215C51.3936 52.8207 53.8351 50.3793 56.8359 50.3793C59.8367 50.3793 62.2781 52.8207 62.2781 55.8215C62.2781 58.8223 59.8367 61.2637 56.8359 61.2637Z"
        fill={color2}
      />
    </svg>
  );
}

export default function LeftArrowBack({ width = '1em', height = '1em', color = 'currentColor', ...props }) {
  return (
    <svg width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_877_551)">
        <path d="M3.75 9L14.25 9" stroke={color} strokeWidth="1.92857" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M9 14.25L3.75 9L9 3.75" stroke={color} strokeWidth="1.92857" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_877_551">
          <rect width="18" height="18" fill="white" transform="matrix(-1 0 0 1 18 0)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default function IconClose({ width = '1em', height = '1em', ...props }) {
  return (
    <svg width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_887_1693)">
        <path d="M12.7499 5.25L5.24994 12.75" stroke="#6D7D93" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M5.25006 5.25L12.7501 12.75" stroke="#6D7D93" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_887_1693">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

import { TextField } from '@mui/material';
import { forwardRef } from 'react';
const regex = /^[0-9]*$/;

const InputNumber = forwardRef(function CustomNumber(props, ref) {
  function handleChange(event) {
    const value = event.target.value;
    if (value === '') props.onChange(event);
    if (regex.test(event.target.value)) {
      props.onChange(event);
    }
  }
  return <TextField ref={ref} {...props} onChange={handleChange} />;
});

export default InputNumber;

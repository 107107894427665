import { Outlet, useLocation } from 'react-router-dom';
import Header from './header';
import { useEffect } from 'react';
import ReactGA from 'react-ga';
import FooterV2, { SubFooterV2 } from './footerV2';
ReactGA.initialize('G-M10D9W5CJQ');

export default function Layout() {
  const location = useLocation();
  const hideFooter = ['/order-summary'];
  const hiddenFooterpages = hideFooter.includes(location.pathname);

  useEffect(() => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <main className="layout">
      <Header />
      <section className="overflow-hidden-sm">
        <Outlet />
      </section>
      {/* <Footer /> */}
      {!hiddenFooterpages ? <FooterV2 /> : <SubFooterV2 />}
    </main>
  );
}

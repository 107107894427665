export default function IconLinkdin({ width = '1em', height = '1em', color = '#fff', ...props }) {
  return (
    <svg width={width} height={height} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="35" height="35" rx="17.5" stroke={color} />
      <g clipPath="url(#clip0_1804_40)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.6004 27.6004H23.7604V20.8813C23.7604 19.0381 22.9473 18.0098 21.489 18.0098C19.9022 18.0098 18.9604 19.0813 18.9604 20.8813V27.6004H15.1204V15.1204H18.9604V16.5238C18.9604 16.5238 20.1652 14.4098 22.8801 14.4098C25.5959 14.4098 27.6004 16.0671 27.6004 19.4962V27.6004ZM10.7447 13.1245C9.44967 13.1245 8.40039 12.0666 8.40039 10.762C8.40039 9.45827 9.44967 8.40039 10.7447 8.40039C12.0388 8.40039 13.0881 9.45827 13.0881 10.762C13.089 12.0666 12.0388 13.1245 10.7447 13.1245ZM8.40039 27.6004H13.2004V15.1204H8.40039V27.6004Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1804_40">
          <rect width="19.2" height="19.2" fill={color} transform="translate(8.40039 8.40039)" />
        </clipPath>
      </defs>
    </svg>
  );
}

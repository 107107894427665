export function getOptionLabel(option, DDList) {
  switch (typeof option) {
    case 'number':
      return DDList?.data?.find((item) => item.id == option)?.label ?? '';
    case 'object':
      return option.label;
    default:
      return option;
  }
}

import { HashLink } from 'react-router-hash-link';
import { useGetProfile } from '../profile/profile-api';
import { useAuthContext } from '../../context/authContext';
import { useLanguage } from '../../language/useLanguage';
import HeaderWebV2 from './header/header-web-V2';
import HeaderResponsiveV2 from './header/header-responsive-V2';
import { Drawer } from '@mui/material';
import { useGetLocation } from '../homepage/homepage-api';
import { useDispatch, useSelector } from 'react-redux';
import { setLocationID, setStateID } from '../../redux/features/location/locationSlice';
import Cart from './cart/cart';
import { useGetCart } from './header/header-api';
import { setCartId, setCartItems, setCartState } from '../../redux/features/cart/cartSlice';

export const HEADER_MENU = [
  {
    title: 'Home',
    path: '/',
    subItems: null,
    target: '',
  },
  {
    title: 'Book Now',
    path: '/#select-service',
    subItems: null,
    target: '',
  },
  {
    title: 'About Us',
    path: '/about-us',
    subItems: null,
    target: '',
  },
  {
    title: 'Warranty',
    path: '/warranty',
    subItems: null,
    target: '',
  },
  // {
  //   title: "Know Tractor Seva",
  //   path: "https://about.tractorseva.com/",
  //   subItems: null
  // },
  {
    title: 'Franchise',
    path: '/franchise',
    subItems: null,
    target: '',
  },
  {
    title: 'Contact Us',
    path: '/contact-us',
    subItems: null,
    target: '',
  },
  // {
  //   title: "Download App",
  //   path: "https://play.google.com/store/apps/details?id=com.tractorseva.customer",
  //   subItems: null,
  //   target: "_blank"
  // },
];

export const SIDEBAR_MENU = [
  // {
  //   title: "Know Tractor Seva",
  //   path: "https://about.tractorseva.com/",
  //   subItems: null
  // },
  {
    title: 'Home',
    path: '/',
    subItems: null,
    target: '',
  },
  {
    title: 'Book Now',
    path: '/#select-service',
    subItems: null,
    protected: false,
    target: '',
  },
  {
    title: 'About Us',
    path: '/about-us',
    subItems: null,
    protected: false,
    target: '',
  },
  {
    title: 'Warranty',
    path: '/warranty',
    subItems: null,
    target: '',
  },
  {
    title: 'My Order',
    path: '/booking',
    subItems: null,
    protected: true,
    target: '',
  },
  {
    title: 'My Profile',
    path: '/profile',
    subItems: null,
    protected: true,
    target: '',
  },
  {
    title: 'Franchise',
    path: '/franchise',
    subItems: null,
    protected: false,
    target: '',
  },
  {
    title: 'Contact Us',
    path: '/contact-us',
    subItems: null,
    protected: false,
    target: '',
  },
  // {
  //   title: "Download App",
  //   path: "https://play.google.com/store/apps/details?id=com.tractorseva.customer",
  //   subItems: null,
  //   protected: false,
  //   target: "_blank",
  // },
];

const languageOptions = [
  {
    id: 'EN',
    label: 'English',
    sortLabel: 'En',
  },
  {
    id: 'HI',
    label: 'हिन्दी',
    sortLabel: 'हिन्दी',
  },
  {
    id: 'MR',
    label: 'मराठी',
    sortLabel: 'मराठी',
  },
];

const header_secondary = ['booking', 'profile', 'cancellation-policy', 'terms-and-condition', 'service-agreement', 'privacy-policy', 'warranty-bosch-t&c'];

export default function Header() {
  const { getData } = useAuthContext();
  const { userData, isAuth } = getData();
  const { data, isLoading } = useGetProfile();
  const image = !isLoading && data?.image ? data.image : `${userData?.first_name[0]}`;
  const location = useGetLocation();
  const dispatch = useDispatch();
  const header_class = header_secondary.includes(window.location.pathname.split('/')[1]);
  const cart = useGetCart(userData?.user_id);
  const cartState = useSelector((state) => state.cart.cartState);
  const locationId = useSelector((state) => state.location.locationId);
  const stateId = useSelector((state) => state.location.stateId);
  const locationChange = useSelector((state) => state.location.locationChange);
  // useEffect(() => {
  //   if (location?.data?.location_id) {
  //     localStorage.setItem("location_id", location?.data.location_id);
  //     dispatch(setLocationID(location?.data.location_id));
  //     dispatch(setStateID(location?.data.state_id));
  //   }
  // }, [location]);

  if (isAuth && cart?.data?.Workshop && !locationChange) {
    if (cart?.data?.Workshop.SubDistrict.id && cart?.data?.Workshop.SubDistrict.id !== locationId) {
      // console.debug("divyaraj cart location", cart?.data?.Workshop.SubDistrict.id);
      localStorage.setItem('location_id', cart?.data?.Workshop.SubDistrict.id);
      dispatch(setLocationID(cart?.data?.Workshop.SubDistrict.id));
      dispatch(setStateID(cart?.data?.Workshop.SubDistrict.District.State.id));
    }
  } else if (!locationId && location.isFetched && !locationChange) {
    // console.debug(location?.data?.location_id, locationId);
    localStorage.setItem('location_id', location?.data.location_id);
    localStorage.setItem('state_id', location?.data.state_id);
    dispatch(setLocationID(location?.data.location_id));
    dispatch(setStateID(location?.data.state_id));
  } else {
    // console.debug("div", locationId, stateId);
    localStorage.setItem('location_id', locationId);
    localStorage.setItem('state_id', stateId);
    dispatch(setLocationID(locationId));
    dispatch(setStateID(stateId));
  }

  if (cart.data) {
    dispatch(setCartId(cart.data.id));
    dispatch(setCartItems(cart.data.CartItems));
  }

  const cartToggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    dispatch(setCartState({ ...cartState, [anchor]: open }));
  };

  const hideHeader = useSelector((state) => state.tractor_selection.hideHeader);

  return (
    <header id="header" style={{ position: `${hideHeader ? 'static' : 'sticky'}` }} className={`${header_class ? 'header-blue' : ''}`}>
      {window.innerWidth > 600 ? (
        <>
          {/* <HeaderWeb
            HEADER_MENU={HEADER_MENU}
            languageOptions={languageOptions}
            isImage={Boolean(data?.image)}
            image={image}
          /> */}
          <HeaderWebV2
            HEADER_MENU={HEADER_MENU}
            languageOptions={languageOptions}
            isImage={Boolean(data?.image)}
            image={image}
            cartToggleDrawer={cartToggleDrawer}
            onClose={cartToggleDrawer('right', false)}
            header_class={header_class}
            cartItems={cart?.data?.cart_items_count}
          />
        </>
      ) : (
        // <HeaderResponsive
        //   SIDEBAR_MENU={SIDEBAR_MENU}
        //   languageOptions={languageOptions}
        //   isImage={Boolean(data?.image)}
        //   image={image}
        // />
        <HeaderResponsiveV2
          SIDEBAR_MENU={SIDEBAR_MENU}
          languageOptions={languageOptions}
          isImage={Boolean(data?.image)}
          image={image}
          cartToggleDrawer={cartToggleDrawer}
          cartItems={cart?.data?.cart_items_count}
        />
      )}
      <Drawer anchor={window.innerWidth > 600 ? 'right' : 'bottom'} open={cartState['right']} onClose={cartToggleDrawer('right', false)}>
        <Cart data={cart?.data} onClose={cartToggleDrawer('right', false)} />
      </Drawer>
    </header>
  );
}

export function MenuOnly({ item }) {
  const langData = useLanguage();
  return (
    <HashLink className="sidebar-main-link" to={item.path} target={item.target}>
      <div className={`sidebar-title`}>{langData(item.title)}</div>
    </HashLink>
  );
}

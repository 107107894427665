import dayjs from 'dayjs';

export function getLastYears(years = 30) {
  let yearsArray = [];
  const currentYear = dayjs().format('YYYY');
  for (let i = 0; i < years; i++) {
    yearsArray.push((currentYear - i).toString());
  }
  return yearsArray;
}

import { createContext, useContext, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import AddBookingForm from './add-booking-form';
import { Navigate, useLocation } from 'react-router';
import { useGetPreBookingSummary } from './booking-api';
import BookingSummary from './booking-summary';
import { useAuthContext } from '../../context/authContext';
// import { useGetPreBookingSummary } from "../booking-api";

const steps = [{ label: 'Select Address & Preference' }, { label: 'Select Workshop' }, { label: 'Select Date & Time' }];

const defaultInputs = {
  customer_id: null,
  customer_tractor_id: null,
  tractor_model_id: null,
  service_id: null,
  customer_address_id: null,
  service_location: '1',
  workshop_id: null,
  booking_date: null,
  booking_time: null,
  notes: '',
  issues: {},
};

export default function BookingAdd() {
  const { getData } = useAuthContext();
  const { userData } = getData();

  const { state } = useLocation();
  // console.debug({ userData });
  // console.debug({ state });
  defaultInputs.customer_id = userData.user_id;
  defaultInputs.tractor_model_id = state.tractor_model_id;
  defaultInputs.customer_tractor_id = state.customer_tractor_id;
  defaultInputs.service_id = state.service_id;

  const [currStep, setCurrStep] = useState(0);
  const [isSummary, setIsSummary] = useState(false);
  const [bookingDataSummary, setBookingDataSummary] = useState([]);
  const [summaryPage, setSummaryPage] = useState(null);
  const [form, setForm] = useState(null);
  const bookingForm = useForm({ defaultValues: defaultInputs });
  const getBookingSummary = useGetPreBookingSummary();

  const stepPrev = () => setCurrStep((prevStep) => (prevStep <= 0 ? prevStep : prevStep - 1));
  const stepNext = () => setCurrStep((prevStep) => (prevStep >= steps.length - 1 ? prevStep : prevStep + 1));

  if (!state) return <Navigate to={'/'} />;

  const handleOpenSummary = ({ data }, formData) => {
    setSummaryPage(data);
    setIsSummary(true);
    setForm(formData);
  };

  // console.debug(bookingForm.watch());
  function getSummary(inputs) {
    console.debug('SUBMITTING...', inputs);
    const form = new FormData();
    for (const key in inputs) {
      if (key === 'booking_time') {
        const timeArr = inputs[key].split('-');
        timeArr[0] = dayjs(timeArr[0].trim(), 'hh:mm A').format('HH:mm');
        timeArr[1] = dayjs(timeArr[1].trim(), 'hh:mm A').format('HH:mm');
        form.append('start_time', timeArr[0]);
        form.append('end_time', timeArr[1]);
      } else if (key === 'issues') {
        form.append(key, JSON.stringify(inputs[key]));
      } else {
        form.append(key, inputs[key]);
      }
    }
    getBookingSummary.mutate(form, {
      onSuccess: handleOpenSummary,
    });
  }
  return (
    <section className="add-booking-container">
      <BookingProvider
        step={currStep}
        stepNext={stepNext}
        stepPrev={stepPrev}
        bookingDataSummary={bookingDataSummary}
        setBookingDataSummary={setBookingDataSummary}
        booking_meta_data={state}
      >
        {/* <h1>{isSummary ? "Booking Summary" : "Add Booking"}</h1> */}
        {isSummary ? (
          <BookingSummary onClose={() => setIsSummary(false)} bookingData={summaryPage} bookingForm={form} />
        ) : (
          <form className="step-form-container" noValidate onSubmit={bookingForm.handleSubmit(getSummary)}>
            <FormProvider {...bookingForm}>
              <AddBookingForm />
            </FormProvider>
          </form>
        )}
      </BookingProvider>
    </section>
  );
}

const BookingContext = createContext(null);

function BookingProvider({ children, ...props }) {
  return <BookingContext.Provider value={props}>{children}</BookingContext.Provider>;
}

export function useBookingContext() {
  const context = useContext(BookingContext);
  if (!context) throw new Error('Use this Context inside Booking Context Only');
  return context;
}

import './franchise-program.css';
import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useLanguage } from '../../../../../language/useLanguage';

function FranchiseProgram(props) {
  const { children, value, index, ...other } = props;
  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export default function BasicTabs() {
  const [value, setValue] = React.useState(0);
  const langData = useLanguage();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="tab-franchise-program-container">
      <div className="franchise-program-head-container">
        <h5>{langData(`Franchise`)}</h5>
        <p className="franchise-program-subtitle">{langData(`Discover limitless opportunities with our lucrative franchise program!`)}</p>
        <p className="franchise-program-des">
          {langData(
            `Become a part Tractor Seva as a franchise holder and embark on a journey of success. Benefit from our extensive support system and tap into a vast customer base while enjoying numerous advantages.`,
          )}
        </p>
      </div>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} variant="scrollable">
            <Tab label={langData(`New Experince`)} />
            <Tab label={langData('Stock Management')} />
            <Tab label={langData('Technical and Support')} />
            <Tab label={langData('Additional Leads')} />
            <Tab label={langData('Sales and Marketing')} />
            <Tab label={langData('Target-Based Incentive')} />
          </Tabs>
        </Box>
        <FranchiseProgram value={value} index={0}>
          <InnerTabsContent
            key={1}
            itctitle={langData('New Experience for Customers')}
            itcdes={langData('Provide your customers with an unmatched service experience at attractive rates, setting yourself apart from the competition.')}
            itcprcimg="./images/franchise-program-new-experience-customers.png"
          />
        </FranchiseProgram>
        <FranchiseProgram value={value} index={1}>
          <InnerTabsContent
            key={2}
            itctitle={langData('Stock Management Made Easy')}
            itcdes={langData('Leverage our specialized app for efficient stock management and rotation, ensuring optimum inventory control.')}
            itcprcimg="./images/Stock-Management-franchise-program.jpg"
          />
        </FranchiseProgram>
        <FranchiseProgram value={value} index={2}>
          <InnerTabsContent
            key={3}
            itctitle={langData('Technical and Support Services')}
            itcdes={langData('Access technical expertise and support from our team, just a phone call away, assisting you in troubleshooting and problem-solving.')}
            itcprcimg="./images/technical-and-support-franchise-program.jpg"
          />
        </FranchiseProgram>
        <FranchiseProgram value={value} index={3}>
          <InnerTabsContent
            key={4}
            itctitle={langData('Additional Service Leads')}
            itcdes={langData('Expand your business with service leads beyond regular maintenance, opening up new revenue streams.')}
            itcprcimg="./images/additional-service-franchise-program.jpg"
          />
        </FranchiseProgram>
        <FranchiseProgram value={value} index={4}>
          <InnerTabsContent
            key={5}
            itctitle={langData('Sales and Marketing Support')}
            itcdes={langData('Benefit from our comprehensive sales and marketing support, helping you expand your customer base and retain existing clients.')}
            itcprcimg="./images/sales-and-marketing-franchise-program.jpg"
          />
        </FranchiseProgram>
        <FranchiseProgram value={value} index={5}>
          <InnerTabsContent
            key={6}
            itctitle={langData('Target-Based Incentive Scheme')}
            itcdes={langData('Enjoy a performance-based incentive scheme, rewarding your efforts and driving your business growth.')}
            itcprcimg="./images/Target-Based-franchise-program.jpg"
          />
        </FranchiseProgram>
      </Box>
    </div>
  );
}

function InnerTabsContent({ itctitle, itcdes, itcprcimg }) {
  return (
    <div className="tab-inner-container">
      <div className="tab-inner-content-container">
        <div className="tab-inner-heading">
          <h5>{itctitle}</h5>
        </div>
        <div className="tab-inner-des">
          <p>{itcdes}</p>
        </div>
      </div>
      <div className="tab-inner-img-container">
        <img className="tab-inner-img" src={itcprcimg} />
      </div>
    </div>
  );
}

import './warranty-form.css';
import { Autocomplete, Button, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import InputNumber from '../../../assets/custom-fields/input-number';
// import { DatePicker } from "@mui/x-date-pickers";
// import dayjs from "dayjs";
// import IconDate from "../../../assets/icons/icon-common/icon-date";
import { useGetBrand } from '../../homepage/homepage-api';
import { useAuthContext } from '../../../context/authContext';
import { useNavigate } from 'react-router-dom';
import { useGetProductWarranty, useIsWarrantyAvailable, useGetModelAll } from '../warrenty-api';
import { getLastYears } from '../../../config/common-function/data-generate';
import { useLanguage } from '../../../language/useLanguage';

export default function CheckPriceForm({ inputForm, setData }) {
  const { getData } = useAuthContext();
  const { isAuth } = getData();
  const navigate = useNavigate();
  const getPrice = useGetProductWarranty();
  const langData = useLanguage();
  const isWarrantyAvailable = useIsWarrantyAvailable();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    control,
    watch,
    setValue,
  } = inputForm;

  const [tractor_brand_id] = watch(['tractor_brand_id']);
  const brandList = useGetBrand();
  const modelList = useGetModelAll(tractor_brand_id);
  const getYears = getLastYears(9);
  // const getYears = getLastYears(30);

  const submit = (inputs) => {
    console.debug({ inputs });
    const form = new FormData();
    for (const key in inputs) {
      form.append(key, inputs[key]);
    }
    getPrice.mutate(form, {
      onSuccess: (response) => {
        // debugger;
        console.debug('DATA: ', response.data);
        setData(response.data);
      },
      onError: (error) => {
        setError('root.serverError', { message: error.response.data.message, type: error.response.status });
      },
    });
  };

  return (
    <form noValidate onSubmit={handleSubmit(submit)}>
      <p style={{ fontWeight: 700, marginTop: 2, marginBottom: 16 }}>{langData('Please Fill The Form To Check The Extended Warranty Price For Your Tractor')}</p>
      <div className="field-container">
        <label className="required">{langData('Name')}</label>
        <TextField
          error={!!errors.owner_name}
          {...register('owner_name', {
            required: langData('Please Enter Name'),
            minLength: { value: 2, message: 'Atleast two character require' },
            maxLength: { value: 30, message: 'Limit exceed, 30 character allowed' },
          })}
          inputProps={{ maxLength: 30 }}
        />
        <p className="form-error-text">{errors.owner_name?.message}</p>
      </div>
      <div className="field-container">
        <label className="required">{langData('Contact Number')}</label>
        <Controller
          name="owner_phone_no"
          control={control}
          rules={{
            required: langData('Please Enter Contact Number'),
            maxLength: { value: 10, message: 'Enter valid input' },
            minLength: { value: 10, message: 'Enter valid input' },
          }}
          render={({ field }) => <InputNumber {...field} error={!!errors.owner_phone_no?.message} inputProps={{ maxLength: 10 }} />}
        />
        <p className="form-error-text">{errors.owner_phone_no?.message}</p>
      </div>

      <div className="divide-two">
        <div className="field-container">
          <label className="required">{langData('Tractor Brand')}</label>
          <Controller
            name="tractor_brand_id"
            control={control}
            rules={{ required: langData('Please Select Brand') }}
            render={({ field }) => {
              return (
                <Autocomplete
                  {...field}
                  options={brandList.data ?? []}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      // placeholder="Choose Your Tractor Brand"
                      error={!!errors.tractor_brand_id}
                    />
                  )}
                  isOptionEqualToValue={(option, value) => option.id === value}
                  getOptionLabel={(option) => option.label ?? brandList.data?.find((opt) => opt.id === option)?.label ?? ''}
                  onChange={(__event, data) => {
                    field.onChange(data?.id ?? null);
                    setValue('tractor_model_id', null);
                  }}
                />
              );
            }}
          />
          <p className="form-error-text">{errors.tractor_brand_id?.message}</p>
        </div>
        <div className="field-container">
          <label className="required">{langData('Tractor Model')}</label>
          <Controller
            name="tractor_model_id"
            control={control}
            rules={{
              required: langData('Please Select Model'),
              validate: isWarrantyAvailable,
            }}
            render={({ field }) => {
              return (
                <Autocomplete
                  {...field}
                  options={modelList.data ?? []}
                  disabled={!tractor_brand_id}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      // placeholder="Choose Your Tractor Model"
                      error={!!errors.tractor_model_id}
                    />
                  )}
                  isOptionEqualToValue={(option, value) => option.id === value}
                  getOptionLabel={(option) => option.label ?? modelList.data?.find((opt) => opt.id === option)?.label ?? ''}
                  onChange={(__event, data) => {
                    field.onChange(data?.id ?? null);
                  }}
                />
              );
            }}
          />
          <p className="form-error-text">{errors.tractor_model_id?.message}</p>
        </div>
      </div>

      <div className="divide-two">
        <div className="field-container">
          <label className="required">{langData('Year of Purchase')}</label>
          <Controller
            name="purchase_year"
            control={control}
            rules={{
              required: langData('Please Select Year of Purchase'),
            }}
            render={({ field }) => {
              return (
                <Autocomplete
                  {...field}
                  options={getYears}
                  value={field.value?.toString() ?? null}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      // placeholder={dayjs().format("YYYY")}
                      error={Boolean(errors.purchase_year)}
                    />
                  )}
                  onChange={(___event, newValue) => {
                    field.onChange(newValue ? Number(newValue) : newValue);
                  }}
                />
              );
            }}
          />
          <p className="form-error-text">{errors.purchase_year?.message}</p>
        </div>
        {/* <div className="field-container">
          <label className="required">Pump Manufacturing Date</label>
          <Controller
            name="feed_pump_manufacture_date"
            control={control}
            rules={{
              required: "Please Select Pump Manufacturing Date"
            }}
            render={({ field }) => {
              return (
                <DatePicker
                  {...field}
                  format="DD MMM, YYYY"
                  value={field.value ? dayjs(field.value, "YYYY-MM-DD") : null}
                  onChange={(dayJsObj) => field.onChange(dayJsObj.format("YYYY-MM-DD"))}
                  slots={{
                    openPickerIcon: () => <IconDate />
                  }}
                  slotProps={{
                    textField: { error: Boolean(errors.feed_pump_manufacture_date) }
                  }}
                  maxDate={dayjs()}
                />
              )
            }}
          />
          <p className="form-error-text">{errors.feed_pump_manufacture_date?.message}</p>
        </div> */}
      </div>
      <p style={{ marginBottom: 4, height: 'unset' }} className="form-error-text">
        {errors.root?.serverError?.message}
      </p>
      <div>
        {isAuth ? (
          <Button variant="contained" type="submit">
            {langData('Check For Price')}
          </Button>
        ) : (
          <Button
            variant="outlined"
            type="button"
            onClick={() =>
              navigate('/login', {
                state: {
                  redirectTo: '/warranty',
                  formInputs: watch(),
                },
              })
            }
          >
            {langData('Login For Price')}
          </Button>
        )}
      </div>
    </form>
  );
}

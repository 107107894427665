import './warranty-feedpump-deteriorating.css';
import { useLanguage } from '../../../language/useLanguage';

const tipsData = [
  {
    number: '1',
    title: 'Decreased Engine Performance',
    desc: 'A drop in power or efficiency could signal fuel injection pump issues.',
  },
  {
    number: '2',
    title: 'Irregular Engine Idling',
    desc: 'Unusual engine idling patterns may point towards fuel injection pump irregularities.',
  },
  {
    number: '3',
    title: 'Fuel Delivery Problems',
    desc: "If there's a noticeable delay or inconsistency in fuel delivery, it's time for a closer inspection.",
  },
];
export default function FeedPumpDeteriorating() {
  const langData = useLanguage();
  return (
    <div className="FeedPumpDeteriorating-container">
      <div className="title-text">
        <p style={{ marginBottom: '1rem' }} className="text-title" dangerouslySetInnerHTML={{ __html: langData('Signs For Your <b>Fuel Injection Pump is Deteriorating</b>') }}></p>
        <p className="text-desc">
          {langData(
            'Detecting early signs of wear and tear in your fuel injection pump can save you from costly repairs and downtime. Keep an eye out for the following indicators:',
          )}
        </p>
      </div>
      <img src="/images/Deteriorated-pump.png" alt="Deteriorated Pump" />
      <div className="tips-container">
        {tipsData.map(({ number, title, desc }) => (
          <div className="tip-card" key={number}>
            <h3>{langData(title)}</h3>
            <p className="text-desc">{langData(desc)}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

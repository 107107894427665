export default function IconTwitter({ width = '1em', height = '1em', color = '#fff', ...props }) {
  return (
    <svg width={width} height={height} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="35" height="35" rx="17.5" stroke={color} />
      <path
        d="M19.7354 16.72L26.0021 10H24.4021L19.0154 15.76L14.8021 10H9.46875L16.0288 18.96L9.46875 26H11.0688L16.7488 19.92L21.2021 26H26.5354L19.7354 16.72ZM11.8421 11.0667H13.9754L24.1354 24.9333H22.0021L11.8421 11.0667Z"
        fill={color}
      />
    </svg>
  );
}

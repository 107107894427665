import './ourbenefits.css';
// import { Button } from "@mui/material";

export default function OurBenefits({ data }) {
  return (
    <div className="our-benefits-container">
      <div className="our-benefits-title-container">
        <h5>{data?.heading}</h5>
        <p className="our-benefits-subtitle" dangerouslySetInnerHTML={{ __html: data?.sub_heading }}></p>
        {/* <p className="our-benefits-subtitle">
          We always go the extra mile to provide
          <span> unparalleled benefits to our customers,</span> here’s how
        </p> */}
        <div className="our-benefits-tractor">
          <img className="tractor-gradiant" src={data?.image?.[0]?.image_name} alt="tractor" onClick={() => data?.image?.[0].link && window.open(data.image[0].link, '_blank')} />
        </div>
      </div>
      <div className="our-benefits-card-container">
        {data?.content.map((elem, num) => (
          <Benefitscards key={num + 1} bnfticn={elem.icon} bnfttitle={elem.title} bnftsubtitle={elem.description} />
        ))}
        {/* <Button style={{ alignSelf: "flex-start" }} variant="contained">
          Know More
        </Button> */}
      </div>
    </div>
  );
}

function Benefitscards({ bnfticn, bnfttitle, bnftsubtitle }) {
  return (
    <div className="our-benefits-card">
      <div className="benefits-icn">
        <img className="icn" src={bnfticn} />
      </div>
      <h3 className="our-benefits-title">{bnfttitle}</h3>
      <p className="our-benefits-subtitle">{bnftsubtitle}</p>
    </div>
  );
}

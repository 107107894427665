import { useContext } from 'react';
import useAxios from '../axios/useAxios';
import { promptActions, promptContext } from '../../context/prompt';
import dayjs from 'dayjs';
import download from 'downloadjs';

export function useGetPdfExport(API, title) {
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);

  return (params) => {
    protectedAxios
      .get(`${API}`, { params: { ...params }, responseType: 'blob' })
      .then((response) => {
        download(response.data, `${title}-${dayjs().format('YYYYMMDD-HHmmss')}`);
      })
      .catch((error) => {
        console.debug('Error', error);
        prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.statusText || 'Error while export data' } });
      });
  };
}

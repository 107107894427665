import { useLanguage } from '../../../../../language/useLanguage';
import './franchise-banner.css';
// import { Button } from "@mui/material";

export default function FranchiseProgramBanner() {
  const langData = useLanguage();
  return (
    <div className="banner-franchise-program-container">
      <div className="banner-franchise-program-des-container">
        <div className="banner-franchise-program-title">{langData(`Franchise`)}</div>
      </div>
      <div className="banner-franchise-program-img-container">
        <div className="banner-franchise-program-img">{/* <img className="bnnr-map-img" src="./images/india-map-cta.svg" /> */}</div>
      </div>
    </div>
  );
}

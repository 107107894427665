import './uploadDoc.css';
import { LazyImage } from '../../assets/image/lazyImage';
import IconUploadImage from '../icons/icon-common/icon-upload-image';
import { useRef } from 'react';
import IconCancelFile from '../icons/icon-common/icon-cancel-file';
import { Avatar } from '@mui/material';

export default function UploadDoc({
  name = 'DefaultName',
  files,
  uploadedFiles = [],
  acceptedFileFormat = ['jpg', 'jpeg', 'png'],
  onUpload,
  onRemove,
  onRemoveUploaded,
  setError,
  isError = false,
  multiple = false,
  disabled = false,
  disableCircleImg = false,
}) {
  // const dispatch = useDispatch();
  const fileInputRef = useRef();
  const { accept, supportFormatText, validMimeType } = getFileFormatData(acceptedFileFormat);

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    ValidateAndUpload(e.dataTransfer.files);
  };

  const handleUpload = (e) => ValidateAndUpload(e.target.files);

  const ValidateAndUpload = (files) => {
    const Files = [...files];
    console.debug('Files: ', Files);
    for (const f of Files) {
      if (validMimeType.includes(f.type)) {
        if (f.size > 1 * 1024 * 1024) {
          // dispatch(promptShow({ type: 'error', message: 'File size must be less than 1 MB' }));
          console.debug('Size Failure: ', f.name);
          setError?.('File size must be less than 1 MB');
          return;
        }
      } else {
        // dispatch(promptShow({ type: 'error', message: 'Invalid File Format' }));
        setError?.('Invalid File Format');
        console.debug('Format Failure: ', f.name);
        return;
      }
    }
    if (Files.length > 0) {
      onUpload(files, name);
    }
  };

  return (
    <section className="upload-wrapper">
      <div
        className={`drop-area ${isError ? 'error' : ''}`}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onClick={() => {
          // console.log("PARENT CLICKED");
          fileInputRef.current.click();
        }}
      >
        {files.length > 0 ? (
          <div className="uploadedImgContainer">
            {files.map((f, i) => (
              <div key={i}>
                <div
                  className="cancel-file-icon-container"
                  onClick={(e) => {
                    // console.log("CHILD CLICKED");
                    e.stopPropagation();
                    onRemove(f, name);
                  }}
                >
                  <IconCancelFile />
                </div>
                {/(.pdf)$/.test(f.name) ? (
                  <img src="/logo/file_pdf.png" alt="PDF Icon" style={{ width: '50px', boxShadow: 'none' }} />
                ) : (
                  <img src={URL.createObjectURL(f)} style={{ width: '64px', height: '64px', borderRadius: disableCircleImg ? '0' : '50%' }} alt={f.name} />
                )}
                <p>{f.name.length > 18 ? f.name.slice(0, 5) + '...' + f.name.substr(-10) : f.name}</p>
                <p>{`(${Math.round(f.size / 1024)} KB)`}</p>
              </div>
            ))}
          </div>
        ) : uploadedFiles.length > 0 ? (
          <div className="uploadedImgContainer">
            {uploadedFiles.map((image_name, i) => (
              <div key={i} className="position-relative text-center imageDisplay">
                <div
                  className="cancel-file-icon-container"
                  onClick={(e) => {
                    // console.log("CHILD CLICKED");
                    e.stopPropagation();
                    onRemoveUploaded(image_name, name);
                  }}
                >
                  <IconCancelFile />
                </div>
                {/(.png|.jpg|.jpeg)$/.test(image_name) ? (
                  <Avatar className="uploaded-img-avatar" sx={{ width: 64, height: 64 }}>
                    <LazyImage name={image_name} width={64} height={64} />
                  </Avatar>
                ) : (
                  <img src="/logo/file_pdf.png" alt="PDF Icon" style={{ width: '50px', boxShadow: 'none' }} />
                )}

                <p>{image_name.length > 18 ? image_name.slice(0, 5) + '...' + image_name.substr(-10) : image_name}</p>
              </div>
            ))}
          </div>
        ) : (
          <IconUploadImage className="uploaddoc-icon" />
        )}

        <div className="action-text">
          <p>
            Click to Upload or
            <br />
            Drag & Drop Here
            <input hidden type="file" accept={accept} ref={fileInputRef} onChange={handleUpload} multiple={multiple} disabled={disabled} />
          </p>
        </div>

        <div className="infoText">
          <p>Maximum size 1MB</p>
          <p>Supported formats: {supportFormatText}</p>
        </div>
      </div>
    </section>
  );
}

function getFileFormatData(fileFormatArray) {
  const accept = [];
  const supportFormatText = [];
  const validMimeType = [];

  fileFormatArray.forEach((format) => {
    accept.push(`.${format}`);

    switch (format) {
      case 'pdf':
        validMimeType.push('application/pdf');
        supportFormatText.push('PDF');
        break;
      case 'jpg':
        validMimeType.push('image/jpg');
        !supportFormatText.includes('JPG') && supportFormatText.push('JPG');
        break;
      case 'jpeg':
        validMimeType.push('image/jpeg');
        !supportFormatText.includes('JPG') && supportFormatText.push('JPG');
        break;
      case 'png':
        validMimeType.push('image/png');
        supportFormatText.push('PNG');
        break;
      case 'svg':
        validMimeType.push('image/svg+xml');
        supportFormatText.push('SVG');
        break;
      default:
        console.error(`Invalid File Format Found: ${format}`);
    }
  });

  return {
    accept: accept.join(','),
    supportFormatText: supportFormatText.join(', '),
    validMimeType,
  };
}

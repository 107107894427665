import { useMutation, useQueryClient } from '@tanstack/react-query';
import { promptActions, promptContext } from '../../../context/prompt';
import { useContext } from 'react';
import useAxios from '../../../config/axios/useAxios';

// export function useGetCart(customer_id) {
//   const API = "cart";
//   const protectedAxios = useAxios();
//   const prompt = useContext(promptContext);
//   const navigate = useNavigate();

//   return useQuery({
//     queryKey: [API],
//     queryFn: async () => {
//       try {
//         const { data } = await protectedAxios.get(API, { params: { customer_id } });
//         return data;
//       } catch (error) {
//         prompt({
//           type: promptActions.SHOW_PROMPT,
//           payload: { message: error.response.data?.message || "Error while getting data" },
//         });
//         if (error.response.status === 401) navigate("/session-timeout");
//         return;
//       }
//     },
//   });
// }

export function useAddToCart() {
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (formData) => protectedAxios.post('cart', formData),
    onSuccess: (response) => {
      prompt({
        type: promptActions.SHOW_PROMPT,
        payload: {
          type: 'success',
          message: response.data?.message || 'Added successfully',
        },
      });
      queryClient.invalidateQueries({ queryKey: ['cart'] });
    },
    onError: (error) => {
      prompt({
        type: promptActions.SHOW_PROMPT,
        payload: {
          message: error.response.data?.message || 'Error while adding',
        },
      });
    },
  });
}

export function useUpdateCartItem(cart_item_id, customer_id) {
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (formData) => protectedAxios.put(`cart/${cart_item_id}`, formData),
    onSuccess: (response) => {
      prompt({
        type: promptActions.SHOW_PROMPT,
        payload: {
          type: 'success',
          message: response.data?.message || 'Updated successfully',
        },
      });
      queryClient.invalidateQueries({ queryKey: ['cart', customer_id] });
    },
    onError: (error) => {
      prompt({
        type: promptActions.SHOW_PROMPT,
        payload: {
          message: error.response.data?.message || 'Error while updating',
        },
      });
      if (error.response.status === 500) {
        queryClient.invalidateQueries({ queryKey: ['cart', customer_id] });
      }
    },
  });
}

export function useUpdateCartService(cart_item_id, customer_id) {
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (formData) => protectedAxios.put(`cart/service/${cart_item_id}`, formData),
    onSuccess: (response) => {
      prompt({
        type: promptActions.SHOW_PROMPT,
        payload: {
          type: 'success',
          message: response.data?.message || 'Updated successfully',
        },
      });
      queryClient.invalidateQueries({ queryKey: ['cart', customer_id] });
    },
    onError: (error) => {
      prompt({
        type: promptActions.SHOW_PROMPT,
        payload: {
          message: error.response.data?.message || 'Error while updating',
        },
      });
    },
  });
}

export function useUpdateCart(customer_id) {
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (formData) => protectedAxios.post(`cart`, formData),
    onSuccess: (response) => {
      prompt({
        type: promptActions.SHOW_PROMPT,
        payload: {
          type: 'success',
          message: response.data?.message || 'Updated successfully',
        },
      });
      queryClient.invalidateQueries({ queryKey: ['cart', customer_id] });
    },
    onError: (error) => {
      prompt({
        type: promptActions.SHOW_PROMPT,
        payload: {
          message: error.response.data?.message || 'Error while updating',
        },
      });
    },
  });
}

export function useDeleteCartItem(cart_item_id) {
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => protectedAxios.delete(`cart/${cart_item_id}`),
    onSuccess: (response) => {
      prompt({
        type: promptActions.SHOW_PROMPT,
        payload: {
          type: 'success',
          message: response.data?.message || 'Deleted successfully',
        },
      });
      queryClient.invalidateQueries({ queryKey: ['cart'] });
      // queryClient.invalidateQueries({ queryKey: ["booking/v2/booking_summary"] });
    },
    onError: (error) => {
      prompt({
        type: promptActions.SHOW_PROMPT,
        payload: {
          message: error.response.data?.message || 'Error while deleting',
        },
      });
    },
  });
}
export function useDeleteCart(cart_id) {
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => protectedAxios.delete(`cart/empty/${cart_id}`),
    onSuccess: (response) => {
      prompt({
        type: promptActions.SHOW_PROMPT,
        payload: {
          type: 'success',
          message: response.data?.message || 'Deleted successfully',
        },
      });
      queryClient.invalidateQueries({ queryKey: ['cart'] });
      queryClient.invalidateQueries({ queryKey: ['cart/summary', cart_id] });
    },
    onError: (error) => {
      prompt({
        type: promptActions.SHOW_PROMPT,
        payload: {
          message: error.response.data?.message || 'Error while deleting',
        },
      });
    },
  });
}

export function useAddCustomerOrder() {
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  return useMutation({
    mutationFn: async (params) => {
      const { data } = await protectedAxios.get('cart/payment-link', { params });
      return data;
    },
    onError: (error) => {
      prompt({
        type: promptActions.SHOW_PROMPT,
        payload: {
          message: error.response.data?.message || 'Error while sending',
        },
      });
    },
  });
}

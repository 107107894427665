import { useState } from 'react';
import './my-address.css';
import { useAddAddress, useGetAddress, useGetDistricts, useGetStates, useGetSubDistricts, useUpdateAddress, useMakeAddressPrimary } from '../profile-api';
import { Autocomplete, Button, Dialog, Skeleton, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import IconEdit from '../../../assets/icons/icon-common/icon-edit';
import { useAuthContext } from '../../../context/authContext';
import { TagCompleted } from '../../common/tags';
import { useLanguage } from '../../../language/useLanguage';

export default function MyAddress() {
  const getUserAddress = useGetAddress();
  const { getData } = useAuthContext();
  const { userData } = getData();
  const [edit, setEdit] = useState(false);
  const handleEditClose = () => setEdit(false);
  const [add, setAdd] = useState(false);
  const handleAddClose = () => setAdd(false);
  const [addressID, setAddressID] = useState(null);
  const [inputs, setInputs] = useState(null);
  const langData = useLanguage();

  const handleEditAddress = (addressData) => {
    const inputs = {
      address_name: addressData.address_name,
      address_1: addressData.address_1,
      address_2: addressData.address_2,
      state_id: addressData.SubDistrict.District.State.id,
      district_id: addressData.SubDistrict.District.id,
      sub_district_id: addressData.SubDistrict.id,
      is_primary: addressData.is_primary,
      pincode: addressData.pincode,
    };
    setAddressID(addressData.id);
    setInputs(inputs);
    setEdit(true);
  };
  if (getUserAddress.isLoading) return <LoadingAddresses />;

  return (
    <div className="address-details">
      <div className="heading-container">
        <h2>{langData('Address Details')}</h2>
        <Button variant="contained" onClick={() => setAdd(true)}>
          {langData('Add New Address')}
        </Button>
      </div>
      {getUserAddress.data.length > 0 ? (
        <div className="address-details-grid">
          {getUserAddress.data.map((adrs) => (
            <div key={adrs.id} className="address-card">
              <div className="address-header">
                <div style={{ display: 'flex', gap: 12, alignItems: 'center' }}>
                  <h3>{adrs.address_name}</h3>
                  <span>{adrs.is_primary === true ? <TagCompleted text="Primary" /> : ''}</span>
                </div>
                <div className="icon-edit" onClick={() => handleEditAddress(adrs)}>
                  <IconEdit />
                </div>
              </div>
              <p>{`${adrs.address_1}, ${adrs.address_2 ? adrs.address_2 + ', ' : ''} ${adrs.SubDistrict.subdistrict_name}, ${
                adrs.SubDistrict.District.district_name
              }, ${adrs.SubDistrict.District.State.state_name} - ${adrs.pincode}`}</p>
            </div>
          ))}
        </div>
      ) : (
        <NoAddress />
      )}

      {edit && <AddAddressModel open={edit} onClose={handleEditClose} inputs={inputs} addressID={addressID} customer_id={userData?.user_id} />}
      {add && (
        <AddAddressModel
          open={add}
          onClose={handleAddClose}
          inputs={{
            address_name: '',
            address_1: '',
            address_2: '',
            state_id: null,
            district_id: null,
            sub_district_id: null,
            pincode: '',
          }}
        />
      )}
    </div>
  );
}

export function AddAddressModel({ open = false, onClose, addressID, customer_id, inputs }) {
  const addAddress = useAddAddress();
  const langData = useLanguage();
  const updateAddress = useUpdateAddress(addressID);
  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
  } = useForm({ defaultValues: inputs });
  const [state_id, district_id] = watch(['state_id', 'district_id']);

  const dropdownStateList = useGetStates();
  const dropdownDistrictList = useGetDistricts(state_id);
  const dropdownSubdistrictList = useGetSubDistricts(district_id);
  const makePrimaryAddress = useMakeAddressPrimary(customer_id, addressID);

  function submit(inputs) {
    console.debug('SUBMITTING: ', inputs);
    const form = new FormData();
    for (const key in inputs) {
      form.append(key, inputs[key]);
    }

    if (addressID) {
      form.append('address_id', addressID);
      updateAddress.mutate(form, {
        onSuccess: () => {
          onClose();
        },
      });
    } else {
      addAddress.mutate(form, {
        onSuccess: () => {
          onClose();
        },
      });
    }
  }

  function makeSubmitPrimaryAddress() {
    const form = new FormData();
    makePrimaryAddress.mutate(form, {
      onSuccess: () => {
        onClose();
      },
    });
  }

  return (
    <Dialog open={open} onClose={onClose} className="popup edit-profile">
      <form
        className="form-container"
        noValidate
        onSubmit={(e) => {
          e.stopPropagation();
          handleSubmit(submit)(e);
        }}
      >
        {/* <h2>{addressID ? "Update" : "Add"} Address</h2> */}
        <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
          <h2>
            {addressID ? 'Update' : 'Add'} {langData('Address')}
          </h2>
          {inputs.is_primary === true && <TagCompleted text="Primary" />}
          {addressID && inputs.is_primary !== true && (
            <Button variant="contained" style={{ marginLeft: 'auto' }} onClick={makeSubmitPrimaryAddress}>
              {langData('Make Address Primary')}
            </Button>
          )}
        </div>
        <div className="field-container">
          <label className="required">{langData('Address Name')}</label>
          <TextField
            {...register('address_name', {
              required: langData('Field is Required'),
              minLength: { value: 2, message: 'Minimum 2 character required' },
              maxLength: { value: 30, message: 'Maximum limit exceed' },
            })}
            error={!!errors.address_name}
          />
          <p className="error-text">{errors.address_name?.message}</p>
        </div>
        <div className="field-container">
          <label className="required">{langData('Address Line 1')}</label>
          <TextField
            {...register('address_1', {
              required: langData('Field is Required'),
              minLength: { value: 2, message: 'Minimum 2 character required' },
              maxLength: { value: 200, message: 'Maximum limit exceed' },
            })}
            error={!!errors.address_1}
          />
          <p className="error-text">{errors.address_1?.message}</p>
        </div>
        <div className="field-container">
          <label>{langData('Address Line 2')}</label>
          <TextField
            {...register('address_2', {
              minLength: { value: 2, message: 'Minimum 2 character required' },
              maxLength: { value: 200, message: 'Maximum limit exceed' },
            })}
            error={!!errors.address_2}
          />
          <p className="error-text">{errors.address_2?.message}</p>
        </div>
        <div className="form-column-2">
          <div className="field-container">
            <label className="required">{langData('State')}</label>
            <Controller
              name={'state_id'}
              control={control}
              rules={{ required: langData('Please Select State') }}
              render={({ field }) => {
                return (
                  <Autocomplete
                    {...field}
                    options={dropdownStateList.data ?? []}
                    renderInput={(params) => <TextField {...params} placeholder={langData('State')} error={!!errors.state_id} />}
                    isOptionEqualToValue={(option, value) => option.id === value}
                    getOptionLabel={(option) => option.label ?? dropdownStateList.data?.find((opt) => opt.id === option)?.label ?? ''}
                    onChange={(__event, data) => {
                      field.onChange(data?.id ?? null);
                      setValue(`district_id`, null);
                      setValue(`sub_district_id`, null);
                    }}
                  />
                );
              }}
            />
            <p className="form-error-text">{errors.state_id?.message}</p>
          </div>
          <div className="field-container">
            <label className="required">{langData('District')}</label>
            <Controller
              name={'district_id'}
              control={control}
              rules={{ required: langData('Please Select District') }}
              render={({ field }) => {
                return (
                  <Autocomplete
                    {...field}
                    disabled={!state_id}
                    options={dropdownDistrictList.data ?? []}
                    renderInput={(params) => <TextField {...params} placeholder={langData('District')} error={!!errors.district_id} />}
                    isOptionEqualToValue={(option, value) => option.id === value}
                    getOptionLabel={(option) => option.label ?? dropdownDistrictList.data?.find((opt) => opt.id === option)?.label ?? ''}
                    onChange={(__event, data) => {
                      field.onChange(data?.id ?? null);
                      setValue('sub_district_id', null);
                    }}
                  />
                );
              }}
            />
            <p className="form-error-text">{errors.district_id?.message}</p>
          </div>
        </div>
        <div className="form-column-2">
          <div className="field-container">
            <label className="required">{langData('Tehsil')}</label>
            <Controller
              name={'sub_district_id'}
              control={control}
              rules={{ required: langData('Please Select Tehsil') }}
              render={({ field }) => {
                return (
                  <Autocomplete
                    {...field}
                    disabled={!district_id}
                    options={dropdownSubdistrictList.data ?? []}
                    renderInput={(params) => <TextField {...params} placeholder={langData('Tehsil')} error={!!errors.sub_district_id} />}
                    isOptionEqualToValue={(option, value) => option.id === value}
                    getOptionLabel={(option) => option.label ?? dropdownSubdistrictList.data?.find((opt) => opt.id === option)?.label ?? ''}
                    onChange={(__event, data) => {
                      field.onChange(data?.id ?? null);
                    }}
                  />
                );
              }}
            />
            <p className="form-error-text">{errors.sub_district_id?.message}</p>
          </div>
          <div className="field-container">
            <label className="required">{langData('Pincode')}</label>
            <TextField
              placeholder={langData('Pincode')}
              error={!!errors.pincode}
              {...register('pincode', {
                required: langData('Field is Required'),
                minLength: { value: 6, message: 'Enter valid pincode' },
                maxLength: { value: 6, message: 'Enter valid pincode' },
              })}
            />
            <p className="form-error-text">{errors.pincode?.message}</p>
          </div>
        </div>
        <div style={{ display: 'flex', gap: 16, flexWrap: 'wrap' }}>
          <Button variant="contained" type="submit" disabled={addAddress.isLoading || updateAddress.isLoading}>
            {langData('Save')}
          </Button>
          <Button variant="outlined" onClick={onClose}>
            {langData('Cancel')}
          </Button>
        </div>
      </form>
    </Dialog>
  );
}

function LoadingAddresses() {
  const langData = useLanguage();
  return (
    <div className="address-details">
      <div className="heading-container">
        <h2>{langData('Address Details')}</h2>
        <Button variant="contained" disabled>
          {langData('Add New Address')}
        </Button>
      </div>
      <div className="address-details-grid">
        {[1, 2].map((no) => (
          <div key={no} className="address-card">
            <div className="address-header">
              <h3>
                <Skeleton variant="text" />
              </h3>
            </div>
            <p>
              <Skeleton variant="text" />
            </p>
            <p>
              <Skeleton variant="text" />
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}

function NoAddress() {
  return (
    <div style={{ textAlign: 'center', width: '100%' }}>
      <img style={{ width: '100%', maxWidth: '400px' }} src="assets/images/placeholder-no-address.svg" alt="No-Address" />
      <p style={{ fontSize: 20, margin: '16px 0px' }}>No Address Available</p>
    </div>
  );
}

import FranchiseProgramBanner from './franchise-banner/franchise-banner';
import FranchiseFaqs from './franchise-faqs/franchise-faqs';
import GetStarted from './get-started/get-started';
import JoinOurFranchise from './Join-our-franchise/Join-our-franchise';
import FranchiseProgram from './franchise-program/franchise-program';
import FranchiseForm from './franchise-form/franchise-form';
import FranchiseSEO from './franchise-seo';

export default function FranchisePage() {
  return (
    <div>
      <FranchiseSEO />
      <FranchiseProgramBanner />
      <JoinOurFranchise />
      <GetStarted />
      <FranchiseProgram />
      <FranchiseForm />
      <FranchiseFaqs />
    </div>
  );
}

import { useContext } from 'react';
import useAxios from '../../../config/axios/useAxios';
import { promptActions, promptContext } from '../../../context/prompt';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useAuthContext } from '../../../context/authContext';

export function useGetTractorBrandDDList() {
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  const navigate = useNavigate();
  const { getData } = useAuthContext();
  const { lang } = getData();

  return useQuery({
    queryKey: ['tractor-brand-dd-list', lang],
    queryFn: async () => {
      try {
        const { data } = await protectedAxios.get('/getdata/brands', { params: { language: lang } });
        return data;
      } catch (error) {
        prompt({
          type: promptActions.SHOW_PROMPT,
          payload: {
            message: error.response.data?.message || 'Error while getting data',
          },
        });
        if (error.response.status === 401) navigate('/session-timeout');
        return;
      }
    },
  });
}

export function useGetCustomerTractor() {
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  const navigate = useNavigate();
  const { getData } = useAuthContext();
  const { isAuth, lang } = getData();

  return useQuery({
    enabled: !!isAuth,
    queryKey: ['customer-tractors', lang],
    queryFn: async () => {
      try {
        const { data } = await protectedAxios.get('/customer/tractor/getall');
        return data;
      } catch (error) {
        prompt({
          type: promptActions.SHOW_PROMPT,
          payload: {
            message: error.response.data?.message || 'Error while getting data',
          },
        });
        if (error.response.status === 401) navigate('/session-timeout');
        return;
      }
    },
  });
}

export function useGetCustomerTractorNew(brand_id) {
  const baseAPI = 'getData/user_models';
  const API = brand_id ? `${baseAPI}/${brand_id}` : baseAPI;

  const protectedAxios = useAxios();
  const { setToken } = useAuthContext();
  const prompt = useContext(promptContext);
  const { getData } = useAuthContext();
  const { lang } = getData();

  return useQuery({
    queryKey: [lang, baseAPI, brand_id],
    cacheTime: 5 * 60 * 1000,
    queryFn: async () => {
      try {
        const { data } = await protectedAxios.get(`${API}`, {
          params: { language: lang },
        });
        return data;
      } catch (error) {
        if (error.response.status === 401) {
          setToken('clearToken');
          prompt({
            type: promptActions.SHOW_PROMPT,
            payload: {
              message: error.response.data?.message || 'Token Expired',
            },
          });
        }
        return;
      }
    },
  });
}

export function useGetTractorModelDDListByBrand(id) {
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  const navigate = useNavigate();

  return useQuery({
    enabled: !!id,
    queryKey: ['tractor-model-dd-list', id],
    queryFn: async () => {
      try {
        const { data } = await protectedAxios.get(`/getdata/models/${id}`);
        return data;
      } catch (error) {
        prompt({
          type: promptActions.SHOW_PROMPT,
          payload: {
            message: error.response.data?.message || 'Error while getting data',
          },
        });
        if (error.response.status === 401) navigate('/session-timeout');
        return;
      }
    },
  });
}
export function useGetAllServiceTypeV3(tractor_model_id) {
  const API = 'getdata/v3/tractor_service';
  const protectedAxios = useAxios();
  const { setToken } = useAuthContext();
  const prompt = useContext(promptContext);
  const { getData } = useAuthContext();
  const { lang } = getData();
  return useQuery({
    queryKey: [lang, API, tractor_model_id],
    queryFn: async () => {
      try {
        const { data } = await protectedAxios.get(`${API}/${tractor_model_id}`);
        return data;
      } catch (error) {
        if (error.response.status === 401) {
          setToken('clearToken');
          prompt({
            type: promptActions.SHOW_PROMPT,
            payload: {
              message: error.response.data?.message || 'Token Expired',
            },
          });
        }
        return;
      }
    },
  });
}
export function useGetAllServiceTypeV3Trial() {
  const protectedAxios = useAxios();
  const { setToken } = useAuthContext();
  const prompt = useContext(promptContext);
  // const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (val) => {
      const params = {
        tractor_model_id: val.tractor_model_id,
        workshop_id: val.workshop_id,
      };
      return protectedAxios.get(`/service_type/v2/user/getall`, {
        params,
      });
    },
    onError: (error) => {
      if (error.response.status === 401) {
        setToken('clearToken');
        prompt({
          type: promptActions.SHOW_PROMPT,
          payload: { message: error.response.data?.message || 'Token Expired' },
        });
      }
      return;
    },
  });
}

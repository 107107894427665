import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import jwt_decode from 'jwt-decode';

const getLocalData = () => {
  const token = localStorage.getItem('token');
  const userdata = token ? JSON.parse(localStorage.getItem('userdata')) : {};
  const isAuth = token && userdata ? true : false;
  return { token, userdata, isAuth };
};

export const authLogin = createAsyncThunk('user/authLogin', async (credentials, { rejectWithValue }) => {
  let result;
  try {
    await axios
      .post('auth/login', credentials)
      .then((res) => {
        // console.debug('Login_Res: ', res);
        result = res.data;
      })
      .catch((err) => {
        throw err;
      });
    return result;
  } catch (error) {
    // console.error('Catch: ', error);
    return rejectWithValue(error.response.data);
  }
});

const initialState = () => getLocalData();
export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: () => {
      localStorage.setItem('token', null);
      localStorage.setItem('userdata', null);
      return { isAuth: false, token: null, userdata: {} };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(authLogin.fulfilled, (_state, action) => {
        localStorage.setItem('token', action.payload.token);
        const userdata = jwt_decode(action.payload.token);
        localStorage.setItem('userdata', JSON.stringify(userdata));
        return { isAuth: true, token: action.payload.token, userdata: userdata };
      })
      .addCase(authLogin.rejected, () => {
        return { isAuth: false, token: null, userdata: {} };
      });
  },
});

export const { logout } = authSlice.actions;
export default authSlice.reducer;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  cartId: null,
  cartState: {
    // top: false,
    // left: false,
    bottom: false,
    right: false,
  },
  editServicePopId: null,
  cartItems: null,
};

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setCartId: (state, action) => {
      state.cartId = action.payload;
    },
    setCartState: (state, action) => {
      state.cartState = action.payload;
    },
    setEditServicePopId: (state, action) => {
      state.editServicePopId = action.payload;
    },
    setCartItems: (state, action) => {
      state.cartItems = action.payload;
    },
  },
});

export const { setCartId, setCartState, setEditServicePopId, setCartItems } = cartSlice.actions;
export default cartSlice.reducer;

import { Avatar, Button } from '@mui/material';
import { LazyImage } from '../../../assets/image/lazyImage';
// import { dateFormat } from "../../../config/common-function/date-time-format";
import { useNavigate } from 'react-router';
import IconEdit from '../../../assets/icons/icon-common/icon-edit';
import IconTrusted from '../../../assets/icons/icon-common/icon-trusted';

export default function WarrantyMiniSummary({ data, prevStep }) {
  console.debug('DATA: ', data);
  const {
    customer_name,
    model_img,
    tractor_name,
    // feed_pump_manufacture_date,
    purchase_year,
    product: { name, description, price },
  } = data;

  const descriptionArr = description.split(',');
  const navigate = useNavigate();

  return (
    <form noValidate className="warranty-mini-summary">
      <div className="name">Hi {customer_name},</div>
      <div className="tractor-details">
        <Avatar sx={{ width: 80, height: 80 }}>
          <LazyImage name={model_img} fallbackImage="tractor-model-image.svg" width={80} height={80} />
        </Avatar>
        <div className="details-section">
          <p>
            {tractor_name}
            <span onClick={prevStep}>
              <IconEdit />
            </span>
          </p>
          <div>
            <div className="details-block">
              <div>Year of Purchase</div>
              <p>{purchase_year}</p>
            </div>
            <div className="details-block">
              <div>Product</div>
              <p>{name}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="purchase-card">
        <div className="name-title">
          <IconTrusted />
          {name} warranty
        </div>
        <ul className="description-list">
          {descriptionArr.map((ele) => (
            <li key={ele}>{ele}</li>
          ))}
        </ul>
        <div className="purchase-action">
          <div className="price">
            <p>Pricing</p>
            <div style={{ color: 'var(--clr-secondary)', textAlign: 'end', fontSize: '1.25rem', fontWeight: 700 }}>
              ₹ {price}
              <div style={{ color: 'var(--clr-gray)', fontSize: 12, fontWeight: 500 }}>(Including GST)</div>
            </div>
          </div>
          <Button variant="contained" type="button" sx={{ minWidth: 120 }} onClick={() => navigate('/warranty-purchase', { state: data })}>
            Purchase
          </Button>
        </div>
      </div>
    </form>
  );
}

export default function IconEditRound({ width = '1em', height = '1em', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 86 86" fill="none" {...props}>
      <circle cx="43" cy="43" r="43" fill="#E2E8F0" />
      <path
        d="M59.5477 32.9901L40.1593 52.3785C39.5055 53.0324 38.616 53.3957 37.6914 53.3866L29.6057 53.307C27.7514 53.2888 26.2448 51.805 26.1983 49.9511L26.0011 42.0862C25.9774 41.1436 26.3415 40.2326 27.0082 39.5658L46.5658 20.0082C47.9102 18.6639 50.0897 18.6639 51.434 20.0082L59.5477 28.1219C60.892 29.4662 60.892 31.6458 59.5477 32.9901Z"
        fill="#EC2726"
      />
      <rect x="27.1174" y="62.0303" width="33.85" height="4.23125" rx="2.11563" fill="#002852" />
    </svg>
  );
}

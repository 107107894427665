export default function IconUploadImage({ width = '1em', height = '1em', color = 'var(--clr-primary)', color2 = 'var(--clr-secondary)', ...props }) {
  return (
    <svg width={width} height={height} viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_824_1540)">
        <path
          d="M17.1327 8.34668C13.7416 8.36384 11.0048 11.4003 11.0204 15.1289C11.023 15.6509 11.0802 16.1701 11.1908 16.6779C11.9934 20.2907 15.3064 22.5047 18.5922 21.6222C21.8779 20.7398 23.8915 17.0969 23.0889 13.4841C22.419 10.4663 19.958 8.34382 17.1327 8.34811V8.34668Z"
          fill={color2}
        />
        <path
          d="M33.0307 32.2426C36.314 28.9636 40.7826 27.125 45.4392 27.1374C46.6839 27.1429 47.9245 27.2706 49.143 27.5205C49.7017 27.6372 50.2535 27.7882 50.7929 27.9736C51.259 28.1068 51.7168 28.2702 52.1621 28.4611L52.6531 28.6698V7.29027C52.6434 3.81353 49.8013 1 46.2995 1H6.35361C2.84903 1.00961 0.00968116 3.82863 0 7.30812V39.9802C0 43.4665 2.84211 46.2965 6.35361 46.3061H27.9758C27.9758 45.7308 27.8887 45.139 27.8887 44.5636C27.9177 43.9801 27.9827 43.3979 28.0823 42.8212C28.4474 38.8322 30.1969 35.0946 33.0321 32.244L33.0307 32.2426ZM26.0105 28.2867L24.8875 27.136L23.9927 26.2641C20.7564 23.1635 15.6282 23.1635 12.3919 26.2641L3.5115 34.9765V7.29027C3.53086 5.7359 4.80601 4.48498 6.37159 4.48498H46.2995C47.8595 4.47537 49.1333 5.72354 49.143 7.27242V18.024C48.4252 17.5997 47.659 17.2605 46.861 17.0133C42.6013 15.6567 37.9336 16.7497 34.7332 19.853L26.0105 28.2867Z"
          fill={color2}
        />
        <path
          d="M46.883 38.6548C46.3072 38.0832 45.3784 38.0832 44.8026 38.6548L39.381 44.5219C38.8823 44.9618 38.7155 45.8009 39.168 46.3697C39.4847 46.769 39.6276 46.832 40.5017 46.832H44.0573V50.3876C44.0573 51.3696 44.853 52.1654 45.8351 52.1654C46.8171 52.1654 47.6129 51.3696 47.6129 50.3876V46.832H51.3463C52.1211 46.8614 52.7739 46.2576 52.8047 45.4829V45.4815C52.8229 45.0892 52.6744 44.7082 52.3956 44.4322L46.8844 38.6548H46.883Z"
          fill={color}
        />
        <path
          d="M45.7343 59.2919C37.8681 59.2919 31.4686 52.8924 31.4686 45.0262C31.4686 37.16 37.8681 30.7605 45.7343 30.7605C53.6006 30.7605 60 37.16 60 45.0262C60 52.8924 53.6006 59.2919 45.7343 59.2919ZM45.7343 34.4029C39.877 34.4029 35.1111 39.1689 35.1111 45.0262C35.1111 50.8835 39.877 55.6495 45.7343 55.6495C51.5916 55.6495 56.3576 50.8835 56.3576 45.0262C56.3576 39.1689 51.5916 34.4029 45.7343 34.4029Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_824_1540">
          <rect width="60" height="60" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

import axios from 'axios';
import { useAuthContext } from '../../context/authContext';

export default function useAxios() {
  const { getData } = useAuthContext();
  const { token } = getData();
  return axios.create({
    baseURL: process.env.REACT_APP_SERVER_URL,

    headers: { Authorization: `Bearer ${token}` },
  });
}

export function getFormData(inputs, callback) {
  const form_data = new FormData();
  for (const key in inputs) {
    switch (typeof inputs[key]) {
      case 'undefined':
        break;
      case 'object':
        if (inputs[key] instanceof File) {
          for (const file of inputs[key]) {
            form_data.append(key, file);
          }
        } else if (inputs[key] instanceof Date) {
          form_data.append(key, inputs[key].toISOString());
        } else if (inputs[key] instanceof Array) {
          if (inputs[key].length === 0) break;
          if (inputs[key][0] instanceof File) {
            for (const file of inputs[key]) {
              form_data.append(key, file);
            }
            break;
          }
          form_data.append(key, JSON.stringify(inputs[key]));
          // for (const item of inputs[key]) {
          //   form_data.append(key, item);
          // }
        } else {
          form_data.append(key, JSON.stringify(inputs[key]));
        }
        break;
      case 'function':
      case 'string':
      case 'number':
      case 'bigint':
      case 'boolean':
      case 'symbol':
      default:
        form_data.append(key, inputs[key]);
        break;
    }
  }
  if (callback) {
    callback(form_data);
  }
  return form_data;
}

import { Button } from '@mui/material';
import './warranty-buy-banner.css';
import { HashLink } from 'react-router-hash-link';
import { useLanguage } from '../../../language/useLanguage';

export default function BuyWarrantyBanner() {
  const langData = useLanguage();
  return (
    <div className="BuyWarrantyBanner-container">
      <div className="text-container">
        <p style={{ fontSize: '2rem', fontWeight: 600 }}>{langData('ACT NOW – Buy The Extended Warranty')}</p>
        <p
          style={{ fontSize: '1rem' }}
          dangerouslySetInnerHTML={{ __html: langData('Don&apos;t wait for symptoms to escalate; be proactive in safeguarding your tractor&apos;s heart.') }}
        ></p>
        <HashLink to="/warranty/#warranty-form-section">
          <Button variant="contained" color="white" sx={{ minWidth: 120 }}>
            {langData('Buy Now')}
          </Button>
        </HashLink>
      </div>
      <img className="buy-warranty-image" src="/images/warranty-buy-image.png" alt="Buy Warranty" />
    </div>
  );
}

export default function IconSecurePaymentOption({ width = '1em', height = '1em', color = 'var(--clr-primary)', color2 = 'var(--clr-secondary)', ...props }) {
  return (
    <svg className="hoverable" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 74 74" fill="none" {...props}>
      <path d="M32.6936 24.2119V19.9083H6.87197C4.50068 19.9083 2.56836 21.8406 2.56836 24.2119V32.8192H33.6017C33.0035 30.1896 32.6936 27.3579 32.6936 24.2119Z" fill={color2} />
      <path
        d="M71.2497 41.4266C68.3146 45.9497 63.8389 49.7972 57.4265 53.481C56.4409 54.0448 55.3306 54.3375 54.2117 54.3375C53.0927 54.3375 51.9824 54.0448 51.0098 53.4897C44.5974 49.7972 40.1217 45.9454 37.1823 41.4266H2.56836V62.9447C2.56836 65.3203 4.50068 67.2483 6.87197 67.2483H67.1225C69.4981 67.2483 71.4261 65.3203 71.4261 62.9447V41.4266H71.2497ZM21.9346 54.3375H13.3274C12.1396 54.3375 11.1756 53.3735 11.1756 52.1857C11.1756 50.9979 12.1396 50.0339 13.3274 50.0339H21.9346C23.1224 50.0339 24.0864 50.9979 24.0864 52.1857C24.0864 53.3735 23.1224 54.3375 21.9346 54.3375Z"
        fill={color2}
      />
      <path
        d="M70.1249 13.6297L55.0622 7.1743C54.5157 6.9419 53.9089 6.9419 53.3623 7.1743L38.2997 13.6297C37.5121 13.9654 37 14.7443 37 15.6051V24.2123C37 36.0515 41.3768 42.9717 53.1428 49.7499C53.4742 49.9393 53.8443 50.034 54.2144 50.034C54.5845 50.034 54.9547 49.9393 55.286 49.7499C67.0521 42.9889 71.4289 36.0687 71.4289 24.2123V15.6051C71.4289 14.7443 70.9167 13.9654 70.1249 13.6297ZM62.3526 23.4075L53.7453 34.1665C53.3322 34.6744 52.7125 34.9713 52.0626 34.9713C52.0325 34.9713 51.9981 34.9713 51.9723 34.9713C51.288 34.9412 50.6554 34.5883 50.2723 34.0159L45.9687 27.5605C45.3103 26.5707 45.5771 25.2365 46.5669 24.5781C47.5481 23.9239 48.8866 24.1822 49.5493 25.1763L52.2219 29.183L58.9871 20.7221C59.7317 19.7968 61.0873 19.6505 62.0126 20.3864C62.9422 21.1223 63.0885 22.4779 62.3526 23.4075Z"
        fill={color}
      />
    </svg>
  );
}

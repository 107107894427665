import './about-us-details.css';
import { useLanguage } from '../../../language/useLanguage';

export default function AboutDetails() {
  const langData = useLanguage();
  return (
    <>
      <div className="about-us-details-container">
        <div className="about-us-details-des-container">
          <div className="about-us-details-head-container">
            <h5>{langData('About Us')}</h5>
            <p
              dangerouslySetInnerHTML={{
                __html: langData('At Tractor Seva, <b>we aim to revolutionize the tractor service industry</b> by being the pioneers in offering multi-brand tractor service.'),
              }}
            ></p>
            <div className="about-us-details-description-container">
              <p>
                {langData(
                  "Our name reflects our commitment to providing exceptional service that goes beyond expectations. The term 'Seva' embodies our core value of help & lies at the heart of our company's mission. We strive to provide unparalleled  assistance and support to all tractor owners and farmers with  our comprehensive range of service options, suitable for all  your needs.",
                )}
              </p>
              <p>
                {langData(
                  "Whether it's routine maintenance or advanced servicing, we are here to assist you every step of the way. By offering reliable and efficient service, we aim to empower our customers, ensuring their tractors are in optimal condition for increased productivity.",
                )}
              </p>
            </div>
          </div>
        </div>
        <div className="about-us-details-img-container">
          <img className="about-us-details-title-img" src="./images/about-us-industry.png" alt="Tractor Industry" loading="lazy" />
        </div>
      </div>
    </>
  );
}

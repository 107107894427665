export default function IconWarnPending({ width = '1em', height = '1em', color = 'var(--clr-primary)', color2 = 'var(--clr-secondary)', ...props }) {
  return (
    <svg width={width} height={height} viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M45.4062 31.8125C42.7177 31.8125 40.0894 32.6098 37.854 34.1035C35.6185 35.5972 33.8761 37.7202 32.8473 40.2041C31.8184 42.6881 31.5492 45.4213 32.0737 48.0582C32.5982 50.6952 33.8929 53.1173 35.794 55.0185C37.6951 56.9196 40.1173 58.2143 42.7542 58.7388C45.3912 59.2633 48.1244 58.9941 50.6083 57.9652C53.0923 56.9363 55.2153 55.194 56.709 52.9585C58.2027 50.723 59 48.0948 59 45.4062C59 41.801 57.5678 38.3433 55.0185 35.794C52.4692 33.2447 49.0115 31.8125 45.4062 31.8125ZM50.5039 47.1055H45.4062C44.9556 47.1055 44.5234 46.9264 44.2047 46.6078C43.8861 46.2891 43.707 45.8569 43.707 45.4062V40.3086C43.707 39.8579 43.8861 39.4257 44.2047 39.1071C44.5234 38.7884 44.9556 38.6094 45.4062 38.6094C45.8569 38.6094 46.2891 38.7884 46.6078 39.1071C46.9264 39.4257 47.1055 39.8579 47.1055 40.3086V43.707H50.5039C50.9546 43.707 51.3868 43.886 51.7054 44.2047C52.0241 44.5234 52.2031 44.9556 52.2031 45.4062C52.2031 45.8569 52.0241 46.2891 51.7054 46.6078C51.3868 46.9264 50.9546 47.1055 50.5039 47.1055Z"
        fill={color}
      />
      <path
        d="M27.5372 3.65011C27.104 2.84933 26.4626 2.18038 25.6807 1.71393C24.8988 1.24747 24.0055 1.00081 23.095 1C22.1846 0.99919 21.2908 1.24425 20.5081 1.70931C19.7253 2.17437 19.0827 2.84217 18.6481 3.64218L1.66496 34.21C1.22946 34.9618 1.00009 35.8152 1 36.684C1.0015 38.0356 1.53905 39.3313 2.49472 40.287C3.45039 41.2426 4.74613 41.7802 6.09765 41.7817H28.5273C29.8572 35.2612 34.9436 30.1137 41.4108 28.6411L27.5372 3.65011ZM23.0898 31.5864C22.7538 31.5864 22.4252 31.4867 22.1458 31.3C21.8664 31.1133 21.6486 30.8479 21.52 30.5374C21.3913 30.2269 21.3577 29.8853 21.4233 29.5557C21.4888 29.226 21.6507 28.9233 21.8883 28.6856C22.1259 28.448 22.4287 28.2862 22.7583 28.2206C23.0879 28.155 23.4296 28.1887 23.7401 28.3173C24.0506 28.4459 24.316 28.6637 24.5027 28.9431C24.6894 29.2226 24.789 29.5511 24.789 29.8872C24.789 30.3378 24.61 30.77 24.2914 31.0887C23.9727 31.4074 23.5405 31.5864 23.0898 31.5864ZM24.789 23.0903C24.789 23.541 24.61 23.9732 24.2914 24.2918C23.9727 24.6105 23.5405 24.7895 23.0898 24.7895C22.6392 24.7895 22.207 24.6105 21.8883 24.2918C21.5696 23.9732 21.3906 23.541 21.3906 23.0903V16.2934C21.3906 15.8428 21.5696 15.4106 21.8883 15.0919C22.207 14.7732 22.6392 14.5942 23.0898 14.5942C23.5405 14.5942 23.9727 14.7732 24.2914 15.0919C24.61 15.4106 24.789 15.8428 24.789 16.2934V23.0903Z"
        fill={color2}
      />
    </svg>
  );
}

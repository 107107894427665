import { styled } from '@mui/material';

export const OurOfferingContainer = styled('div')({
  padding: '1rem var(--padding-inline) 4rem',
  marginInline: 'calc(0px - var(--padding-inline))',
  ' @media only screen and (max-width: 600px)': {
    padding: '1rem var(--padding-inline) 2rem',
  },
});

export const OurOfferingWrapper = styled('div')({
  display: 'flex',
  gap: '3rem',
  flexWrap: 'wrap',
  // justifyContent: "space-between",
  width: 'inherit',
  ' div.offering-content': {
    // flexBasis: "214px",
    flexGrow: 1,
    flexBasis: 80,
    paddingTop: 24,
    ' div:nth-of-type(2)': {
      display: 'flex',
      flexDirection: 'column',
      gap: 12,
      marginTop: 18,
    },
    h2: {
      color: 'var(--clr-primary)',
      fontSize: '3rem',
      fontWeight: 500,
    },
    p: {
      lineHeight: '1.4rem',
      fontSize: 14,
      fontWeight: 400,
    },
    ' @media only screen and (max-width: 600px)': {
      h2: {
        fontSize: '2rem',
      },
    },
  },
  ' div.offering-cards': {
    display: 'flex',
    flexBasis: '700px',
    flexGrow: 1,
    gap: '3rem',
  },
  ' @media only screen and (max-width: 600px)': {
    ' div.offering-content': {
      flexBasis: '290px',
    },
    ' div:nth-of-type(2)': {
      display: 'flex',
      flexWrap: 'wrap',
    },
  },
});

export const OfferingCard = styled('div')({
  padding: '1.5rem',
  // flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  flexBasis: '324px',
  transition: 'background-color 0.3s ease',
  '&:hover': {
    backgroundColor: '#F2F7FF',
    borderRadius: 8,
    p: {
      color: '#002852',
    },
    a: {
      p: {
        color: '#ff4c4c',
      },
    },
  },
  h3: { fontSize: '1.5rem', fontWeight: 500 },
  p: {
    lineHeight: '1.3rem',
    fontSize: 17,
    fontWeight: 400,
    color: '#6D7D93',
  },
  a: {
    marginTop: 'auto',
    color: '#ff4c4c',
    textDecoration: 'none',
    display: 'inline-flex',
    alignItems: 'center',
    gap: 4,
    p: {
      color: '#ff4c4c',
      textDecoration: 'none',
      fontWeight: 500,
      fontSize: 18,
    },
  },
  ' @media (max-width: 600px)': {
    flexBasis: 'auto',
    padding: '1.5rem',
    // a: {
    //   marginTop: 6,
    // },
  },
});

export const ResponsiveDiv = styled('div')({
  '  @media (max-width: 768px)': {
    marginTop: '0rem',
  },
});

export const CardContent = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
  marginBottom: 48,
  ' @media only screen and (max-width: 600px)': {
    marginBottom: 0,
  },
});

export default function IconOnlineBooking({ width = '1em', height = '1em', color = 'var(--clr-primary)', color2 = 'var(--clr-secondary)', ...props }) {
  return (
    <svg className="hoverable" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 74 74" fill="none" {...props}>
      <path
        d="M56.3548 5.02344H17.2928C9.76928 5.02344 3.64844 11.1443 3.64844 18.6678C3.64844 26.2278 9.83291 32.3122 17.2114 32.3122H23.3398V21.6217C23.3398 16.8341 27.2346 12.9391 32.0221 12.9391H32.041C36.8184 12.9493 40.705 16.8443 40.705 21.6217V32.3122H56.3548C63.8783 32.3122 69.999 26.1913 69.999 18.6678C69.999 11.1443 63.8783 5.02344 56.3548 5.02344ZM60.2158 16.7652L52.4403 24.5406C51.6813 25.2998 50.4505 25.2999 49.6913 24.5406L45.8036 20.6529C45.0444 19.8937 45.0444 18.663 45.8036 17.9039C46.5626 17.1447 47.7935 17.1447 48.5526 17.9039L51.0659 20.417L57.4669 14.0161C58.2259 13.257 59.4568 13.257 60.2159 14.0161C60.9749 14.7753 60.9749 16.0061 60.2158 16.7652Z"
        fill={color}
      />
      <path
        d="M49.3288 37.7827L36.8151 36.482V21.6215C36.8151 18.9774 34.6746 16.8324 32.0305 16.8267C29.3784 16.821 27.2254 18.9693 27.2254 21.6215V46.4925H27.1818L22.4488 42.54C20.4524 40.8729 17.4734 41.1855 15.8665 43.2306C14.3255 45.1918 14.623 48.0227 16.538 49.6207L26.145 57.6373H54.4393V43.5694C54.4393 40.6275 52.2481 38.1463 49.3288 37.7827Z"
        fill={color2}
      />
      <path d="M26.1973 63.7281V57.6373V56.9246H54.3833V63.7281H26.1973Z" fill={color2} />
    </svg>
  );
}

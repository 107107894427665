export function IconWatch({ width = '1rem', height = '1rem', color1 = 'var(--clr-secondary)', color2 = 'var(--clr-primary)', ...props }) {
  return (
    <svg width={width} height={height} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M6.99985 2.1001C6.03073 2.1001 5.08336 2.38748 4.27756 2.9259C3.47176 3.46431 2.84372 4.22959 2.47285 5.12495C2.10198 6.0203 2.00494 7.00553 2.19401 7.95604C2.38308 8.90654 2.84975 9.77964 3.53503 10.4649C4.22031 11.1502 5.0934 11.6169 6.04391 11.8059C6.99442 11.995 7.97964 11.898 8.875 11.5271C9.77036 11.1562 10.5356 10.5282 11.0741 9.72239C11.6125 8.91659 11.8999 7.96922 11.8999 7.0001C11.8985 5.70095 11.3818 4.45541 10.4632 3.53677C9.54454 2.61814 8.299 2.10146 6.99985 2.1001ZM9.23052 8.68943C9.18708 8.74445 9.1318 8.78897 9.06878 8.81967C9.00575 8.85038 8.93662 8.86647 8.86652 8.86676C8.76101 8.8676 8.65857 8.83126 8.57719 8.76409L6.71052 7.3641C6.65552 7.32064 6.61102 7.26535 6.58032 7.20233C6.54962 7.13931 6.53351 7.07019 6.53319 7.0001V3.73343C6.53319 3.60966 6.58235 3.49096 6.66987 3.40345C6.75739 3.31593 6.87609 3.26676 6.99985 3.26676C7.12362 3.26676 7.24232 3.31593 7.32984 3.40345C7.41735 3.49096 7.46652 3.60966 7.46652 3.73343V6.7761L9.15585 8.03609C9.20441 8.07354 9.24497 8.12035 9.27511 8.17375C9.30525 8.22715 9.32437 8.28605 9.33133 8.34697C9.33829 8.4079 9.33296 8.4696 9.31564 8.52842C9.29833 8.58724 9.26938 8.64199 9.23052 8.68943Z"
        fill={color1}
      />
      <path
        d="M7 0C5.61553 0 4.26215 0.410543 3.11101 1.17971C1.95987 1.94888 1.06266 3.04213 0.532846 4.32121C0.00303306 5.6003 -0.13559 7.00776 0.134506 8.36563C0.404603 9.7235 1.07129 10.9708 2.05025 11.9497C3.02922 12.9287 4.2765 13.5954 5.63437 13.8655C6.99223 14.1356 8.3997 13.997 9.67878 13.4672C10.9579 12.9373 12.0511 12.0401 12.8203 10.889C13.5895 9.73784 14 8.38447 14 7C13.9978 5.14416 13.2596 3.36498 11.9473 2.05271C10.635 0.740435 8.85583 0.002223 7 0ZM7 12.8646C5.84009 12.8646 4.70623 12.5206 3.7418 11.8762C2.77738 11.2318 2.0257 10.3159 1.58182 9.24428C1.13794 8.17267 1.0218 6.99349 1.24809 5.85587C1.47438 4.71825 2.03292 3.67328 2.8531 2.8531C3.67328 2.03292 4.71825 1.47437 5.85587 1.24809C6.99349 1.0218 8.17267 1.13794 9.24428 1.58182C10.3159 2.02569 11.2318 2.77737 11.8762 3.7418C12.5206 4.70623 12.8646 5.84009 12.8646 7C12.8631 8.55493 12.2448 10.0458 11.1453 11.1453C10.0458 12.2448 8.55493 12.8631 7 12.8646Z"
        fill={color2}
      />
    </svg>
  );
}

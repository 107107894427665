import { Helmet } from 'react-helmet-async';

export default function ServiceAgreementSEO() {
  const host_url = process.env.REACT_APP_HOST_URL;

  return (
    <Helmet>
      <title>Service Agreement | Tractor Seva</title>
      <meta property="og:title" content="Service Agreement | Tractor Seva" />
      <meta
        property="og:description"
        content="The Tractor Seva tool has been designed to facilitate Users in discovering and connecting with workshops that specialize in servicing heavy vehicles."
      />
      <meta
        name="description"
        content="The Tractor Seva tool has been designed to facilitate Users in discovering and connecting with workshops that specialize in servicing heavy vehicles."
      />
      <link rel="canonical" href={host_url + '/service-agreement'} />
    </Helmet>
  );
}

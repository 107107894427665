export default function IconInstagram({ width = '1em', height = '1em', color = '#fff', ...props }) {
  return (
    <svg width={width} height={height} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="35" height="35" rx="17.5" stroke={color} />
      <path
        d="M10.8008 17.2008C10.8008 14.1838 10.8008 12.6753 11.738 11.738C12.6753 10.8008 14.1838 10.8008 17.2008 10.8008H18.8008C21.8177 10.8008 23.3263 10.8008 24.2635 11.738C25.2008 12.6753 25.2008 14.1838 25.2008 17.2008V18.8008C25.2008 21.8177 25.2008 23.3263 24.2635 24.2635C23.3263 25.2008 21.8177 25.2008 18.8008 25.2008H17.2008C14.1838 25.2008 12.6753 25.2008 11.738 24.2635C10.8008 23.3263 10.8008 21.8177 10.8008 18.8008V17.2008Z"
        stroke={color}
        strokeWidth="2"
      />
      <path
        d="M21.6004 15.6012C22.2631 15.6012 22.8004 15.0639 22.8004 14.4012C22.8004 13.7384 22.2631 13.2012 21.6004 13.2012C20.9376 13.2012 20.4004 13.7384 20.4004 14.4012C20.4004 15.0639 20.9376 15.6012 21.6004 15.6012Z"
        fill={color}
      />
      <path
        d="M18.0016 20.3996C19.327 20.3996 20.4016 19.3251 20.4016 17.9996C20.4016 16.6741 19.327 15.5996 18.0016 15.5996C16.6761 15.5996 15.6016 16.6741 15.6016 17.9996C15.6016 19.3251 16.6761 20.3996 18.0016 20.3996Z"
        stroke={color}
        strokeWidth="2"
      />
    </svg>
  );
}

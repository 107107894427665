import IconTagCircle from '../../assets/icons/icon-common/icon-tag-circle';
import './tags.css';

export function TagGreen({ text = 'Active', ...props }) {
  return (
    <div className="tag-container tag-green" {...props}>
      <IconTagCircle />
      <div>{text}</div>
    </div>
  );
}

export function TagRed({ text = 'Inactive', ...props }) {
  return (
    <div className="tag-container tag-red" {...props}>
      <IconTagCircle />
      <div>{text}</div>
    </div>
  );
}

export function TagInfo({ text = 'Info', ...props }) {
  return (
    <div className="tag-container tag-info" {...props}>
      <div>{text}</div>
    </div>
  );
}

export function TagCompleted({ text = 'Completed', ...props }) {
  return (
    <div className="tag-container tag-completed" {...props}>
      <div>{text}</div>
    </div>
  );
}

export function TagOngoing({ text = 'Ongoing', ...props }) {
  return (
    <div className="tag-container tag-ongoing" {...props}>
      <div>{text}</div>
    </div>
  );
}

export function TagPending({ text = 'Pending', ...props }) {
  return (
    <div className="tag-container tag-pending" {...props}>
      <div>{text}</div>
    </div>
  );
}

export function TagUpcoming({ text = 'Upcoming', ...props }) {
  return (
    <div className="tag-container tag-upcoming" {...props}>
      <div>{text}</div>
    </div>
  );
}

export function TagCancelled({ text = 'Cancelled', ...props }) {
  return (
    <div className="tag-container tag-cancelled" {...props}>
      <div>{text}</div>
    </div>
  );
}

export function TagStarted({ text = 'Started', ...props }) {
  return (
    <div className="tag-container tag-started" {...props}>
      <div>{text}</div>
    </div>
  );
}

export function TagTotal({ text = 'Total', ...props }) {
  return (
    <div className="tag-container tag-total" {...props}>
      <div>{text}</div>
    </div>
  );
}

export function TagProcessing({ text = 'Processing', ...props }) {
  return (
    <div className="tag-container tag-processing" {...props}>
      <div>{text}</div>
    </div>
  );
}

export function TagPartial({ text = 'Partial Delivered', ...props }) {
  return (
    <div className="tag-container tag-partial" {...props}>
      <div>{text}</div>
    </div>
  );
}

export function GetPaymentStatusTag({ status }) {
  switch (status) {
    case 'pending':
      return <TagPending text="Unpaid" />;
    case 'processing':
      return <TagOngoing text="Processing" />;
    case 'completed':
      return <TagCompleted text="Paid" />;
    case 'cancelled':
      return <TagCancelled text="Cancelled" />;
    case 'aborted':
      return <TagCancelled text="Aborted" />;
    case 'failure':
      return <TagCancelled text="Failed" />;
    default:
      console.error(`Unexpected Progress Status: ${status}`);
      return <TagPending text={status} />;
  }
}

export function GetBookingTags({ status }) {
  switch (status) {
    case 'pending':
      return <TagPending text="Pending" />;
    case 'partial':
      return <TagPartial text="Partial" />;
    case 're-schedule':
      return <TagPartial text="Re-schedule" />;
    case 'upcoming':
      return <TagUpcoming text="Upcoming" />;
    case 'ongoing':
      return <TagOngoing text="Ongoing" />;
    case 'completed':
      return <TagCompleted text="Completed" />;
    case 'cancelled':
      return <TagCancelled text="Cancelled" />;
    case 'payment-pending':
      return <TagCancelled text="Payment Pending" />;
    case 'payment-failed':
      return <TagCancelled text="Payment Failed" />;
    default:
      console.error(`Unexpected Progress Status: ${status}`);
      return <TagPending text={status} />;
  }
}

export function SectionTagGrey({ text = 'Section Tag', ...props }) {
  return (
    <div className="tag-container tag-section" {...props}>
      <div>{text}</div>
    </div>
  );
}

export function SectionTagTransparent({ text = 'Section Tag', ...props }) {
  return (
    <div className="tag-container tag-transparent" {...props}>
      <div>{text}</div>
    </div>
  );
}

export function GetOrderTags({ status }) {
  switch (status) {
    case 'in-approval':
      return <TagProcessing text="In Approval" />;
    case 'upcoming':
      return <TagUpcoming text="Upcoming" />;
    case 'cancelled':
      return <TagCancelled text="Cancelled" />;
    case 'pending':
      return <TagPending text="Pending" />;
    case 'ongoing':
      return <TagOngoing text="Ongoing" />;
    case 'partial':
      return <TagPartial text="Partial" />;
    case 'completed':
      return <TagCompleted text="Delivered" />;
    case 'payment-pending':
      return <TagCancelled text="Payment Pending" />;
    default:
      console.error(`Unexpected Tag Value Found : ${status}`);
      return <TagPending text={status} />;
  }
}

export default function IconHamburger({ width = '1em', height = '1em', color = 'var(--bg-secondary)', ...props }) {
  return (
    <svg width={width} height={height} viewBox="0 0 19 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M1 1H18" stroke={color} strokeWidth="1.5" strokeLinecap="round" />
      <path d="M1 6H18" stroke={color} strokeWidth="1.5" strokeLinecap="round" />
      <path d="M1 11H18" stroke={color} strokeWidth="1.5" strokeLinecap="round" />
      {/* <path d="M8.87165 4.14949C9.05014 3.971 9.33951 3.971 9.51799 4.14949C9.69648 4.32797 9.69648 4.61734 9.51799 4.79583L6.77581 7.53801C6.60278 7.71104 6.32418 7.71709 6.14381 7.55175L3.40162 5.03807C3.21556 4.86751 3.20299 4.57841 3.37355 4.39234C3.54411 4.20628 3.83321 4.19371 4.01928 4.36427L6.43889 6.58225L8.87165 4.14949Z" fill={color} /> */}
    </svg>
  );
}

import { Alert, Snackbar } from '@mui/material';
import { useEffect } from 'react';
import { promptActions } from '../../context/prompt';

export default function Prompt({ data, dispatchFn }) {
  useEffect(() => {
    console.debug(data);
  }, [data]);

  return (
    <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={data?.length > 0 ?? false}>
      <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
        {data?.map((prompt) => (
          <CustAlert key={prompt.id} data={prompt} dispatchFn={dispatchFn} />
        ))}
      </div>
    </Snackbar>
  );
}

function CustAlert({ data, dispatchFn }) {
  useEffect(() => {
    const timer = setTimeout(() => dispatchFn({ type: promptActions.HIDE_PROMPT, payload: data.id }), data.timer);
    return () => clearTimeout(timer);
  }, [data.id, data.timer, dispatchFn]);

  return (
    <Alert onClose={() => dispatchFn({ type: promptActions.HIDE_PROMPT, payload: data.id })} severity={data.type}>
      {data.message}
    </Alert>
  );
}

import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../../context/authContext';
import { useLanguage } from '../../../language/useLanguage';
import IconTimeout from '../../../assets/icons/icon-common/icon-timeout';
import { Button } from '@mui/material';
import { Fragment, useState } from 'react';
import IconCircleCheck from '../../../assets/icons/icon-common/icon-circle-check';
import { useDispatch, useSelector } from 'react-redux';
import {
  setIsAppointmentModelOpen,
  setSelectedService,
  setSelectServicePriceOffer,
  setSelectServicePriceTotal,
  setServiceId,
} from '../../../redux/features/customer-order/customerOrderSlice';
import IconNoTractors from '../../../assets/icons/icon-common/icon-no-tractors';
import { ServiceNoFound } from './styled';
import IconAdded from '../../../assets/icons/icon-common/icon-added';

export function TractorServices({ serviceTypeListTrial, sectionsRef, path, customerTractors }) {
  const SERVICE_TYPE_IMAGE_PATH = ['/icons/service-basic.svg', '/icons/service-standard.svg', '/icons/service-premium.svg'];

  const SERVICE_TASK_LIST = [
    [
      {
        id: 'BASIC-1',
        task_order: 1,
        Task: { task_details: '15W40 CH4 Engine Oil Change' },
      },
      {
        id: 'BASIC-2',
        task_order: 2,
        Task: { task_details: 'Bosch Oil Filter Change' },
      },
      {
        id: 'BASIC-3',
        task_order: 3,
        Task: { task_details: 'Bosch Diesel Filter Change' },
      },
      { id: 'BASIC-4', task_order: 4, Task: { task_details: 'Greasing' } },
      { id: 'BASIC-5', task_order: 5, Task: { task_details: 'Key Chain' } },
      { id: 'BASIC-6', task_order: 6, Task: { task_details: 'Sticker' } },
      {
        id: 'BASIC-7',
        task_order: 7,
        Task: { task_details: 'Air Pressure Check' },
      },
    ],
    [
      {
        id: 'STANDARD-1',
        task_order: 1,
        Task: { task_details: '15W40 CH4 Engine Oil Change' },
      },
      {
        id: 'STANDARD-2',
        task_order: 2,
        Task: { task_details: 'Bosch Oil Filter Change' },
      },
      {
        id: 'STANDARD-3',
        task_order: 3,
        Task: { task_details: 'Bosch Diesel Filter Change' },
      },
      {
        id: 'STANDARD-4',
        task_order: 4,
        Task: { task_details: 'Bosch Air Filter Change' },
      },
      { id: 'STANDARD-5', task_order: 5, Task: { task_details: 'Top Wash' } },
      { id: 'STANDARD-6', task_order: 6, Task: { task_details: 'Greasing' } },
      { id: 'STANDARD-7', task_order: 7, Task: { task_details: 'Key Chain' } },
      { id: 'STANDARD-8', task_order: 8, Task: { task_details: 'Sticker' } },
      {
        id: 'STANDARD-9',
        task_order: 9,
        Task: { task_details: 'Air Pressure Check' },
      },
    ],
    [
      {
        id: 'PREMIUM-1',
        task_order: 1,
        Task: { task_details: 'Premium 15W40 CI4 Plus Engine Oil Change' },
      },
      {
        id: 'PREMIUM-2',
        task_order: 2,
        Task: { task_details: 'Bosch Oil Filter Change' },
      },
      {
        id: 'PREMIUM-3',
        task_order: 3,
        Task: { task_details: 'Bosch Diesel Filter Change' },
      },
      {
        id: 'PREMIUM-4',
        task_order: 4,
        Task: { task_details: 'Bosch Air Filter Change' },
      },
      { id: 'PREMIUM-5', task_order: 5, Task: { task_details: 'Top Wash' } },
      { id: 'PREMIUM-6', task_order: 6, Task: { task_details: 'Greasing' } },
      { id: 'PREMIUM-7', task_order: 7, Task: { task_details: 'Key Chain' } },
      { id: 'PREMIUM-8', task_order: 8, Task: { task_details: 'Sticker' } },
      { id: 'PREMIUM-9', task_order: 9, Task: { task_details: 'Tyre Polish' } },
      {
        id: 'PREMIUM-10',
        task_order: 10,
        Task: { task_details: 'Hydraulic Oil Check' },
      },
      {
        id: 'PREMIUM-11',
        task_order: 11,
        Task: { task_details: 'Air Pressure Check' },
      },
    ],
    [
      {
        id: 'TSServiceKit-1',
        task_order: 1,
        Task: { task_details: '15W40 Engine oil' },
      },
      {
        id: 'TSServiceKit-2',
        task_order: 2,
        Task: { task_details: 'Bosch Engine oil Filter' },
      },
      {
        id: 'TSServiceKit-3',
        task_order: 3,
        Task: { task_details: 'Bosch Disel Filter' },
      },
      {
        id: 'TSServiceKit-4',
        task_order: 4,
        Task: { task_details: 'Bosch Air Filter' },
      },
    ],
  ];
  const tractor_model_id = useSelector((state) => state.customer_order.tractorModelId);
  const tractor_brand_id = useSelector((state) => state.customer_order.tractorModelId);

  const customer_tractors = customerTractors?.data?.length > 0 ? customerTractors.data.filter((tractor) => tractor.TractorModel.id === tractor_model_id) : [];
  console.debug(customer_tractors);

  const customer_register_tractors = new Set(customer_tractors.map((tr) => tr.id));
  console.debug(customer_register_tractors);
  const cart_items = useSelector((state) => state.cart.cartItems);
  const customer_tractors_in_cart = cart_items
    ?.filter((cart) => cart.service_id !== null && cart.CustomerTractor.TractorModel.id === tractor_model_id)
    .map((x) => x.customer_tractor_id);
  const services_in_cart = cart_items?.filter((cart) => cart.service_id !== null && cart.CustomerTractor.TractorModel.id === tractor_model_id).map((x) => x.service_id);
  const cus_tractor_set = new Set(customer_tractors_in_cart);

  const tractors_occupied =
    customer_tractors?.length > 0 ? customer_register_tractors.size === cus_tractor_set.size && [...customer_register_tractors].every((x) => cus_tractor_set.has(x)) : false;
  return (
    <div className="service-container">
      {serviceTypeListTrial?.data?.data && serviceTypeListTrial?.data?.data?.length > 0 ? (
        <>
          <div className="service-container-heading" style={{ padding: '1.5rem 0 1.5rem' }}>
            <h1 style={{ fontSize: 36, fontWeight: 600 }} ref={(el) => (sectionsRef.current[0] = el)}>
              Book a Service
            </h1>
          </div>
          <div className="service-card-container" style={{ margin: 0 }}>
            {serviceTypeListTrial?.data.data.map((st, i) => (
              <ServiceCard
                key={st.id}
                imagePath={st.service_type_img ?? SERVICE_TYPE_IMAGE_PATH[i] ?? SERVICE_TYPE_IMAGE_PATH[0]}
                title={`
            ${st.service_type_name ?? 'N/A'}
          
            `}
                time={st.time_req_min}
                service={st.Items?.[0]?.Service ?? {}}
                serviceDesc={st.service_type_desc}
                serviceType={st.service_type_name}
                serviceTasks={st.Items?.[0]?.Service?.ServiceTasks ?? SERVICE_TASK_LIST[i]}
                model_id={tractor_model_id}
                brand_id={tractor_brand_id}
                path={path}
                isKitAvailable={st.Items?.[0]?.isKitAvailable ?? false}
                tractors_occupied={tractors_occupied}
                services_in_cart={services_in_cart}
              />
            ))}
          </div>
        </>
      ) : (
        <ServiceNoFound>
          <div className="no-service-tractor">
            <IconNoTractors />
          </div>
          <p className="no-service-title">No Service/Kit/Parts & Accessories Available for This Tractor</p>
          <p className="no-service-des">Change the location or change the tractor to see available services and products</p>
        </ServiceNoFound>
      )}
    </div>
  );
}

export function ServiceCard({
  imagePath = 'icons/service-basic.svg',
  title = 'Basic Service',
  time = 'N/A',
  service,
  serviceDesc,
  serviceTasks,
  model_id,
  service_id,
  onSelectTractor,
  serviceType,
  path,
  isKitAvailable,
  tractors_occupied,
  services_in_cart,
}) {
  const navigate = useNavigate();
  const { getData } = useAuthContext();
  const { isAuth } = getData();
  const langData = useLanguage();
  const mrpPrice = service?.mrp_total;
  const offerPrice = service?.offer_total;
  const dispatch = useDispatch();
  const bookServiceHandler = (service, serviceType) => {
    dispatch(setServiceId(service.id));
    dispatch(setSelectedService(`${serviceType} Service`));
    dispatch(setSelectServicePriceOffer(service?.offer_total ?? ''));
    dispatch(setSelectServicePriceTotal(service?.mrp_total ?? ''));
    dispatch(setIsAppointmentModelOpen(true));
  };

  const inCart = isAuth && tractors_occupied ? services_in_cart?.includes(service?.id) : false;

  return (
    <div className="service-card" style={{ scrollMarginTop: 96, opacity: !isKitAvailable && isAuth ? 0.75 : 1, position: 'relative' }}>
      <div className="image-container">
        <img src={imagePath} alt={imagePath} />
      </div>
      <div className="card-title">{langData(title)}</div>
      <p className="card-subtitle">{langData(serviceDesc)}</p>
      <span className="tag">
        <div className="icon">
          <IconTimeout />
        </div>
        <div className="text">
          {time} {langData('Mins')}
        </div>
      </span>
      <TaskList tasks={serviceTasks?.sort((a, b) => a.task_order - b.task_order)} />
      <div className="card-btn">
        {isAuth ? (
          <>
            {!model_id && (
              <Button variant="contained" onClick={onSelectTractor}>
                {langData('+ Select Tractor')}
              </Button>
            )}
            {model_id && service_id !== service?.id && (
              <Button
                variant={'contained'}
                disabled={!isKitAvailable}
                sx={{
                  ...((inCart || !isKitAvailable) && {
                    '&&.MuiButton-root.MuiButtonBase-root.MuiButton-outlined': {
                      backgroundColor: '#fff',
                      color: '#002242',
                      cursor: 'no-drop',
                    },
                    '&&.MuiButton-root.MuiButtonBase-root.MuiButton-contained.Mui-disabled': {
                      backgroundColor: '#002852',
                      color: '#fff',
                    },
                  }),
                }}
                onClick={() => {
                  bookServiceHandler(service, serviceType);
                }}
                // startIcon={inCart && <IconAdded />}
              >
                {langData('Book Service')}
              </Button>
            )}
            {model_id && service_id === service?.id && (
              <Button
                variant={inCart ? 'outlined' : 'contained'}
                disabled={!isKitAvailable}
                sx={{
                  ...(inCart && {
                    '&&.MuiButton-root.MuiButtonBase-root.MuiButton-outlined': {
                      backgroundColor: '#fff',
                      color: '#002242',
                    },
                  }),
                }}
              >
                {langData('Book Service')}
              </Button>
            )}
          </>
        ) : (
          <Button
            variant="contained"
            onClick={() =>
              navigate('/login', {
                state: {
                  redirectTo: `${path}`,
                },
              })
            }
          >
            {langData('Login For Best Price')}
          </Button>
        )}
        {isAuth && (
          <div>
            {mrpPrice && <span className="crossed">{`₹ ${mrpPrice}`}</span>}
            {offerPrice && <span>{`₹ ${offerPrice}`}</span>}
          </div>
        )}
      </div>
      {!isKitAvailable && isAuth ? <p className="service__unavailable">{langData('Not available at your location')}</p> : ''}
      {inCart && <p className="service__unavailable">{langData('Added')}</p>}
    </div>
  );
}
export function TaskList({ tasks = [] }) {
  const [open, setOpen] = useState(false);
  const toggleShow = () => setOpen((prev) => !prev);
  const langData = useLanguage();
  const allowLength = 5;
  const isExceed = tasks.length > allowLength;
  const taskData = isExceed && !open ? tasks.slice(0, allowLength) : tasks;
  return (
    <div className="task-list">
      {taskData.map((task) => (
        <Fragment key={task.id}>
          <div>
            <IconCircleCheck />
          </div>
          <p>{langData(task.Task.task_details)}</p>
        </Fragment>
      ))}
      {isExceed && (
        <div className="action-text" onClick={toggleShow}>
          {open ? '- Show Less' : '+ Show More'}
        </div>
      )}
    </div>
  );
}

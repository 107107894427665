import { Controller, useFormContext } from 'react-hook-form';
import { useBookingContext } from '../booking-add';
import { useGetCustomerAddressDDList, useServiceLocation } from '../booking-api';
import { FormControlLabel, Radio, RadioGroup, Tooltip } from '@mui/material';
import IconPlusRound from '../../../assets/icons/icon-common/icon-plus-round';
import { AddAddressModel } from '../../profile/components/my-address';
import IconTooltip from '../../../assets/icons/icon-common/icon-tooltip';
import { useState } from 'react';
import { useLanguage } from '../../../language/useLanguage';

export default function FormAddressPreference() {
  const { watch, control, formState } = useFormContext();
  const { errors } = formState;
  const { setBookingDataSummary } = useBookingContext();
  const [customer_id] = watch(['customer_id']);
  const dropdownAddressList = useGetCustomerAddressDDList(customer_id);
  const { data: finalService } = useServiceLocation();
  const [add, setAdd] = useState(false);
  const handleAddClose = () => setAdd(false);
  const langData = useLanguage();

  function addressChange(event, value, changeValue) {
    // console.debug(value);
    const customerDetails = value ? dropdownAddressList.data?.find((cust) => cust.id == value) : null;

    setBookingDataSummary((prev_state) => {
      const newState = [...prev_state];
      newState[0] = <AddressData key="address_data" value={customerDetails} />;
      return newState;
    });

    changeValue(value);
  }

  function handleChangeServicePref(event, onChangeFunction) {
    onChangeFunction(event.target.value);
    const servicePref = <ServicePref value={finalService?.find((a) => a.id == event.target.value)} />;
    setBookingDataSummary((prev) => {
      const newState = [...prev];
      newState[2] = servicePref;
      return newState;
    });
  }
  if (dropdownAddressList.isLoading) return <AddressLoading />;
  return (
    <div className="form-wrapper choose-tractor" style={{ maxWidth: 'unset' }}>
      <h1 className="required">Select Address</h1>
      {dropdownAddressList.data.length > 0 ? (
        <div className="field-container">
          <Controller
            name="customer_address_id"
            rules={{ required: langData('Select Address') }}
            control={control}
            render={(props) => {
              return (
                <RadioGroup
                  {...props.field}
                  row
                  style={{
                    display: 'grid',
                    gap: 16,
                    // gridTemplateColumns: "repeat(auto-fit, minmax(200px, 300px))"
                  }}
                  onChange={(e, value) => addressChange(e, value, props.field.onChange)}
                >
                  {dropdownAddressList.data?.map((addressData) => (
                    <FormControlLabel key={addressData.id} value={addressData.id} style={{ margin: 0 }} control={<AddressCard addressData={addressData} />} />
                  ))}
                </RadioGroup>
              );
            }}
          />
          <p className="form-error-text">{errors.customer_address_id?.message}</p>
        </div>
      ) : (
        <NoAddress />
      )}
      <div className="add-step" onClick={() => setAdd(true)}>
        <span className="icon">
          <IconPlusRound />
        </span>
        <div className="text">{langData('Add New Address')}</div>
      </div>

      <div className="field-container">
        <label className="required" style={{ fontSize: 20, marginTop: 16 }}>
          <b>{langData('What would you prefer?')}</b>
        </label>
        <Controller
          rules={{ required: 'Select Service Location' }}
          control={control}
          name="service_location"
          render={(props) => {
            return (
              <RadioGroup row {...props.field} onChange={(event) => handleChangeServicePref(event, props.field.onChange)} sx={{ '& label': { alignItems: 'flex-start' } }}>
                {finalService?.map((a) => (
                  <FormControlLabel
                    key={a.id}
                    value={a.id}
                    control={
                      <RadioSelect
                        label={langData(a.label)}
                        toolTipText={`Charges: ₹ ${a.charges}`}
                        // infoText={a.status === 1 ? "Service available" : "Service not available"}
                        infoText={
                          <p style={{ fontSize: 14, color: 'var(--clr-gray)', fontWeight: 500 }}>
                            {langData('Charge will be:')} <span style={{ color: 'var(--clr-primary)' }}>₹ {a.charges}</span>
                          </p>
                        }
                        icon={a.icon}
                      />
                    }
                  />
                ))}
              </RadioGroup>
            );
          }}
        />
        <p className="form-error-text">{errors.service_location?.message}</p>
      </div>

      {add && (
        <AddAddressModel
          open={add}
          onClose={handleAddClose}
          inputs={{
            address_name: '',
            address_1: '',
            address_2: '',
            state_id: null,
            district_id: null,
            sub_district_id: null,
            pincode: '',
          }}
        />
      )}
    </div>
  );
}

function AddressCard({ addressData, ...props }) {
  return (
    <div
      style={{
        backgroundColor: 'var(--bg-blue, #F2F7FF)',
        borderRadius: 8,
        display: 'flex',
        // flexDirection: "column",
        alignItems: 'flex-start',
        // justifyContent: "top",
        padding: '16px',
        width: '100%',
        gap: '0.5rem',
      }}
    >
      <Radio
        {...props}
        color="secondary"
        // style={{ paddingLeft: 0 }}
      />
      <div className="address-details">
        <h3>{addressData.address_name}</h3>
        <div>{addressData.label}</div>
      </div>
    </div>
  );
}

function RadioSelect({ label = '', infoText, toolTipText, icon, ...props }) {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
        <Radio color="secondary" {...props} />
        <img src={icon} />
        <div>{label}</div>
        {toolTipText && (
          <Tooltip className="tooltip" title={toolTipText} placement="bottom-start" arrow>
            <span style={{ fontSize: 18, display: 'flex' }}>
              <IconTooltip />
            </span>
          </Tooltip>
        )}
      </div>
      {infoText && <div style={{ marginLeft: 50 }}>{infoText}</div>}
    </div>
  );
}

function AddressData({ value }) {
  const langData = useLanguage();
  return (
    <div className="address">
      <p className="title">{langData('Selected Address')}</p>
      <div className="service-details">
        <p key={1} className="details">
          {langData(value.address_name)} {langData('Address')}
        </p>
        <p key={2} className="details">
          {langData(value.label)}
        </p>
      </div>
    </div>
  );
}

function ServicePref({ value }) {
  const langData = useLanguage();
  return (
    <div key={4} className="address">
      <p className="title">{langData('Service Charge')}</p>
      <div className="service-details">
        <p key={1} className="details">
          {langData(value.label)}
        </p>
        <p key={2} className="details">
          ₹ {value.charges}
        </p>
      </div>
    </div>
  );
}

function AddressLoading() {
  return <p>Loading...</p>;
}

function NoAddress() {
  const langData = useLanguage();
  return (
    <div style={{ textAlign: 'center', width: '100%' }}>
      <img style={{ width: '100%', maxWidth: '400px' }} src="/assets/images/placeholder-no-address.svg" alt="No-Address" />
      <p style={{ fontSize: 20, margin: '16px 0px' }}>{langData('No Address Available')}</p>
    </div>
  );
}

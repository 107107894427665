export const capitalizeFirstLetter = (str) => {
  // Check if the string contains a dash
  if (str.includes('-')) {
    // Case: The string contains a dash (e.g., "payment-pending")
    return str
      .split('-')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join('-'); // Join the words back with dashes
  } else {
    // Case: The string  not contain a dash (e.g., "pending")
    return str.charAt(0).toUpperCase() + str.slice(1); // Capitalize only the first letter
  }
};

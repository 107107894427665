import { useLanguage } from '../../../../language/useLanguage';
import './cancellationandreschedulingpolicy.css';

export default function Cancellationandpolicy() {
  const langData = useLanguage();
  return (
    <>
      <div className="cancellation_main_container">
        <div className="cancellation_container">
          <h1 className="heading_text">{langData('Cancellation')}</h1>
          <p className="para_text">
            <ol type="i">
              <li>
                <p>{langData('You can cancel a service appointment anytime up to 3 hours before the scheduled appointment time.')}</p>
              </li>
              <li>
                <p>{langData('After this period, no refunds will be provided')} </p>
              </li>
            </ol>
          </p>
        </div>
        <div className="cancellation_container">
          <h2 className="heading_text">{langData('Rescheduling:')}</h2>
          <p className="para_text">
            <ol type="i">
              <li>
                <p>{langData('For Workshop Servicing: You have the flexibility to reschedule your appointment at any time before the scheduled day ends.')}</p>
              </li>
              <li>
                <p>{langData('For At-Door Servicing: Appointments can be rescheduled anytime before 3 hours of the scheduled appointment time.')}</p>
              </li>
              <li>
                <p>
                  {langData(
                    "Appointments can be rescheduled within the time frame allowed by 'Tractor Seva' Company policy. If no slots are available for rescheduling within that time frame, the appointment will be automatically canceled, and a refund will be initiated.",
                  )}
                </p>
              </li>
              <li>
                <p>{langData('If there is a User No Show or no rescheduling of the appointment, it will be automatically canceled at the end of the day.')}</p>
              </li>
              <li>
                <p>{langData('Rescheduling cannot be requested once the service has started.')}</p>
              </li>
              <li>
                <p>
                  {langData(
                    "It is important to note that users are allowed to request rescheduling for each appointment a maximum of two times. In other words, once you made your payment and booked an appointment, you can reschedule it at no additional cost up to two times. After these two rescheduling instances, the only available option is to cancel the appointment and request a refund in accordance with the 'company' policies.",
                  )}
                </p>
              </li>
            </ol>
          </p>
        </div>
        <div className="cancellation_container">
          <h2 className="heading_text">{langData('Refund:')}</h2>
          <p className="para_text">
            <ol type="i">
              <li>
                <p>{langData('Upon cancellation, you are responsible for paying the complete processing fees and transaction costs.')}</p>
              </li>
              <li>
                <p>{langData('You will receive a refund of 100% of the remaining amount after deducting processing fees and transaction costs within 7 working days.')}</p>
              </li>
              <li>
                <p>{langData('Refunds will be credited to the same bank account from which the initial payment was received.')}</p>
              </li>
              <li>
                <p>
                  {langData(
                    "Please carefully review our 'Terms of Use' for any additional terms related to your use of 'Tractor Seva' services. If you have any questions or need assistance, please don't hesitate to contact us at ",
                  )}
                  <a href="mailto:customercare@tractorseva.com" className="mail_url">
                    customercare@tractorseva.com
                  </a>
                </p>
              </li>
            </ol>
          </p>
        </div>
      </div>
    </>
  );
}

import { Helmet } from 'react-helmet-async';

export default function AboutUsSEO() {
  const host_url = process.env.REACT_APP_HOST_URL;

  return (
    <Helmet>
      <title>About Us | Tractor Seva</title>
      <meta name="title" content="Tractor Servicing Company: Learn About TractorSeva" />
      <meta name="description" content="At Tractor Seva, we aim to revolutionize the tractor service industry by being the pioneers in offering multi-brand tractor service." />
      <link rel="canonical" href={host_url + '/about-us'} />
    </Helmet>
  );
}

import { Dialog, styled } from '@mui/material';

export const AppointmentDialog = styled(Dialog)({
  // ".MuiDialog-paper": {
  //   minWidth: "60%",
  // },
  '.service-appointment': {
    display: 'flex',
    flexDirectionL: 'row',
    padding: '1rem',
  },
  '.service-container': {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    gap: '1rem',
    padding: '1rem',
    background: '#F2F7FF',
    '.selected-tractor, .selected-service, .selected-workshop': {
      flex: '1 1 200px',
    },
    ' .selected-tractor, .selected-service, .selected-workshop': {
      h5: {
        color: '#6D7D93',
      },
    },
    '.selected-tractor-model': {
      display: 'flex',
      gap: '0.5rem',
      paddingTop: '1.5rem',
    },
    '.selected-service': {
      '.tractor-details': {
        '.offer-price': {
          paddingRight: '0.5rem',
          fontSize: '15px',
          fontWeight: 600,
        },
        '.total-price': {
          textDecoration: 'line-through',
          fontSize: '15px',
          color: '#6D7D93',
          fontWeight: 400,
        },
      },
    },
  },

  '.custom-scrollbar-popup::-webkit-scrollbar': {
    width: '6px' /* Width of the scrollbar */,
  },

  '.custom-scrollbar-popup::-webkit-scrollbar-thumb': {
    backgroundColor: '#002852' /* Color of the scrollbar thumb */,
    borderRadius: '5px' /* Rounded corners of the thumb */,
  },

  '.custom-scrollbar-popup::-webkit-scrollbar-track': {
    backgroundColor: '#f1f1f1' /* Color of the track */,
    borderRadius: '5px' /* Rounded corners of the track */,
  },

  /* For Firefox */
  '.custom-scrollbar-popup': {
    scrollbarWidth: 'thin' /* Make scrollbar thinner */,
    scrollbarColor: '#002852 #f1f1f1' /* thumb color track color */,
  },

  // for tractor added card
  '.pop-card-container': {
    width: '100%',
    '.pop-card-media': {
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',
      '.tractor-names > p': {
        fontWeight: '600',
        fontSize: '18px',
      },
    },
    '.pop-card-details': {
      '.pop-card-content': {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: '0.5rem',
        '.content-head': {
          color: '#6D7D93',
          fontWeight: '500',
        },
        '.content-head-value': {
          fontWeight: '500',
        },
      },
    },
  },
});

// for the tractor model add

export const TractorModelAdd = styled('div')({
  padding: '1rem 2rem',
  '.book-modal-pop > .form-column-2>*': {
    flexBasis: '200px',
    maxWidth: '100%',
    flexGrow: 1,
  },
});

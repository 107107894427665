import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function LocationChangeModel({ isModelOpen, handleAgree, handleDisAgree }) {
  return (
    <React.Fragment>
      <Dialog open={isModelOpen} onClose={handleDisAgree} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{'Want to change location service?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Make sure after change the location your cart will be empty</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDisAgree} variant="contained">
            Disagree
          </Button>
          <Button onClick={handleAgree} autoFocus variant="outlined">
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export function IconCalendar({ width = '1rem', height = '1rem', color1 = 'var(--clr-secondary)', color2 = 'var(--clr-primary)', ...props }) {
  return (
    <svg width={width} height={height} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M13.9993 6.31327V6.50012C13.9993 8.27781 13.9993 10.0555 13.9993 11.8332C13.9993 12.8742 13.3659 13.7077 12.3952 13.9401C12.2138 13.9802 12.0285 13.9994 11.8428 13.9974C8.61801 14.0003 5.39291 14.0003 2.16752 13.9974C1.06587 13.9974 0.22046 13.2934 0.0338577 12.2253C0.0159627 12.1062 0.00834191 11.9859 0.0110797 11.8656C0.00932759 10.0657 0.00932759 8.26614 0.0110797 6.46686C0.0110797 6.41873 0.0154602 6.3706 0.0180884 6.31152L13.9993 6.31327Z"
        fill={color1}
      />
      <path
        d="M11.2031 2.10073C11.441 2.10073 11.6534 2.10073 11.8658 2.10073C13.0923 2.10904 13.9907 3.00956 13.9991 4.23956C13.9991 4.45222 13.9991 4.66444 13.9991 4.88804H0.0257924C-0.0556819 4.02078 0.0231642 3.20909 0.745482 2.6C1.09898 2.30157 1.50459 2.12698 1.96803 2.10729C2.23962 2.09548 2.51207 2.10511 2.80468 2.10511V1.40237C2.80468 1.17834 2.80468 0.953865 2.80468 0.72983C2.80774 0.313703 3.0951 0.0078426 3.48757 0.000841507C3.89188 -0.00615958 4.19544 0.289198 4.2042 0.713639C4.21252 1.10745 4.20639 1.50126 4.20639 1.89507V2.08717H9.80095V1.70692C9.80095 1.37874 9.79657 1.05057 9.80095 0.722391C9.8084 0.2962 10.1054 -0.00309655 10.5079 -0.000471145C10.9105 0.00215426 11.1996 0.308451 11.2013 0.734643C11.2049 1.18403 11.2031 1.63166 11.2031 2.10073Z"
        fill={color2}
      />
    </svg>
  );
}

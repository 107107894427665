import { useAuthContext } from '../context/authContext';
import EN_LANG from './en.json';
import HI_LANG from './hi.json';
import MR_LANG from './mr.json';

export function useLanguage() {
  const { getData } = useAuthContext();
  const { lang } = getData();
  const LANG_JSON = lang === 'HI' ? HI_LANG : lang === 'MR' ? MR_LANG : EN_LANG;
  return (words) => LANG_JSON[words] ?? words;
}

import { useState } from 'react';
import './my-tractor.css';
import { useAddTractor, useGetTractor, useUpdateTractor, useDeleteTractor, useActiveTractor } from '../profile-api';
import { Autocomplete, Avatar, Button, Dialog, Skeleton, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useGetBrand, useGetModel } from '../../homepage/homepage-api';
import InputNumber from '../../../assets/custom-fields/input-number';
import { getLastYears } from '../../../config/common-function/data-generate';
import { LazyImage } from '../../../assets/image/lazyImage';
import SwitchCustom from '../../homepage/components/switch/switch';
import IconEditRound from '../../homepage/components/switch/icon-edit-round';
import { useLanguage } from '../../../language/useLanguage';
import { useAuthContext } from '../../../context/authContext';
const getYears = getLastYears(30);

export default function MyTractor() {
  const { getData } = useAuthContext();
  const { userData } = getData();
  const getUserTractor = useGetTractor();
  const [edit, setEdit] = useState(false);
  const handleEditClose = () => setEdit(false);
  const [add, setAdd] = useState(false);
  const handleAddClose = () => setAdd(false);
  const [tractorID, setTractorID] = useState(null);
  const [inputs, setInputs] = useState(null);
  const deleteTractor = useDeleteTractor(userData?.language);
  const activeTractor = useActiveTractor(userData?.language);
  const langData = useLanguage();

  const handleEditTractor = (tractorData) => {
    const inputs = {
      tractor_brand_id: tractorData.TractorModel.TractorBrand.id,
      tractor_model_id: tractorData.tractor_model_id,
      reg_no: tractorData.reg_no,
      purchase_year: tractorData.purchase_year,
      manufacture_year: tractorData.manufacture_year,
      hours_used: tractorData.hours_used,
    };
    setTractorID(tractorData.id);
    setInputs(inputs);
    setEdit(true);
  };
  const handleDeleteTractor = (e, tractor_id) => {
    if (e.target.checked === true) {
      activeTractor.mutate(tractor_id);
    } else {
      deleteTractor.mutate(tractor_id);
    }
  };
  if (getUserTractor.isLoading) return <LoadingAddresses />;
  return (
    <div className="tractor-details">
      <div className="heading-container">
        <h2>{langData('Tractor Details')}</h2>
        <Button variant="contained" onClick={() => setAdd(true)}>
          {langData('Add New Tractor')}
        </Button>
      </div>
      {getUserTractor.data.length > 0 ? (
        <div className="tractor-details-grid">
          {getUserTractor.data.map((tractor) => (
            <div key={tractor.id} className={`${tractor.status === 1 ? 'tractor-card' : 'tractor-card deactiveCard'}`}>
              <div className="tractor-header">
                <Avatar className="icon-container">
                  <LazyImage name={tractor.TractorModel?.model_img} fallbackImage="tractor-model-image.svg" width={50} height={50} />
                </Avatar>
                <div>
                  <SwitchCustom color="primary" checked={tractor.status === 1} value={tractor.id} onClick={(e) => handleDeleteTractor(e, tractor.id)} />
                  {tractor.status === 1 && (
                    <>
                      <div style={{ marginTop: 12, cursor: 'pointer' }} onClick={() => handleEditTractor(tractor)}>
                        <IconEditRound height="1.5rem" width="1.5rem" />
                      </div>
                    </>
                  )}
                </div>
              </div>
              <h3>{`${tractor.TractorModel.TractorBrand.brand_name} ${tractor.TractorModel.model_name}`}</h3>
              <div className="tractor-des-details">
                <div className="tractor-chassis-details">
                  <p className="detail-title">{langData('Chassis No.')}</p>
                  <p className="detail-des">{tractor.reg_no}</p>
                </div>
                <div className="tractor-yop-details">
                  <p className="detail-title">{langData('Year of Purchase')}</p>
                  <p className="detail-des">{tractor.purchase_year}</p>
                </div>
                <div className="tractor-uh-details">
                  <p className="detail-title">{langData('Used Hours')}</p>
                  <p className="detail-des">{tractor.hours_used}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <NoTractor />
      )}

      {edit && <AddTractorModel open={edit} onClose={handleEditClose} inputs={inputs} tractorID={tractorID} language={userData.language} />}
      {add && (
        <AddTractorModel
          open={add}
          onClose={handleAddClose}
          inputs={{
            tractor_brand_id: null,
            tractor_model_id: null,
            reg_no: '',
            purchase_year: null,
            manufacture_year: null,
            hours_used: '',
          }}
          language={userData.language}
        />
      )}
    </div>
  );
}

export function AddTractorModel({ open = false, onClose, tractorID, inputs, disableBrand = false, disableModel = false, onSuccess, language }) {
  const addAddress = useAddTractor(language);
  const updateAddress = useUpdateTractor(tractorID, language);
  const langData = useLanguage();
  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
  } = useForm({ defaultValues: inputs });
  const [tractor_brand_id] = watch(['tractor_brand_id']);
  const brandList = useGetBrand();
  const modelList = useGetModel(tractor_brand_id);

  function submit(inputs) {
    console.debug('SUBMITTING: ', inputs);
    const form = new FormData();
    for (const key in inputs) {
      form.append(key, inputs[key]);
    }

    if (tractorID) {
      form.append('address_id', tractorID);
      updateAddress.mutate(form, {
        onSuccess: () => {
          onClose();
          onSuccess?.();
        },
      });
    } else {
      addAddress.mutate(form, {
        onSuccess: (res) => {
          onClose();
          onSuccess?.(res);
        },
      });
    }
  }

  return (
    <Dialog open={open} onClose={onClose} className="popup edit-profile">
      <form className="form-container" noValidate onSubmit={handleSubmit(submit)}>
        <h2>{tractorID ? 'Update' : 'Add'} Tractor</h2>
        <div className="form-column-2">
          <div className="field-container">
            <label className="required">{langData('Choose Brand')}</label>
            <Controller
              name="tractor_brand_id"
              control={control}
              rules={{ required: langData('Please Select Brand') }}
              render={({ field }) => {
                return (
                  <Autocomplete
                    {...field}
                    disabled={!!tractorID || disableBrand}
                    options={brandList.data ?? []}
                    renderInput={(params) => <TextField {...params} placeholder={langData('Brand')} error={!!errors.tractor_brand_id} />}
                    isOptionEqualToValue={(option, value) => option.id === value}
                    getOptionLabel={(option) => option.label ?? brandList.data?.find((opt) => opt.id === option)?.label ?? ''}
                    onChange={(__event, data) => {
                      field.onChange(data?.id ?? null);
                      setValue('tractor_model_id', null);
                    }}
                  />
                );
              }}
            />
            <p className="error-text">{errors.tractor_brand_id?.message}</p>
          </div>
          <div className="field-container">
            <label className="required">{langData('Choose Tractor Model')}</label>
            <Controller
              name="tractor_model_id"
              control={control}
              rules={{ required: langData('Please Select Tractor Model') }}
              render={({ field }) => {
                return (
                  <Autocomplete
                    {...field}
                    disabled={!tractor_brand_id || !!tractorID || disableModel}
                    options={modelList.data ?? []}
                    renderInput={(params) => <TextField {...params} placeholder={langData('Model')} error={!!errors.tractor_model_id} />}
                    isOptionEqualToValue={(option, value) => option.id === value}
                    getOptionLabel={(option) => option.label ?? modelList.data?.find((opt) => opt.id === option)?.label ?? ''}
                    onChange={(__event, data) => {
                      field.onChange(data?.id ?? null);
                    }}
                  />
                );
              }}
            />
            <p className="error-text">{errors.tractor_model_id?.message}</p>
          </div>
        </div>
        <div className="form-column-2">
          <div className="field-container">
            <label className="required">{langData('Chassis No.')}</label>
            <TextField
              {...register('reg_no', {
                required: langData('Field is Required'),
                minLength: {
                  value: 2,
                  message: 'Minimum 2 character required',
                },
                maxLength: { value: 30, message: 'Maximum limit exceed' },
              })}
              error={!!errors.reg_no}
            />
            <p className="error-text">{errors.reg_no?.message}</p>
          </div>
          <div className="field-container">
            <label className="required">{langData('Year of Manufacture')}</label>
            <Controller
              name="manufacture_year"
              control={control}
              rules={{
                required: langData('Please Select Year of Manufacture'),
                deps: ['purchase_year'],
              }}
              render={({ field }) => {
                return (
                  <Autocomplete
                    {...field}
                    options={getYears}
                    value={field.value?.toString() ?? null}
                    renderInput={(params) => <TextField {...params} placeholder={langData('Year of Manufacture')} error={!!errors.manufacture_year} />}
                    onChange={(___event, newValue) => {
                      field.onChange(newValue ? Number(newValue) : newValue);
                    }}
                  />
                );
              }}
            />
            <p className="error-text">{errors.manufacture_year?.message}</p>
          </div>
        </div>
        <div className="form-column-2">
          <div className="field-container">
            <label className="required">{langData('Year of Purchase')}</label>
            <Controller
              name="purchase_year"
              control={control}
              rules={{
                required: langData('Please Select purchase year'),
                validate: {
                  notLessThanManufacture: (value, formValues) => {
                    return Number(value) < Number(formValues.manufacture_year) ? 'Please select valid year' : true;
                  },
                },
              }}
              render={({ field }) => {
                return (
                  <Autocomplete
                    {...field}
                    options={getYears}
                    value={field.value?.toString() ?? null}
                    renderInput={(params) => <TextField {...params} placeholder={langData('Purchase year')} error={!!errors.purchase_year} />}
                    onChange={(___event, newValue) => {
                      field.onChange(newValue ? Number(newValue) : newValue);
                    }}
                  />
                );
              }}
            />
            <p className="error-text">{errors.purchase_year?.message}</p>
          </div>
          <div className="field-container">
            <label className="required">{langData('Hours Used')}</label>
            <Controller name="hours_used" control={control} render={({ field }) => <InputNumber {...field} />} />
            <p className="error-text">{errors.hours_used?.message}</p>
          </div>
        </div>

        <div style={{ display: 'flex', gap: 16, flexWrap: 'wrap' }}>
          <Button variant="contained" type="submit" disabled={addAddress.isLoading || updateAddress.isLoading}>
            {langData('Save')}
          </Button>
          <Button variant="outlined" onClick={onClose}>
            {langData('Cancel')}
          </Button>
        </div>
      </form>
    </Dialog>
  );
}

function LoadingAddresses() {
  const langData = useLanguage();
  return (
    <div className="address-details">
      <div className="heading-container">
        <h2>{langData('Tractor Details')}</h2>
        <Button variant="contained" disabled>
          {langData('Add New Tractor')}
        </Button>
      </div>
      <div className="address-details-grid">
        {[1, 2].map((no) => (
          <div key={no} className="address-card">
            <div className="address-header">
              <h3>
                <Skeleton variant="text" />
              </h3>
            </div>
            <p>
              <Skeleton variant="text" />
            </p>
            <p>
              <Skeleton variant="text" />
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}

function NoTractor() {
  return (
    <div style={{ textAlign: 'center', width: '100%' }}>
      <img style={{ maxWidth: '400px', width: '100%' }} src="/assets/images/placeholder-no-tractor.svg" alt="No-Tractor" />
      <p style={{ fontSize: 20, margin: '16px 0px' }}>No Tractor Available</p>
    </div>
  );
}

import './ew-purchase-page.css';
import { useLocation, Navigate } from 'react-router-dom';
import WarrantyPurchaseBanner from './ew-purchase-banner';
import FormWarrantyPurchase from './form-warranty-purchase';

export default function WarrantyPurchasePage() {
  const location = useLocation();
  if (!location.state) return <Navigate to={'/warranty'} />;
  return (
    <div>
      <WarrantyPurchaseBanner />
      <FormWarrantyPurchase />
    </div>
  );
}

import { useContext } from 'react';
import useAxios from '../../../config/axios/useAxios';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { promptActions, promptContext } from '../../../context/prompt';

export function useAddTractor(customer_id) {
  const API = `customer/tractor/add/${customer_id}`;
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (form) => protectedAxios.post(`${API}`, form),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['customer-tractors'] });
      queryClient.invalidateQueries(['customer/tractor/getall']);
      queryClient.invalidateQueries({
        queryKey: ['customer', 'tractor-dd-list'],
      });
    },
    onError: (error) => {
      prompt({
        type: promptActions.SHOW_PROMPT,
        payload: {
          message: error.response.data?.message || 'Error while adding',
        },
      });
    },
  });
}
export function useAddToCart() {
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (formData) => protectedAxios.post('cart', formData),
    onSuccess: (response) => {
      prompt({
        type: promptActions.SHOW_PROMPT,
        payload: {
          type: 'success',
          message: response.data?.message || 'Added successfully',
        },
      });
      queryClient.invalidateQueries({ queryKey: ['cart'] });
    },
    onError: (error) => {
      prompt({
        type: promptActions.SHOW_PROMPT,
        payload: {
          message: error.response.data?.message || 'Error while adding',
        },
      });
    },
  });
}

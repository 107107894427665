import './warranty-form.css';
import CheckPriceForm from './form-check-price';
import { useState } from 'react';
import WarrantyMiniSummary from './form-summary-warranty';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import IconSuccess from '../../../assets/icons/icon-common/icon-success';
import IconCancelFile from '../../../assets/icons/icon-common/icon-cancel-file';
import { Button, Dialog } from '@mui/material';
import { useLanguage } from '../../../language/useLanguage';

let defaultValues = {
  owner_name: '',
  owner_phone_no: '',
  tractor_brand_id: null,
  tractor_model_id: null,
  purchase_year: null,
};
export default function WarrantyForm() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const langData = useLanguage();
  const payment_status = searchParams.get('order_status');
  const [paymentPopup, setPaymentPopup] = useState(Boolean(payment_status));
  const handleClosePaymentPopup = () => {
    setPaymentPopup(false);
    navigate('/warranty', { replace: true });
  };

  const location = useLocation();
  if (location?.state?.formInputs) defaultValues = location?.state?.formInputs ?? defaultValues;
  const inputForm = useForm({ defaultValues });

  const [formStep, setFormStep] = useState(1);
  const [details, setDetails] = useState(null);
  const setData = (data) => {
    setDetails(data);
    setFormStep(2);
  };

  const prevStep = () => setFormStep(1);

  return (
    <div className="warranty-form-container" id="warranty-form-section">
      <div className="title-container">
        <h2>{langData('Bosch Inline Warranty Pump')}</h2>
      </div>
      <div className="content-section">
        <div className="image-text-container">
          <h3>{langData('Bosch Inline Warranty Pump Check For Free')}</h3>
          <img src="/images/warranty-form-image.png" alt="warranty-form" />
        </div>
        {formStep === 1 && <CheckPriceForm setData={setData} inputForm={inputForm} />}
        {formStep === 2 && <WarrantyMiniSummary data={details} prevStep={prevStep} />}
      </div>
      {paymentPopup && <PaymentStatusDialog open={paymentPopup} payment_status={payment_status} handleClosePaymentPopup={handleClosePaymentPopup} />}
    </div>
  );
}

function PaymentStatusDialog({ open = false, payment_status, handleClosePaymentPopup }) {
  return (
    <Dialog open={open} onClose={handleClosePaymentPopup} className="popup payment-status">
      <div className="payment-status">
        <div>{payment_status === 'Success' ? <IconSuccess height={136} width={136} /> : <IconCancelFile height={136} width={136} />}</div>
        <div>
          {payment_status === 'Success' ? (
            <>
              <h1>Payment Successful</h1>
              <p>Thank You for Your Order.</p>
              <Button variant="contained" onClick={handleClosePaymentPopup} style={{ marginTop: '1rem', minWidth: 100 }}>
                Okay
              </Button>
            </>
          ) : (
            <>
              <h1>Payment Cancelled</h1>
              <Button variant="contained" onClick={handleClosePaymentPopup} style={{ marginTop: '1rem', minWidth: 100 }}>
                Okay
              </Button>
            </>
          )}
        </div>
      </div>
    </Dialog>
  );
}

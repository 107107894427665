export default function IconEmailFooter({ width = '1em', height = '1em', color = '#fff', ...props }) {
  return (
    <svg width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1826_78)">
        <circle cx="16" cy="16" r="15.5" stroke={color} />
        <path
          d="M15.4053 15.9634C15.7508 16.2808 16.2511 16.2817 16.596 15.9634L22.6605 10.3995C22.4389 10.1543 22.1291 10 21.7839 10H10.218C9.87282 10 9.56267 10.1543 9.34139 10.3995L15.4056 15.9631L15.4053 15.9634Z"
          fill={color}
        />
        <path d="M22.9595 20.0212C22.9833 19.9199 23 19.8158 23 19.7067V11.2953C23 11.1859 22.9833 11.0814 22.9595 10.9802L18.0324 15.5004L22.9595 20.0212Z" fill={color} />
        <path d="M9.04048 10.9802C9.01644 11.0814 9 11.1859 9 11.2953V19.7067C9 19.8158 9.01644 19.9199 9.04048 20.0212L13.9679 15.5004L9.04048 10.9802Z" fill={color} />
        <path
          d="M17.5681 15.9269L16.9947 16.453C16.7117 16.7137 16.3568 16.8441 16.0016 16.8441C15.6464 16.8441 15.2912 16.7137 15.0078 16.4526L14.4347 15.9269L9.34139 20.5998C9.56297 20.8454 9.87312 21 10.2186 21H21.7845C22.13 21 22.4402 20.8457 22.6617 20.5998L17.5681 15.9269Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1826_78">
          <rect width="32" height="32" fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
}

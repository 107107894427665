import { Autocomplete, Button, FormControlLabel, InputAdornment, Radio, RadioGroup, TextField } from '@mui/material';
import './franchise-form.css';
import InputNumber from '../../../../../assets/custom-fields/input-number';
import { Controller, useForm } from 'react-hook-form';
import { useAddFranchiseInquiry, useGetFranchiseStates } from './franchise-api';
import { useLanguage } from '../../../../../language/useLanguage';

const defaultValues = {
  name: '',
  phone_no: '',
  state: null,
  sub_district: '',
  tractor_industry: '',
};

export default function FranchiseForm() {
  const langData = useLanguage();
  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({ defaultValues: defaultValues });
  const dropdownStateList = useGetFranchiseStates();
  const addFranchiseInquiry = useAddFranchiseInquiry();

  const submit = (inputs) => {
    const form = new FormData();
    for (const key in inputs) {
      form.append(key, inputs[key]);
    }
    addFranchiseInquiry.mutate(form, {
      onSuccess: () => {
        reset();
      },
    });
  };

  return (
    <div className="franchise-container" id="franchise-form">
      <div className="franchise-des-container">
        <div className="franchise-title">
          <h5>{langData('Enquire Now')}</h5>
          <p>{langData(`Discover convenience, reliability, and value like never before. Join Tractor Seva today and experience the difference!`)}</p>
          <img className="franchise-img" src="./images/franchise-form-img.jpg" />
        </div>
      </div>
      <div className="franchise-form-container">
        <form className="form-container" noValidate onSubmit={handleSubmit(submit)}>
          <div className="column-two">
            <div className="field-container">
              <label className="required">{langData('Name')}</label>
              <TextField
                {...register('name', {
                  required: langData('Field is required'),
                  minLength: {
                    value: 2,
                    message: 'Minimum 2 character required',
                  },
                  maxLength: { value: 30, message: 'Maximum limit exceed' },
                })}
                error={!!errors.name}
              />
              <p className="error-text">{errors.name?.message}</p>
            </div>
            <div className="field-container">
              <label className="required">{langData('Phone Number')}</label>
              <Controller
                name="phone_no"
                control={control}
                rules={{
                  required: langData('Phone Number is Required'),
                  maxLength: { value: 10, message: 'Enter Valid Number' },
                  minLength: { value: 10, message: 'Enter Valid Number' },
                }}
                render={({ field }) => (
                  <InputNumber
                    {...field}
                    fullWidth
                    InputProps={{
                      startAdornment: <InputAdornment position="start">+91</InputAdornment>,
                    }}
                    inputProps={{ maxLength: 10 }}
                  />
                )}
              />
              <p className="error-text">{errors.phone_no?.message}</p>
            </div>
          </div>
          <div className="column-two">
            <div className="field-container">
              <label className="required">{langData('State')}</label>
              <Controller
                name="state"
                control={control}
                rules={{ required: langData('Please Select State') }}
                render={({ field }) => {
                  return (
                    <Autocomplete
                      {...field}
                      options={dropdownStateList.data ?? []}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={langData('State')}
                          error={!!errors.state}
                          //   style={{ padding: "1px" }}
                        />
                      )}
                      onChange={(__event, data) => {
                        field.onChange(data ?? null);
                      }}
                    />
                  );
                }}
              />
              <p className="form-error-text">{errors.state?.message}</p>
            </div>
            <div className="field-container">
              <label className="required">{langData('Tehsil')}</label>
              <TextField
                {...register('sub_district', {
                  required: langData('Field is required'),
                  minLength: {
                    value: 2,
                    message: 'Minimum 2 character required',
                  },
                  maxLength: { value: 30, message: 'Maximum limit exceed' },
                })}
                error={!!errors.sub_district}
              />
              <p className="error-text">{errors.sub_district?.message}</p>
            </div>
          </div>
          <div className="field-container">
            <label className="required">{langData('Associated with the tractor industry?')}</label>
            <Controller
              rules={{ required: langData('Select Status') }}
              control={control}
              name="tractor_industry"
              render={(props) => {
                return (
                  <RadioGroup style={{ marginTop: '-10px' }} row {...props.field}>
                    <FormControlLabel value="1" control={<Radio color="secondary" />} label={langData('Yes')} style={{ marginBottom: '0' }} />
                    <FormControlLabel value="0" control={<Radio color="secondary" />} label={langData('No')} style={{ marginBottom: '0' }} />
                  </RadioGroup>
                );
              }}
            />
            <p className="form-error-text">{errors.tractor_industry?.message}</p>
          </div>
          <div
            style={{
              display: 'flex',
              gap: 16,
              flexWrap: 'wrap',
              //   paddingTop: "1rem",
            }}
          >
            <Button variant="contained" type="submit">
              {langData('Submit')}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

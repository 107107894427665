import { Helmet } from 'react-helmet-async';

export default function FranchiseSEO() {
  const host_url = process.env.REACT_APP_HOST_URL;

  return (
    <Helmet>
      <title>Franchise | Tractor Seva</title>
      <meta name="title" content="Top Tractor Servicing Franchise: Enhance Your Agribusiness!" />
      <meta
        name="description"
        content="Join our franchise for best quality tractor maintenance services! TractorSeva: The best tractor servicing company in India, ensuring quality care. Join Now!"
      />
      <link rel="canonical" href={host_url + '/franchise'} />
    </Helmet>
  );
}

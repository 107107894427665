import { Helmet } from 'react-helmet-async';

export default function WarrantySEO() {
  const host_url = process.env.REACT_APP_HOST_URL;

  return (
    <Helmet>
      <title>Warranty | Tractor Seva</title>
      <meta property="og:title" content="Warranty | Tractor Seva" />
      <link rel="canonical" href={host_url + '/warranty'} />
    </Helmet>
  );
}

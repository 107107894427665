export default function IconLocationFooter({ width = '1em', height = '1em', color = '#fff', ...props }) {
  return (
    <svg width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1826_71)">
        <circle cx="16" cy="16" r="15.5" stroke={color} />
        <path
          d="M15.9998 9C13.5399 9 11.4883 10.9815 11.4883 13.5115C11.4883 14.474 11.7775 15.3323 12.3326 16.1365L15.6545 21.3201C15.8157 21.5721 16.1842 21.5716 16.345 21.3201L19.6814 16.1189C20.2245 15.3511 20.5113 14.4495 20.5113 13.5115C20.5113 11.0239 18.4874 9 15.9998 9ZM15.9998 15.5622C14.8691 15.5622 13.9491 14.6422 13.9491 13.5115C13.9491 12.3808 14.8691 11.4608 15.9998 11.4608C17.1305 11.4608 18.0504 12.3808 18.0504 13.5115C18.0504 14.6422 17.1305 15.5622 15.9998 15.5622Z"
          fill={color}
        />
        <path
          d="M19.206 18.4258L17.1408 21.6546C16.6062 22.488 15.3902 22.4853 14.8582 21.6554L12.7896 18.4266C10.9696 18.8474 9.84766 19.6183 9.84766 20.5395C9.84766 22.138 13.0174 23.0003 15.9997 23.0003C18.982 23.0003 22.1517 22.138 22.1517 20.5395C22.1517 19.6176 21.0282 18.8464 19.206 18.4258Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1826_71">
          <rect width="32" height="32" fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
}

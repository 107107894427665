import ImageSlider from './banner-slider';
import './banner-slider.css';

export default function BannerCarousal({ data }) {
  // const slides = [
  //   {
  //     image_name: "/images/banner-homepage.jpg",
  //     title: "bnnr1",
  //     link: "http://65.0.129.114/work",
  //   },
  //   {
  //     image_name: "/images/banner-homepage-old.jpg",
  //     title: "bnnr2",
  //     link: "",
  //     // bnnrLinks: "https://www.w3schools.com/jsref/met_win_open.asp",
  //   },
  //   {
  //     image_name: "/images/banner-homepage.jpg",
  //     title: "bnnr3",
  //     link: "",
  //   },
  //   {
  //     image_name: "/images/banner-homepage-old.jpg",
  //     title: "bnnr4",
  //     link: "",
  //   },
  // ];
  // console.debug("tstvhjblb", data);

  const responsiveSlides = [
    // {
    //   image_name: "/images/banner-homepage-old.jpg",
    //   title: "bnnr2",
    //   bnnrLinks: "",
    // },
    // { image_name: "/images/banner-homepage.jpg", title: "bnnr1" },
    // { image_name: "/images/banner-homepage-old.jpg", title: "bnnr4" },
    // { image_name: "/images/banner-homepage.jpg", title: "bnnr3" },
  ];

  // const containerStyles = {
  //   width: "100%",
  //   height: "75vh",
  //   margin: "0 auto",
  // };

  return (
    <div className="banner_container_cstm">
      <div className="banner_container_styles_cstm">
        <ImageSlider
          slides={data}
          responsiveSlides={responsiveSlides.length === 0 ? data : responsiveSlides}
          // slides={slides}
          // responsiveSlides={responsiveSlides}
          // parentWidth={1920}
          duration={5000}
          disableArrows
        />
      </div>
    </div>
  );
}

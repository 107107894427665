import { createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider } from 'react-router-dom';
import Homepage from './component/homepage/homepage';
import Layout from './component/layout/layout';
import About from './component/about/about';
import RouteError from './routing/no-route-found';
import Login from './component/login/login';
import Logout from './component/login/logout';
import Profile from './component/profile/profile';
import BookingAdd from './component/booking/booking-add';
import MyBooking from './component/booking/my-booking';
import { useAuthContext } from './context/authContext';
import Cancellationandpolicy from './component/homepage/components/cancellation-and-rescheduling-policy/cancellation-and-rescheduling-policy';
import Termsandcondition from './component/homepage/components/terms-and-condition/terms-and-condition';
import Privacypolicy from './component/homepage/components/privacy-policy/privacy-policy';
import Serviceagreement from './component/homepage/components/service-agreement/service-agreement';
import BookingView from './component/booking/booking-view';
import FranchisePage from './component/homepage/components/franchise/franchise-page';
import SessionTimeout from './routing/session-timeout';
import ReactGA from 'react-ga';
import ContactUs from './component/homepage/components/contact-us/contact-us';
import Warranty from './component/warranty/warranty';
import WarrantyPurchasePage from './component/warranty/warranty-purchase-request/ew-purchase-page';
import BoschTermsandcondition from './component/homepage/components/terms-and-condition/bosch-extened warranty/warranty-bosch-t&c';
import PaymentLink from './routing/paymentlink';
import CustomerOrderAdd from './component/customer-order/customer-order-add';
import HomepageV2 from './component/homepage-V2/homepage';
import OrderSummary from './component/order/order-summary/order-summary';
import MyOrder from './component/customer-order/customer-order-list';
import OrderView from './component/customer-order/customer-order-view';
import { useSelector } from 'react-redux';
import NoCartFound from './component/no-cart-found/no-cart-found';
ReactGA.initialize('G-M10D9W5CJQ');

function App() {
  const { getData } = useAuthContext();
  const { isAuth } = getData();
  const cartItems = useSelector((state) => state.cart.cartItems);
  console.debug(cartItems, 'cartItems');
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route>
        {/* <Route path="login" element={<Login />} errorElement={<RouteError />} /> */}
        <Route path="logout" element={<Logout />} />
        <Route path="/*" element={<Navigate to="/" />} />
        <Route path="session-timeout" element={<SessionTimeout />} />
        <Route path="/" element={<Layout />}>
          <Route path="login" element={<Login />} errorElement={<RouteError />} />
          <Route index element={<HomepageV2 />} />
          <Route path="home-page-v2" element={<HomepageV2 />} />
          <Route path="franchise" element={<FranchisePage />} />
          <Route path="contact-us" element={<ContactUs />} />
          <Route path="about-us" element={<About />} />
          <Route path="warranty" element={<Warranty />} />
          <Route path="home-old" element={<Homepage />} />
          {isAuth && (
            <>
              <Route path="warranty-purchase" element={<WarrantyPurchasePage />} />
              <Route path="booking" element={<MyBooking />} />
              <Route path="orders" element={<MyOrder />} />
              <Route path="orders/view/:id" element={<OrderView />} />
              <Route path="booking/add" element={<BookingAdd />} />
              <Route path="booking/view/:id" element={<BookingView />} />
              <Route path="order-summary" element={cartItems !== null || (Array.isArray(cartItems) && cartItems.length > 0) ? <OrderSummary /> : <NoCartFound />} />
              <Route path="profile" element={<Profile />} />
            </>
          )}
          <Route path="customer-order" element={<CustomerOrderAdd />} />
          <Route path="/:brand/:model" element={<CustomerOrderAdd />} />
          <Route path="cancellation-policy" element={<Cancellationandpolicy />} />
          <Route path="terms-and-condition" element={<Termsandcondition />} />
          <Route path="warranty-bosch-t&c" element={<BoschTermsandcondition />} />
          <Route path="service-agreement" element={<Serviceagreement />} />
          <Route path="privacy-policy" element={<Privacypolicy />} />
        </Route>
        <Route path="paylink/:link" element={<PaymentLink />} />
      </Route>,
    ),
  );

  return <RouterProvider router={router} />;
}

export default App;

export default function IconNoCustomerTractors({ width = '78', height = '79', ...props }) {
  return (
    <svg width={width} height={height} viewBox="0 0 78 79" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="38.9999" cy="39.5" r="33.4286" fill="#6D7D93" fillOpacity="0.1" />
      <path
        d="M36.7945 46.066C36.7871 47.4425 36.3577 48.7862 35.5603 49.9272C34.7629 51.0682 33.6333 51.9554 32.3143 52.4768C30.9952 52.9982 29.5458 53.1304 28.149 52.8567C26.7522 52.583 25.4707 51.9157 24.4663 50.939C23.4619 49.9623 22.7796 48.7201 22.5056 47.3691C22.2316 46.0182 22.3782 44.6191 22.9269 43.3486C23.4755 42.0781 24.4017 40.9931 25.5883 40.2306C26.775 39.4681 28.1689 39.0623 29.5942 39.0645C30.5437 39.0649 31.4837 39.2467 32.3602 39.5993C33.2368 39.9519 34.0325 40.4684 34.7016 41.1191C35.3708 41.7697 35.9002 42.5417 36.2593 43.3907C36.6185 44.2397 36.8004 45.1489 36.7945 46.066ZM31.8614 46.066C31.864 45.6312 31.7332 45.2054 31.4855 44.8423C31.2379 44.4793 30.8844 44.1952 30.4698 44.026C30.0552 43.8567 29.5979 43.8099 29.1558 43.8913C28.7136 43.9728 28.3063 44.1789 27.9853 44.4837C27.6643 44.7885 27.444 45.1783 27.3521 45.604C27.2602 46.0296 27.3009 46.472 27.469 46.8753C27.6372 47.2786 27.9252 47.6248 28.2968 47.8701C28.6684 48.1155 29.1069 48.2491 29.557 48.254C29.8574 48.2566 30.1553 48.2021 30.4338 48.0933C30.7122 47.9846 30.9658 47.8239 31.1798 47.6204C31.3939 47.4169 31.5643 47.1746 31.6812 46.9074C31.7982 46.6402 31.8594 46.3533 31.8614 46.0632V46.066Z"
        fill="#6D7D93"
      />
      <path
        d="M55.0324 47.1333C55.0328 48.2934 54.6769 49.4276 54.0099 50.3923C53.3428 51.3571 52.3945 52.109 51.285 52.5532C50.1754 52.9973 48.9543 53.1136 47.7763 52.8873C46.5983 52.6611 45.5162 52.1025 44.6669 51.2822C43.8176 50.4619 43.2393 49.4167 43.0051 48.2789C42.7709 47.1411 42.8913 45.9617 43.3511 44.89C43.8109 43.8183 44.5894 42.9024 45.5882 42.2581C46.5871 41.6138 47.7613 41.2701 48.9623 41.2704C49.7598 41.2692 50.5497 41.4201 51.2867 41.7143C52.0237 42.0085 52.6934 42.4403 53.2573 42.9849C53.8212 43.5296 54.2683 44.1764 54.5729 44.8883C54.8775 45.6001 55.0336 46.3631 55.0324 47.1333Z"
        fill="#6D7D93"
      />
      <path
        d="M40.688 28.2508C41.246 30.6219 41.7919 32.9572 42.3555 35.2879C42.3875 35.4201 42.5604 35.6066 42.6825 35.6186C43.9038 35.738 45.1251 35.8262 46.3943 35.9273V34.1009C46.3943 33.7564 46.3943 33.4119 46.3943 33.0674C46.4018 32.3913 46.8715 31.9025 47.5113 31.8979C48.1511 31.8933 48.6387 32.3857 48.6434 33.0545C48.649 34.0522 48.6434 35.0508 48.6434 36.0862C49.2681 36.1385 49.8806 36.2065 50.4941 36.2378C51.5519 36.292 52.311 36.9277 52.3824 37.9529C52.4454 38.856 52.4078 39.7655 52.4134 40.6732C52.4134 40.7503 52.4031 40.8275 52.3937 40.9579C49.8741 39.6828 47.3563 39.5882 44.9062 40.9782C42.4561 42.3681 41.2479 44.5445 41.1192 47.329C40.4203 47.329 39.7297 47.3565 39.043 47.3189C38.5968 47.295 38.2219 46.8834 38.1552 46.4186C38.0556 45.7176 38.0378 44.9992 37.8809 44.3111C37.4959 42.6531 36.603 41.1494 35.3215 40.0004C34.0399 38.8514 32.4306 38.1119 30.7081 37.8804C28.3388 37.5662 26.2081 38.1183 24.2794 39.4945C24.0252 39.6708 23.7237 39.7702 23.4122 39.7802C22.9247 39.7802 22.5874 39.4835 22.4324 39.026C22.3539 38.8117 22.3507 38.578 22.4232 38.3617C22.4956 38.1455 22.6397 37.959 22.8326 37.8317C23.5757 37.3567 24.3451 36.9213 25.1155 36.4886C25.2855 36.3967 25.3814 36.3204 25.3814 36.1101C25.3729 33.5718 25.3757 31.0344 25.3748 28.4961C25.3748 28.418 25.3635 28.3408 25.3569 28.2554C25.1551 28.2419 24.9541 28.2186 24.7547 28.1855C24.4889 28.1272 24.2539 27.9761 24.0939 27.7604C23.9339 27.5448 23.8599 27.2794 23.8857 27.0142C23.909 26.7545 24.0278 26.5117 24.2201 26.331C24.4123 26.1503 24.665 26.0438 24.9314 26.0312C25.5758 26.0074 26.2203 26.0009 26.8648 26C30.8731 26 34.8815 26 38.8899 26C39.4874 26 40.0849 26.0147 40.6824 26.0239C41.3541 26.034 41.8388 26.4437 41.8849 27.0344C41.9309 27.6251 41.5354 28.0826 40.89 28.2122C40.8383 28.2269 40.7819 28.2342 40.688 28.2508ZM27.6455 35.659C31.8054 35.4495 35.9287 35.6406 40.0943 35.546C39.5184 33.0959 38.9453 30.6798 38.3816 28.2701H27.6455V35.659Z"
        fill="#6D7D93"
      />
    </svg>
  );
}

import { Popover, Button, FormControl, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useState } from 'react';
import { useGetStates, useGetTehsil } from './header-api';
import IconArrowDown from '../../../assets/icons/icon-common/icon-arrow-down';
import { useDispatch, useSelector } from 'react-redux';
import {
  setLocationID,
  setTehsilName,
  setStateName,
  setStateID,
  setLocationChange,
  setIsLocationModelOpen,
  setIsLocationChangeAgree,
  setWorkshopSelectId,
} from '../../../redux/features/location/locationSlice';
import { getOptionLabel } from '../../../assets/autocomplete/autocomplete-functions';
import { useEffect } from 'react';
import { useGetWorkshopLocation } from '../../homepage/homepage-api';
import { useDeleteCart } from '../cart/cart-api';
import { setCartItems } from '../../../redux/features/cart/cartSlice';

const PopoverWithDropdowns = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  // const [state, setState] = useState("");
  // const [tehsil, setTehsil] = useState("");
  const locationId = useSelector((state) => state.location.locationId);
  const getLocationWorkshop = useGetWorkshopLocation(locationId);
  const stateId = useSelector((state) => state.location.stateId);
  const cartItems = useSelector((state) => state.cart.cartItems);
  const cartId = useSelector((state) => state.cart.cartId);
  const deleteCart = useDeleteCart(cartId);
  const isLocationChangeAgree = useSelector((state) => state.location.isLocationChangeAgree);

  // if (locationId && locationId !== null) {
  //   // setTehsil(`${locationId}`);
  // }

  const dropdownStateList = useGetStates();
  const dispatch = useDispatch();
  const dropdownTehsilList = useGetTehsil(stateId);
  // const [buttonText, setButtonText] = useState("Location");

  let buttonText = 'Location';

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (dropdownTehsilList.data?.find((opt) => opt.id == locationId)) {
    buttonText = dropdownTehsilList.data?.find((opt) => opt.id == locationId)?.label;
  }
  // let selectedState;
  let selectedTehsil;

  const handleStateChange = (option, reason) => {
    if (reason !== 'clear') {
      // setState((prevState) => ({ ...prevState, state: option.id }));
      if (dropdownStateList.data?.find((opt) => opt.id === option.id)) {
        dispatch(setStateName(dropdownStateList.data?.find((opt) => opt.id === option.id)?.label));
      }
      localStorage.setItem('state_id', option.id);
      if (option.id !== stateId) {
        dispatch(setStateID(option.id));
      }
      // selectedState =
      //   dropdownStateList.data?.find((opt) => opt.id === option.id)?.label ??
      //   "Select State";
      // setButtonText(`${selectedState},`);
    } else {
      // setState((prevState) => ({ ...prevState, state: null }));
      localStorage.setItem('state_id', option.id);
      dispatch(setStateID(option.id));
    }
  };
  const [selectedOption, setSelectedOption] = useState({
    option: null,
    reason: null,
  });

  const handleTehsilChange = (option, reason) => {
    if (reason !== 'clear') {
      selectedTehsil = dropdownTehsilList.data?.find((opt) => opt.id === option.id)?.label ?? 'Select Tehsil';
      if (dropdownTehsilList.data?.find((opt) => opt.id === option.id)) {
        dispatch(setTehsilName(dropdownTehsilList.data?.find((opt) => opt.id === option.id)?.label));
      }
      // setButtonText(` ${selectedTehsil}`);
      buttonText = selectedTehsil;
      localStorage.setItem('location_id', option.id);
      if (option.id !== locationId) {
        dispatch(setLocationID(option.id));
        dispatch(setLocationChange(true));
      }
      dispatch(setIsLocationChangeAgree(false));
    } else {
      dispatch(setWorkshopSelectId(getLocationWorkshop?.data[0]?.id ?? null));
      localStorage.setItem('location_id', null);
      dispatch(setLocationID(null));
      dispatch(setLocationChange(false));
      dispatch(setIsLocationChangeAgree(false));
    }
  };

  useEffect(() => {
    if (isLocationChangeAgree) {
      handleTehsilChange(selectedOption.option, selectedOption.reason);
      deleteCart.mutate('', {
        onSuccess: () => {
          dispatch(setCartItems(null));
        },
      });
    }
  }, [isLocationChangeAgree, selectedOption]);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <Button
        aria-describedby={id}
        variant="text"
        style={{ color: '#fff' }}
        onClick={handleClick}
        endIcon={<IconArrowDown />}
        startIcon={<img src="/images/location-logo-white-new.svg" color="#fff" />}
      >
        {buttonText}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div style={{ padding: '16px' }}>
          <FormControl fullWidth margin="normal">
            <Autocomplete
              options={dropdownStateList.data ?? []}
              renderInput={(params) => <TextField {...params} placeholder="State" />}
              isOptionEqualToValue={(option, value) => option.id == value}
              // getOptionLabel={(option) =>
              //   option.label ?? dropdownStateList.data?.find((opt) => opt.id === option)?.label ?? ""
              // }
              getOptionLabel={(option) => getOptionLabel(option, dropdownStateList)}
              value={dropdownStateList?.data?.find((option) => option.id == stateId)?.id ?? null}
              onChange={(__event, option, reason) => handleStateChange(option, reason)}
              name="state"
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <Autocomplete
              name="tehsil"
              options={dropdownTehsilList.data ?? []}
              renderInput={(params) => <TextField {...params} placeholder="Tehsil" />}
              isOptionEqualToValue={(option, value) => option.id == value}
              // getOptionLabel={(option) =>
              //   option.label ?? dropdownTehsilList.data?.find((opt) => opt.id === option)?.label ?? ""
              // }
              getOptionLabel={(option) => getOptionLabel(option, dropdownTehsilList)}
              value={dropdownTehsilList?.data?.find((option) => option.id == locationId)?.id ?? null}
              onChange={(__event, option, reason) => {
                if (cartItems?.length >= 1) {
                  dispatch(setIsLocationModelOpen(true));
                  setSelectedOption({ option, reason });
                  // setSelectedReason(reason);
                } else {
                  handleTehsilChange(option, reason);
                }
              }}
            />
          </FormControl>
        </div>
      </Popover>
    </div>
  );
};

export default PopoverWithDropdowns;

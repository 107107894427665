import { useLanguage } from '../../../../language/useLanguage';
import PrivacyPolicySEO from './privacy-policy-seo';
import './privacypolicy.css';

export default function Privacypolicy() {
  const langData = useLanguage();
  return (
    <>
      <PrivacyPolicySEO />
      <div className="main_container">
        <div className="Privacy_policy">
          <h1 className="heading_text1">1. {langData('Introduction')}</h1>
          <p className="para_text">
            <p>
              {langData(
                "BEDIENUNG SOLUTION PRIVATE LIMITED, doing business as 'Tractor Seva' ('we,' 'us,' or 'our'), is committed to protecting your privacy and safeguarding your personal information. This Privacy Policy outlines our practices regarding the collection, use, disclosure, and protection of your information when you use our website tractorseva.com, our mobile application, and related services (collectively referred to as the 'Services'). By using our Services, you consent to the practices described in this Privacy Policy.",
              )}
            </p>
          </p>
        </div>
        <div className="Privacy_policy">
          <h2 className="heading_text">2.{langData(' Information We Collect')}</h2>
          <p className="para_text">
            <p>
              2.1.{' '}
              {langData('User Data: To access and use Tractor Seva, you must register for a User Account. During the registration process, we collect the following information:')}
            </p>
            <ul>
              <li>
                <p>{langData('Full name')}</p>
              </li>
              <li>
                <p>{langData('Email address')}</p>
              </li>
              <li>
                <p>{langData('Phone number')} </p>
              </li>
              <li>
                <p>{langData('Vehicle details')}</p>
              </li>
              <li>
                <p>{langData('Location information')}</p>
              </li>
            </ul>
            <p>{langData("This information is collectively referred to as 'User Data.' By submitting your User Data, you agree to the terms of this Privacy Policy.")}</p>
            <p>
              2.2.{langData('User Records: Tractor Seva may collect and store additional information about your interactions with the Services, including but not limited to:')}
            </p>
            <ul>
              <li>{langData('User-generated data or information such as appointments with Workshops and vehicle details.')}</li>
              <li>{langData("Service records generated during your interactions with Workshops ('Vehicle Data').")}</li>
              <li>{langData('Telephone calls, which may be recorded, between you and our customer support or service representatives')}</li>
            </ul>
            <p>
              {langData(
                "This information, together with your User Data, constitutes 'User Records.' We may use User Records for various purposes, including analysis, improving our services, and complying with legal requirements.",
              )}
            </p>
            <p>
              2.3.{' '}
              {langData(
                "Third-Party Content: Our platform may feature content from other Users or third parties ('Third-Party Content'). Please note that we do not verify the completeness, accuracy, legality, or safety of Third-Party Content. Additionally, Tractor Seva may display contextual advertisements from third-party advertisers, and we do not review the content of these advertisements. It is your responsibility to verify the authenticity and suitability of offerings from third-party advertisers.",
              )}
            </p>
          </p>
        </div>
        <div className="Privacy_policy">
          <h2 className="heading_text">3.{langData('How We Use Your Information')}</h2>
          <p className="para_text">
            <p>3.1.{langData('User Data: We use your User Data for the following purposes:')}</p>
            <ul>
              <li>
                <p>{langData('Creating and maintaining your User Account.')}</p>
              </li>
              <li>
                <p>{langData('Facilitating appointments with Workshops.')}</p>
              </li>
              <li>
                <p>{langData('Contacting you for feedback about our Services.')}</p>
              </li>
              <li>
                <p>{langData('Providing you with information about our Services and promotions, where permitted by applicable law.')}</p>
              </li>
              <li>
                <p>{langData('Sending you reminders when your vehicle’s next servicing is due')}</p>
              </li>
              <li>
                <p>{langData('Complying with legal obligations.')}</p>
              </li>
            </ul>
            <p>3.2. {langData('User Records: We may use User Records for the following purposes:')}</p>
            <ul>
              <li>
                <p>{langData('Conducting analysis to improve our Services.')}</p>
              </li>
              <li>
                <p>{langData('Complying with legal requirements and responding to legal requests.')}</p>
              </li>
              <li>
                <p>{langData('Sharing User Records with third parties as allowed by applicable law.')}</p>
              </li>
            </ul>
            <p>
              3.3.{' '}
              {langData(
                'Third-Party Content: While we do not take responsibility for the accuracy, legality, or safety of Third-Party Content, we may examine legitimate notices of infringements or unlawful content and take appropriate actions as required by law',
              )}
            </p>
          </p>
        </div>
        <div className="Privacy_policy">
          <h2 className="heading_text">4. {langData('User Content/Data')}</h2>
          <p className="para_text">
            <p>
              4.1.{' '}
              {langData(
                'Ownership: By submitting User Content/Data, you grant Tractor Seva a fully paid, irrevocable, perpetual, royalty-free, worldwide, sub-licensable, non-exclusive license and right to use your User Content/Data for the sole purpose of enabling Tractor Seva to perform its obligations and provide its services in accordance with this Privacy Policy.',
              )}
            </p>
            <p>4.2.{langData('Warranties: By submitting User Content/Data, you warrant that:')} </p>
            <ul>
              <li>
                <p>{langData('You have all necessary rights and title to grant Tractor Seva the license for your User Content/Data.')}</p>
              </li>
              <li>
                <p>{langData('Your User Content/Data does not infringe or violate the intellectual property rights of a third party.')}</p>
              </li>
              <li>
                <p>{langData('Your User Content/Data complies with these Terms of Use and applicable laws.')}</p>
              </li>
              <li>
                <p>{langData('Your User Content/Data is free of viruses, worms, malware, Trojan horses, or any other harmful elements.')}</p>
              </li>
            </ul>
            <p>4.3. {langData('Backups: You are responsible for regularly backing up your User Content/Data on an alternative storage medium.')}</p>
          </p>
        </div>
        <div className="Privacy_policy">
          <h2 className="heading_text">5.{langData('Disclosure of Information')}</h2>
          <p className="para_text">
            <p>{langData('We may disclose your information to government authorities when required by law, rule, regulation, or valid legal processes.')}</p>
          </p>
        </div>
        <div className="Privacy_policy">
          <h2 className="heading_text">6. {langData('Security')}</h2>
          <p className="para_text">
            <p>
              {langData(
                'We employ reasonable security measures to protect your information, but no method of transmission or storage is entirely secure. You are responsible for maintaining the confidentiality of your User Account credentials.',
              )}
            </p>
          </p>
        </div>
        <div className="Privacy_policy">
          <h2 className="heading_text">7.{langData('Third-Party Links')}</h2>
          <p className="para_text">
            <p>
              {langData(
                'Our Services may contain links to third-party websites or applications. Tractor Seva does not endorse or control these third-party sites and is not responsible for their privacy practices. We recommend reviewing the privacy policies of such sites before using them.',
              )}
            </p>
          </p>
        </div>
        <div className="Privacy_policy">
          <h2 className="heading_text">8. {langData('Changes to this Privacy Policy')}</h2>
          <p className="para_text">
            <p>
              {langData(
                'Tractor Seva may update this Privacy Policy from time to time. Significant changes will be communicated through our Services or other means. Your continued use of our Services after such changes constitutes your acceptance of the updated Privacy Policy.',
              )}
            </p>
          </p>
        </div>
        <div className="Privacy_policy">
          <h2 className="heading_text">9. {langData('Contact Us')}</h2>
          <p className="para_text">
            <p>
              {langData('If you have any questions or concerns regarding this Privacy Policy or our data practices, please contact us at ')}
              <a href="mailto:customercare@tractorseva.com" className="mail_url">
                customercare@tractorseva.com
              </a>
            </p>
          </p>
        </div>
      </div>
    </>
  );
}

import Button from '@mui/material/Button';
import { useLanguage } from '../../../language/useLanguage';
import { LazyImage } from '../../../assets/image/lazyImage';
import { useAuthContext } from '../../../context/authContext';
import { useNavigate } from 'react-router-dom';
import './item-list.css';
import { GetDiscount } from '../../../config/common-function/get-discount/get-discount';
import { useSelector } from 'react-redux';
import IconAdded from '../../../assets/icons/icon-common/icon-added';

export default function ItemCard({ data, handleAddToCart, model_id, path }) {
  const langData = useLanguage();
  const { getData } = useAuthContext();
  const { isAuth } = getData();
  const navigate = useNavigate();
  const cart_items = useSelector((state) => state.cart.cartItems);
  const inCart = isAuth && cart_items?.length > 0 ? cart_items?.some((val) => val.item_id === data.item_id && val.CustomerTractor.TractorModel.id === model_id) : false;

  return (
    <div className="card">
      <div className={`item-card ${!isAuth ? '' : data.isItemAvailable ? '' : 'not_avilable'}`}>
        <div className="item-image">
          <LazyImage name={data.item_img} fallbackImage="tractor-model-image-rect.svg" height={'100%'} width={'100%'} />
        </div>
        <div className="content">
          <h3>{data.Item.item_name}</h3>
          {isAuth ? (
            <div className="prices">
              <div className="offer-price">₹ {data.Item.offer_total}</div>
              <div className="mrp-price">₹ {data.Item.mrp_total}</div>
              <GetDiscount mrp={data.Item.mrp_total} offer={data.Item.offer_total} />
            </div>
          ) : (
            ''
          )}
          {isAuth ? (
            <Button
              variant={'contained'}
              disabled={!data?.isItemAvailable || inCart}
              onClick={() => handleAddToCart(data.item_id)}
              sx={{
                ...((!data?.isItemAvailable || inCart) && {
                  '&&.MuiButton-root.MuiButtonBase-root.Mui-disabled': {
                    backgroundColor: '#002852',
                    color: '#fff',
                  },
                }),
              }}
              startIcon={inCart && <IconAdded />}
            >
              {inCart ? langData('Added') : langData('Add to Cart')}
            </Button>
          ) : (
            <Button
              variant="contained"
              onClick={() =>
                navigate('/login', {
                  state: {
                    redirectTo: `${path}`,
                  },
                })
              }
            >
              {langData('Login For Best Price')}
            </Button>
          )}
        </div>
      </div>
      {!data.isItemAvailable && isAuth ? <p className="item__unavailable">{langData('Not available at your location')}</p> : ''}
    </div>
  );
}

export function KitCard({ data, handleAddToCart, model_id, path }) {
  const langData = useLanguage();
  const { getData } = useAuthContext();
  const { isAuth } = getData();
  const navigate = useNavigate();
  const cart_items = useSelector((state) => state.cart.cartItems);
  const inCart = isAuth && cart_items?.length > 0 ? cart_items?.some((val) => val.item_id === data.item_id && val.CustomerTractor.TractorModel.id === model_id) : false;

  return (
    <div className="card" style={{ marginTop: '1rem' }}>
      <div className={`kit-card ${!isAuth ? '' : data.isItemAvailable ? '' : 'not_avilable'}`}>
        <div className="item-image">
          <LazyImage name={data.item_img} fallbackImage="tractor-model-image-rect.svg" height={'100%'} width={'100%'} />
        </div>
        <div className="content">
          <div className="heading">
            <h3>{data.Item.item_name}</h3>
            <p className="sub_items">
              {data.sub_items
                ? data.sub_items
                    .map((item) => `${item.quantity} X ${item.item_name}`)
                    .join()
                    .substring(0, 50) + '...'
                : ''}
            </p>
          </div>
          {isAuth ? (
            <div className="prices">
              <div className="offer-price">₹ {data.Item.offer_total}</div>
              <div className="mrp-price">₹ {data.Item.mrp_total}</div>
              <GetDiscount mrp={data.Item.mrp_total} offer={data.Item.offer_total} />
            </div>
          ) : (
            ''
          )}
          <div
          //  style={{ position: "absolute", bottom: "-30px" }}
          >
            {isAuth ? (
              <Button
                variant={'contained'}
                disabled={!data?.isItemAvailable || inCart}
                onClick={() => handleAddToCart(data.item_id)}
                sx={{
                  ...((!data?.isItemAvailable || inCart) && {
                    '&&.MuiButton-root.MuiButtonBase-root.Mui-disabled': {
                      backgroundColor: '#002852',
                      color: '#fff',
                    },
                  }),
                }}
                startIcon={inCart && <IconAdded />}
              >
                {inCart ? langData('Added') : langData('Add to Cart')}
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={() =>
                  navigate('/login', {
                    state: {
                      redirectTo: `${path}`,
                    },
                  })
                }
              >
                {langData('Login For Best Price')}
              </Button>
            )}
          </div>
        </div>
      </div>
      {!data.isItemAvailable && isAuth ? <p className="item__unavailable">{langData('Not available at your location')}</p> : ''}
    </div>
  );
}
